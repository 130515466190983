import React, { Suspense } from "react";
import NavBar from "../landing/NavBar";
import Hero from "../landing/Hero";
import Footer from "../landing/Footer";
import UserDashboardPage from "../apps/CRM/Dashboard/index";

const UserDashboard = () => {
  return (
    <div id="">
      <UserDashboardPage />
    </div>
  );
};

export default UserDashboard;
