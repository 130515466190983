import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import {
  categoryApiResponseSuccess,
  categoryApiResponseError,
} from "./actions";
import { CategoryActionTypes } from "./constants";
import {
  addCategory,
  deleteCategory,
  editCategory,
  fetchCategory,
} from "../../helpers/api/categoryApi";
import notify from "../../components/Notification/notify";
import Swal from "sweetalert2";

function* fetchCategorySaga(): SagaIterator {
  try {
    const response = yield call(fetchCategory);
    const payload: any = response.data;
    yield put(
      categoryApiResponseSuccess(CategoryActionTypes.FETCH_CATEGORY, payload)
    );
  } catch (error: any) {
    yield put(
      categoryApiResponseError(CategoryActionTypes.FETCH_CATEGORY, error)
    );
  }
}

export function* watchFetchCategory() {
  yield takeEvery(CategoryActionTypes.FETCH_CATEGORY, fetchCategorySaga);
}

function* addCategorySaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(addCategory, { data });
    const payload: any = response.data;
    yield put(
      categoryApiResponseSuccess(CategoryActionTypes.ADD_CATEGORY, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      categoryApiResponseError(CategoryActionTypes.ADD_CATEGORY, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddCategory() {
  yield takeEvery(CategoryActionTypes.ADD_CATEGORY, addCategorySaga);
}

//Edit Category

function* EditCategorySaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(editCategory, { data });
    const payload: any = response.data;
    yield put(
      categoryApiResponseSuccess(CategoryActionTypes.EDIT_CATEGORY, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      categoryApiResponseError(CategoryActionTypes.EDIT_CATEGORY, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditCategory() {
  yield takeEvery(CategoryActionTypes.EDIT_CATEGORY, EditCategorySaga);
}

//Delete subAdmin User...

function* DeleteCategorySaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(deleteCategory, { data });
    const payload: any = response.data.data;
    yield put(
      categoryApiResponseSuccess(CategoryActionTypes.DELETE_CATEGORY, payload)
    );
    Swal.fire("Success!", "Category deleted successfully", "success");
  } catch (error: any) {
    yield put(
      categoryApiResponseError(CategoryActionTypes.DELETE_CATEGORY, error)
    );
    Swal.fire("Error!", `${error.message}`, "error");
  }
}

export function* watchDeleteCategory() {
  yield takeEvery(CategoryActionTypes.DELETE_CATEGORY, DeleteCategorySaga);
}

function* categorySaga() {
  yield all([
    fork(watchFetchCategory),
    fork(watchAddCategory),
    fork(watchEditCategory),
    fork(watchDeleteCategory),
  ]);
}

export default categorySaga;
