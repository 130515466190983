import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import classNames from "classnames";

// components

// dummy data
import { orders, OrdersItemTypes } from "../apps/Ecommerce/data";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { GetUserOrderAction } from "../../redux/actions";
import MyOrderDetails from "./MyOrderDetails";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "../apps/Invoice/Index";
import CustomTable from "../../components/CustomTable";

const productImgList = [
  {
    url: "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-1.png",
  },
  {
    url: "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-2.png",
  },
  {
    url: "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-3.png",
  },
];

/* order column render */
const OrderColumn = ({ row }: { row: any }) => {
  return (
    <>
      {/* /apps/ecommerce/order/details */}
      <Link to="" className="fw-bold text-dark">
        #{row.original.user_order_number}
      </Link>
    </>
  );
};

/* product column render */
const ProductsColumn = ({ row }: { row: any }) => {
  return (
    <>
      {(row.original.wm_user_order_items || []).map(
        (item: any, index: any) => {
          return (
            // <Link to="/apps/ecommerce/product-details" key={index}>
            <img
              src={
                item?.wm_product?.wm_product_photos && item?.wm_product?.wm_product_photos.length > 0 ? item?.wm_product?.wm_product_photos[0]?.photo_url :
                  "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-5.png"
              }
              alt={item.name}
              className="rounded me-1"
              height="40"
            // width="40"
            />
            // </Link>
          );
        }
      )}
    </>
  );
};

/* orderdate column render */
function formatUnixTimestamp(unixTimestamp: any) {
  // Convert Unix timestamp to milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Define month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month, day, year, hours, and minutes
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Add leading zero to minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Construct the formatted string
  const formattedDate = `${month} ${day} ${year} ${hours}:${formattedMinutes} ${period}`;

  return formattedDate;
}

const OrderDateColumn = ({ row }: { row: any }) => {
  const order_date = formatUnixTimestamp(row.original?.user_order_date);
  return (
    <>
      {order_date}{" "}
      <small className="text-muted">{row.original.order_time}</small>
    </>
  );
};

/* payment column render */
const PaymentStatusColumn = ({ row }: { row: any }) => {
  return (
    <>
      <h5>
        <span
          className={classNames("badge", {
            "bg-soft-success text-success": row.original.payment_status === 1,
            "bg-soft-info text-info": row.original.payment_status === 0,
          })}
        >
          {row.original.payment_status === 1 && (
            <i className="mdi mdi-bitcoin me-1"></i>
          )}
          {row.original.payment_status === 0 && (
            <i className="mdi mdi-cash me-1"></i>
          )}
          {row.original.payment_status === 1 ? "Paid" : "Due"}
        </span>
      </h5>
      {/* <h5>
        <span
          className={classNames("badge", {
            "bg-soft-success text-success":
              row.original.payment_status === "Paid",
            "bg-soft-danger text-danger":
              row.original.payment_status === "Payment Failed",
            "bg-soft-info text-info": row.original.payment_status === "Unpaid",
            "bg-soft-warning text-warning":
              row.original.payment_status === "Awaiting Authorization"
          })}
        >
          {row.original.payment_status === "Paid" && (
            <i className="mdi mdi-bitcoin me-1"></i>
          )}
          {row.original.payment_status === "Payment Failed" && (
            <i className="mdi mdi-cancel me-1"></i>
          )}
          {row.original.payment_status === "Unpaid" && (
            <i className="mdi mdi-cash me-1"></i>
          )}
          {row.original.payment_status === "Awaiting Authorization" && (
            <i className="mdi mdi-timer-sand me-1"></i>
          )}
          {row.original.payment_status}
        </span>
      </h5> */}
    </>
  );
};

/* status column render */
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <>
      <h5>
        <span
          className={classNames("badge", {
            "bg-success": row.original.order_status === 4,
            "bg-danger": row.original.order_status === 2,
            "bg-info": row.original.order_status === 0,
            "bg-warning": row.original.order_status === 1,
            "bg-secondary": row.original.order_status === 3,
          })}
        >
          {/* {row.original.order_status} */}
          {row.original.order_status === 0
            ? "Request"
            : row.original.order_status === 1
              ? "Processing/ Accept"
              : row.original.order_status === 2
                ? "Rejected"
                : row.original.order_status === 3
                  ? "Dispatch"
                  : row.original.order_status === 4
                    ? "Delivered"
                    : "Request"}
        </span>
      </h5>
    </>
  );
};

/* action column render */
const ActionColumn = () => {
  return (
    <>
      <Link to="#" className="action-icon">
        {" "}
        <i className="mdi mdi-eye"></i>
      </Link>
      <Link to="#" className="action-icon">
        {" "}
        <i className="mdi mdi-square-edit-outline"></i>
      </Link>
      <Link to="#" className="action-icon">
        {" "}
        <i className="mdi mdi-delete"></i>
      </Link>
    </>
  );
};

// main component
const MyOrders = () => {
  const [orderList, setOrderList] = useState<OrdersItemTypes[]>(orders);
  const [show, setShow] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<any>({});
  const dispatch = useDispatch<AppDispatch>();


  const [orderData, setOrderData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const [reqBody, setReqBody] = useState<any>({
    page_record: pageSize && 10,
    page_no: pageIndex && 1,
    search: "",
    filter_by: "",
    sort_field: "created_datetime",
    sort_order: "desc",
  });

  const { userOrderData, loading, error, placeOrderDetails } = useSelector((state: RootState) => ({
    userOrderData: state.UserOrderReducer.userOrderData,
    loading: state.UserOrderReducer.loading,
    error: state.UserOrderReducer.error,
    placeOrderDetails: state.UserCartReducer.placeOrderDetails,
  }));

  useEffect(() => {
    if (placeOrderDetails?.statusCode === 200 &&
      placeOrderDetails?.data !== undefined) {
      dispatch(GetUserOrderAction(reqBody));
    }
  }, [placeOrderDetails]);
  // useEffect(() => {
  //   dispatch(GetUserOrderAction(reqBody));
  // }, [dispatch]);

  useEffect(() => {
    if (
      userOrderData?.statusCode === 200 &&
      userOrderData?.data !== undefined &&
      userOrderData?.data !== null
    ) {
      setOrderData(userOrderData?.data?.rows);
      setTotalRecords(userOrderData?.data?.count);
      // setOrderData(userOrderData?.rows);
      // setTotalRecords(userOrderData?.count);
    }
  }, [userOrderData]);

  const onOpenShowModal = (row: any) => {
    setShow(true);
    setOrderDetails(row);
  };

  const onCloseModal = () => {
    setShow(false);
    setOrderDetails({});
  };

  // give page size
  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    }
    // {
    //   text: "All",
    //   value: list.length,
    // },
  ];

  // get all columns
  const columns = [
    {
      Header: "Order ID",
      accessor: "user_order_number",
      Cell: OrderColumn,
    },
    {
      Header: "Products",
      accessor: "wm_user_order_items",
      Cell: ProductsColumn,
    },
    {
      Header: "Date",
      accessor: "order_date",
      Cell: OrderDateColumn,
    },
    {
      Header: "Payment Status",
      accessor: "payment_status",
      Cell: PaymentStatusColumn,
    },
    {
      Header: "Total",
      accessor: "total_amount",
      Cell: ({ row }: any) => (
        <>₹ {row.original.total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
      ),
    },
    // {
    //   Header: "Payment Method",
    //   accessor: "payment_method",
    // },
    {
      Header: "Order Status",
      accessor: "order_status",
      Cell: StatusColumn,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }: any) => (
        <>
          <Link
            to=""
            className="action-icon text-dark"
            onClick={() => onOpenShowModal(row.original)}
          >
            <i className="mdi mdi-eye"></i>
          </Link>
          <span
            style={{
              pointerEvents: [0, 1, 2].includes(row.original.order_status)
                ? "none"
                : "auto",
            }}
          >
            <PDFDownloadLink
              className="action-icon text-dark"
              document={<Invoice invoiceData={row.original} />} // items={row.original}
              fileName="invoice.pdf"
            >
              {({ blob, url, loading, error }) => (
                <i className="mdi mdi-briefcase-download"></i>
              )}
            </PDFDownloadLink>
          </span>
        </>
      ),
    },
  ];

  // change order status group
  // const changeOrderStatusGroup = (OrderStatusGroup: string) => {
  //   let updatedData = [...orders];
  //   //  filter
  //   updatedData =
  //     OrderStatusGroup === "All"
  //       ? orders
  //       : [...orders].filter((o) =>
  //           o.payment_status?.includes(OrderStatusGroup)
  //         );
  //   setOrderList(updatedData);
  // };

  // Function to handle search using API call
  const searchOrderRequest = async (value: string) => {
    console.log("search");
  };

  const sortOrderRequest = async (value: any) => {
    console.log("sort");
  };

  const InvokeAPI = async (reqBody: any) => {
    try {
      dispatch(GetUserOrderAction(reqBody));
      const totalRecords = userOrderData?.data?.count || 0;
      const rows = userOrderData?.data?.rows || [];
      setOrderData(rows);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const fetchData = async ({ pageIndex, pageSize, search }: { pageIndex: number; pageSize: number; search?: any }) => {
    const updatedReqBody = {
      ...reqBody,
      page_no: pageIndex + 1,
      page_record: pageSize,
      search: search,
    };
    setReqBody(updatedReqBody);
    setPageSize(pageSize)
    await InvokeAPI(updatedReqBody);
  };


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Orders", path: "/user/myOrders", active: true },
        ]}
        title={"Orders"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>   {/* style={{ backgroundColor: "lightblue" }} */}
              <Row className="align-items-center">

              </Row>

              {/* <Table
                columns={columns}
                data={orderData}
                // isSearchable={true}
                pageSize={10}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                // isSelectable={true}
                theadClass="table-light"
                searchBoxClass="mb-2"
              /> */}

              {
                orderData &&
                <CustomTable
                  loading={loading}
                  columns={columns}
                  data={orderData}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  pagination={true}
                  theadClass="table-light"
                  searchBoxClass="mb-2"
                  isSearchable={false}
                  isSortable={false}
                  sortFunction={sortOrderRequest}
                  fetchData={fetchData}
                  sizePerPageList={sizePerPageList}
                  totalRecords={totalRecords}
                  isCustomActionButton={false}
                // addButton={<AddClientButton />}
                />
              }
            </Card.Body>
          </Card>
        </Col>
      </Row >

      {show && (
        <MyOrderDetails
          show={show}
          orderDetails={orderDetails}
          onHide={onCloseModal}
        />
      )
      }
    </>
  );
};

export default MyOrders;
