import { ProductActionTypes } from "./constants";

const INIT_STATE = {
  productDetails: [],
  AddProductData: [],
  ErrorAddProductData: null,
  productEditDetails: null,
  loading: false,
  error: null,
};

interface productData {
  productDetails: any;
  AddProductData: any;
  ErrorAddProductData: any;
  productEditDetails: any;
  loading: boolean;
  error: string | null;
}

export interface ProductActionType {
  type:
  | ProductActionTypes.API_RESPONSE_SUCCESS
  | ProductActionTypes.API_RESPONSE_ERROR
  | ProductActionTypes.FETCH_PRODUCT
  | ProductActionTypes.ADD_PRODUCT
  | ProductActionTypes.FETCH_PRODUCT_DETAILS
  | ProductActionTypes.EDIT_PRODUCT
  | ProductActionTypes.DELETE_PRODUCT
  | ProductActionTypes.UPDATE_PRODUCT_STATUS
  | ProductActionTypes.UPDATE_PRODUCT_STOCK;
  payload: {
    actionType?: string;
    data?: productData | {};
    error?: string;
  };
}

interface State {
  productDetails?: productData | {};
  AddProductData?: productData | {};
  productEditDetails?: any;
  loading?: boolean;
  value?: boolean;
}

const ProductReducer = (
  state: State = INIT_STATE,
  action: ProductActionType
): any => {
  switch (action.type) {
    case ProductActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ProductActionTypes.FETCH_PRODUCT: {
          return {
            ...state,
            productDetails: action.payload.data,
            loading: false,
          };
        }
        case ProductActionTypes.ADD_PRODUCT: {
          return {
            ...state,
            AddProductData: action.payload.data,
            loading: false,
          };
        }
        case ProductActionTypes.FETCH_PRODUCT_DETAILS: {
          return {
            ...state,
            productDetails: action.payload.data,
            loading: false,
          };
        }

        case ProductActionTypes.EDIT_PRODUCT: {
          return {
            ...state,
            productEditDetails: action.payload.data,
            loading: false,
          };
        }
        case ProductActionTypes.DELETE_PRODUCT: {
          return {
            ...state,
            productDelete: action.payload.data,
            is_deleted: false,
            loading: false,
          };
        }
        case ProductActionTypes.UPDATE_PRODUCT_STATUS: {
          return {
            ...state,
            productStatus: action.payload.data,
            loading: false,
          };
        }
        case ProductActionTypes.UPDATE_PRODUCT_STOCK: {
          return {
            ...state,
            productStatus: action.payload.data,
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case ProductActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ProductActionTypes.FETCH_PRODUCT: {
          return {
            ...state,
            loading: false,
          };
        }
        case ProductActionTypes.ADD_PRODUCT: {
          return {
            ...state,
            ErrorAddProductData: action.payload.error,
            loading: false,
          };
        }
        case ProductActionTypes.FETCH_PRODUCT_DETAILS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        case ProductActionTypes.EDIT_PRODUCT: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        case ProductActionTypes.DELETE_PRODUCT: {
          return {
            ...state,
            productDeleteError: action.payload.error,
            is_deleted: true,
            loading: false,
          };
        }
        case ProductActionTypes.UPDATE_PRODUCT_STATUS: {
          return {
            ...state,
            ProductStatuserror: action.payload.error,
            loading: false,
          };
        }
        case ProductActionTypes.UPDATE_PRODUCT_STOCK: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case ProductActionTypes.FETCH_PRODUCT:
      return { ...state, loading: true };

    case ProductActionTypes.ADD_PRODUCT:
      return { ...state, loading: true };

    case ProductActionTypes.FETCH_PRODUCT_DETAILS:
      return { ...state, loading: true };

    case ProductActionTypes.EDIT_PRODUCT:
      return { ...state, loading: true };

    case ProductActionTypes.DELETE_PRODUCT:
      return { ...state, loading: true,is_deleted: true, };

    case ProductActionTypes.UPDATE_PRODUCT_STATUS:
      return { ...state, loading: true };

    case ProductActionTypes.UPDATE_PRODUCT_STOCK:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};
export default ProductReducer;
