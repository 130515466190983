import React, { useRef, useEffect, forwardRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useAsyncDebounce,
  useExpanded,
  useFilters,
} from "react-table";
import classNames from "classnames";

// components
import Pagination from "./Pagination";
import Spinner from "./Spinner";
import { color } from "html2canvas/dist/types/css/types/color";
import CustomPagination from "./CustomPagination";
import { useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { TASK_STATUS } from "../constants/status";

interface CustomTableProps {
  loading?: boolean;
  isSearchable?: boolean;
  isFilter?: boolean;
  isFilterStatus?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  sizePerPageList?: {
    text: string;
    value: number;
  }[];
  columns: {
    Header: string;
    accessor: string;
    style?: any;
    sort?: boolean;
    Cell?: any;
    className?: string;
  }[];
  data: any[];
  pageIndex?: any;
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  totalRecords?: number;
  // Add a prop for the search function
  searchFunction?: (searchQuery: string, pageIndex: number, filterQuery: number) => Promise<void>;
  sortFunction: (direction: string) => Promise<void>;
  fetchData: (params: {
    pageIndex: number;
    pageSize: number;
    search?: any;
    filter?: any;
  }) => void; // Add this prop
  // handlePageSizeChange: (size: number) => void;
  isCustomActionButton?: boolean;
  addButton?: any;
  filterButton?: any;
  filterStatus?: any;
  filterDate?: any;
  ResetFilter?: any;
  isFilterConfigured?: any;
  frozenColumns?: any;
  filterControls?: any;
}

const CustomTable = (props: CustomTableProps) => {
  const isCustomActionButton = props["isCustomActionButton"] || false;
  const addButton = props["addButton"];
  const filterButton = props["filterButton"];
  const filterStatus = props["filterStatus"];
  const filterDate = props["filterDate"];
  const ResetFilter = props["ResetFilter"];
  const isFilterConfigured = props["isFilterConfigured"];
  const filterControls = props["filterControls"];

  // const isFilter = props["isFilter"] || false;
  const isSearchable = props["isSearchable"] || false;
  const isFilterStatus = props["isSortable"] || false;
  const isFilter = props["isSortable"] || false;
  const pagination = props["pagination"] || false;
  const sizePerPageList = props["sizePerPageList"] || [];
  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<any>({});

  const [filterToggle, setFilterToggle] = useState(false)

  const [totalRecords, setTotalRecords] = useState<any>(props["totalRecords"]); // Initialize totalRecords state
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(props["pageSize"]); // Default page size

  const frozenColumns = props["frozenColumns"] || 0;

  useEffect(() => {
    setTotalRecords(props["totalRecords"]);
  }, [props["totalRecords"]]);

  useEffect(() => {
    props.fetchData({ pageIndex, pageSize, search: searchQuery, filter: filterQuery });
  }, [pageIndex, pageSize, searchQuery, filterQuery]);

  // Start Debounce the search and filter functions

  const debouncedSearch = useAsyncDebounce((value) => {
    setSearchQuery(value);
    setPageIndex(0);
  }, 300);

  const debouncedFilter = useAsyncDebounce((value) => {
    setFilterQuery(value);
    setPageIndex(0);
  }, 300);

  const handleSearch = (value: string) => {
    debouncedSearch(value);
  };

  const handleFilter = (value: string) => {
    debouncedFilter(value);
  };
  // End Debounce the search and filter functions

  // ************** Old Code ************** 
  // // const MyHandlePageSizeChange = (size: number) => {
  // //   console.log('size ---------------+++++++++++++++ :>> ', size);
  // //   handlePageSizeChange(size)
  // //   setPageSize(size);
  // //   setPageIndex(0); // Reset to the first page
  // //   // props.fetchData({ pageIndex: 0, pageSize: size }); // Fetch data with the new page size
  // // };

  // const handleSearch = async (value: string) => {
  //   setSearchQuery(value);
  //   setPageIndex(0);
  //   setPageSize(10);
  //   // props.fetchData({ pageIndex: 0, pageSize: pageSize, search: value });
  //   // await props.searchFunction(value, pageIndex);
  // };

  // const handleFilter = async (value: string) => {
  //   setFilterQuery(value);
  //   setPageIndex(0);
  //   setPageSize(10);
  //   // props.fetchData({ pageIndex: 0, pageSize: pageSize, filter: value });
  //   // await props.searchFunction(value, pageIndex);
  // };
  // ************** Old Code ************** 

  const handleSortClick = async (columnId: string) => {
    let direction: "asc" | "desc" | undefined;

    if (sortDirection[columnId] === "asc") {
      direction = "desc";
    } else if (sortDirection[columnId] === "desc") {
      direction = undefined;
    } else {
      direction = "asc";
    }

    const sortObj: any = { column: columnId, direction };
    setSortDirection((prev: any) => ({ ...prev, [columnId]: direction }));
    await props.sortFunction(sortObj);
  };

  const dataTable = useTable(
    {
      columns: props["columns"],
      data: props["data"],
      initialState: { pageSize: pageSize },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const rows = pagination
    ? pageSize > 10
      ? dataTable.rows
      : dataTable.page
    : dataTable.rows;
  // const rows = pageSize > 10 ? dataTable.rows : dataTable.page;
  const totalPages = Math.ceil(totalRecords / pageSize); // Calculate total pages

  return (
    <>
      <Row className="justify-content-between">
        <Col className="col-auto mb-2 d-flex">
          <div>
            {isSearchable && (
              <>
                <form
                  className={`d-flex flex-wrap align-items-center ${classNames(
                    props.searchBoxClass
                  )}`}
                >
                  <label htmlFor="inputPassword2" className="visually-hidden">
                    Search
                  </label>
                  <div className="me-2 position-relative">
                    <input
                      type="search"
                      className="form-control my-1 my-lg-0 rounded ps-4 search-input" // Adding padding to accommodate icon
                      id="inputPassword2"
                      placeholder="Search..."
                      onChange={(e: any) => handleSearch(e.target.value)}
                      style={{ backgroundColor: "#3e436c", color: "white" }}
                    />
                    <div className="position-absolute top-50 start-0 translate-middle-y ms-2">
                      <i className="bi bi-search text-white"></i>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>

          {
            isFilterConfigured ?
              <>
                <Button
                  className="btn btn-primary mb-2 me-3"
                  onClick={() => setFilterToggle(!filterToggle)}
                  style={{ backgroundColor: "#3e436c", color: "white" }}
                >
                  {filterToggle ? <i className="mdi mdi-filter-off"></i> : <i className="mdi mdi-filter"></i>}
                </Button>
                {
                  filterToggle && <>
                    <div className="d-flex flex-wrap align-items-center mb-2">
                      {filterControls}
                    </div>
                  </>
                }
              </>
              : null
          }

          {/* Filter section */}
          {/* {
            isFilterConfigured ?
              <>
                <Button
                  className="btn btn-primary mb-2 me-3"
                  onClick={() => setFilterToggle(!filterToggle)}
                  style={{ backgroundColor: "#3e436c", color: "white" }}
                >
                  {filterToggle ? <i className="mdi mdi-filter-off"></i> : <i className="mdi mdi-filter"></i>}
                </Button>
                {
                  filterToggle && <>
                    <div className="d-flex flex-wrap align-items-center mb-2">
                      {isFilter && (filterButton)}
                    </div>
                    <div className="d-flex flex-wrap align-items-center mb-2 ms-3">
                      {isFilterStatus && (filterDate)}
                    </div>
                    <div className="d-flex flex-wrap align-items-center mb-2 ms-3">
                      {ResetFilter && (ResetFilter)}
                    </div>
                  </>
                }
              </>
              : null
          } */}

        </Col >
        <Col className="col-auto mb-2"></Col>
        <Col className="col-auto">{isCustomActionButton && addButton}</Col>
      </Row >

      <div className="table-responsive">
        {/*  style={{ minHeight: "65vh" }} */}
        <table
          {...dataTable.getTableProps()}
          className={classNames(
            "table table-centered table-nowrap react-table",
            props["tableClass"]
          )}
        >
          <thead
            // className={props["theadClass"]}
            style={{
              backgroundColor: "#050A30",
              fontWeight: "400",
              textTransform: "uppercase",
              color: "#20e3ffc9",
            }}
          >
            {(dataTable.headerGroups || []).map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th>#</th>
                {(headerGroup.headers || []).map((column: any) => (
                  <th
                    {
                    ...column.getHeaderProps(
                      column.sort && column.getSortByToggleProps()
                    )
                    }
                    className={
                      classNames({
                        sortable: column.sort === true,
                      })}
                  // onClick={() => handleSortClick(column.id)}
                  >
                    <div className="">
                      {column.sort ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="d-grid">
                            <i
                              className={classNames(
                                "bi bi-caret-up-fill",
                                sortDirection[column.id] === "asc"
                                  ? "opacity-100"
                                  : sortDirection[column.id] === "desc"
                                    ? "opacity-25"
                                    : "opacity-50"
                              )}
                            // style={{ marginTop: "-10px" }}
                            />
                            <i
                              className={classNames(
                                "bi bi-caret-down-fill",
                                sortDirection[column.id] === "desc"
                                  ? "opacity-100"
                                  : sortDirection[column.id] === "asc"
                                    ? "opacity-25"
                                    : "opacity-50"
                              )}
                              style={{ marginTop: "-10px" }}
                            />
                          </span>
                        </div>
                      ) : column.isCustom ? (
                        <>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="text-center">
                              <span className="">{column.Header}</span>
                            </div>
                            <div className="text-center mt-1">
                              <span
                                className="fs-6 border-end p-1"
                                style={{ color: "#cae8fe" }}
                              >
                                UNIT
                              </span>
                              <span
                                className="fs-6 border-end p-1"
                                style={{ color: "#cae8fe" }}
                              >
                                COST
                              </span>
                              <span
                                className="fs-6 p-1"
                                style={{ color: "#cae8fe" }}
                              >
                                RRR
                              </span>
                            </div>
                          </div>
                        </>
                      ) : column.render("Header")}
                      {/* {column.isCustom ? (
                        <>
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="">
                              <span className="">{column.Header}</span>
                            </div>
                            <div className="text-center mt-1">
                              <span
                                className="fs-6 border-end p-1"
                                style={{ color: "#cae8fe" }}
                              >
                                UNIT
                              </span>
                              <span
                                className="fs-6 border-end p-1"
                                style={{ color: "#cae8fe" }}
                              >
                                COST
                              </span>
                              <span
                                className="fs-6 p-1"
                                style={{ color: "#cae8fe" }}
                              >
                                RRR
                              </span>
                            </div>
                          </div>
                        </>
                      ) : null} */}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...dataTable.getTableBodyProps()} className="text-dark">
            {props.loading ? (
              <tr>
                <td colSpan={props.columns.length++} className="text-center">
                  <Spinner className="m-2" color={"dark"} />
                </td>
              </tr>
            ) : rows.length > 0 ? (
              (rows || []).map((row: any, i: number) => {
                dataTable.prepareRow(row);
                const rowIndex = pageIndex * pageSize + i + 1;
                return (
                  <tr {...row.getRowProps()} className="text-dark">
                    <td>{rowIndex}</td>
                    {(row.cells || []).map((cell: any) => {
                      return (
                        <td
                          className="text-dark"
                          style={{ color: "#000 !important;" }}
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={props.columns.length++} className="text-center">
                  <h3>No Data </h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>


      <div className="pt-3">
        {pagination && (
          <CustomPagination
            tableProps={dataTable}
            sizePerPageList={sizePerPageList}
            totalPages={totalPages}
            currentPage={pageIndex}
            pageSize={pageSize}
            onPageChange={(newPageIndex: any) => setPageIndex(newPageIndex)}
            onPageSizeChange={(pageSize: any) => setPageSize(pageSize)}
          />
          // <Pagination tableProps={dataTable} sizePerPageList={sizePerPageList} />
        )}
      </div>
    </>
  );
};

export default CustomTable;
