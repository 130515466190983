import React, { useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import { VerticalForm, FormInput } from "../../components";

import AuthLayout from "./AuthLayout";

import userImg from "../../assets/images/users/user-1.jpg";
import notify from "../../components/Notification/notify";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { resetPassword } from "../../redux/actions";

interface UserData {
  password: string;
  confirm_password: string;
}

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();
  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-dark-50">
          {t("Back to")}{" "}
          <Link to={"/auth/login"} className="text-dark ms-1">
            <b>{t("Log in")}</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const NewPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const email = localStorage.getItem("forgot-email");
  const navigate = useNavigate();


  const { loading, is_reset, error } = useSelector(
    (state: RootState) => ({
      loading: state.Auth.loading,
      error: state.Auth.error,
      is_reset: state.Auth.is_reset,
    })
  );

  useEffect(() => {
    if (is_reset) {
      navigate("/auth/set-NewPassword");
    } else {
      console.log("in else");
    }
  }, [is_reset])

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      password: yup.string().required(t("Please enter Password")),
      confirm_password: yup.string().required(t("Please enter Confirm Password")),
    })
  );

  /*
   * handle form submission
   */
  const onSubmit = (formData: UserData) => {
    if (formData.password === formData.confirm_password) {
      navigate('/auth/login')
      let reqBody = { email: email, newPassword: formData.password }
      dispatch(resetPassword(reqBody));
    } else {
      notify('Password and confirm password must be same.', 'error');
    }
  };

  return (
    <>
      <AuthLayout
      //  bottomLinks={<BottomLink />}
      >
        <div className="text-center w-75 m-auto">
          {/* <img
            src={userImg}
            alt=""
            height="88"
            className="rounded-circle shadow"
          /> */}
          <h4 className="text-dark-50 text-center mt-3 mb-1">{t("Set NewPassword")} </h4>
          <p className="text-dark-50 text-center  mb-3">{t("Hi ! ")} {email}</p>
          {/* <p className="text-muted mb-4">
            {t("Enter your password to access the admin.")} 
          </p> */}
        </div>
        <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
          <FormInput
            label={t("Password")}
            type="password"
            name="password"
            placeholder={t("Enter your password")}
            containerClass={"mb-3"}
          />
          <FormInput
            label={t("Confirm Password")}
            type="password"
            name="confirm_password"
            placeholder={t("Enter your confirm password")}
            containerClass={"mb-3"}
          />

          <div className="d-grid text-center">
            <Button variant="primary" type="submit">
              {t("Reset Password")}
            </Button>
          </div>
        </VerticalForm>
        <BottomLink />
      </AuthLayout>
    </>
  );
};

export default NewPassword;
