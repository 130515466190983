import { TaskActionTypes } from "./constants";

export interface TaskAction {
  type:
    | TaskActionTypes.API_RESPONSE_SUCCESS
    | TaskActionTypes.API_RESPONSE_ERROR
    | TaskActionTypes.FETCH_TASKS
    | TaskActionTypes.FETCH_TASKS_ID
    | TaskActionTypes.ADD_TASK
    | TaskActionTypes.EDIT_TASK
    | TaskActionTypes.DELETE_TASK
    | TaskActionTypes.FETCH_SUBADMIN_LIST
    | TaskActionTypes.EDIT_STATUS_TASK
    | TaskActionTypes.FETCH_CLIENT_TASK
    | TaskActionTypes.CUSTOMER_LIST;
  payload: {} | any;
}

interface TaskData {
  taskDetails: [];
  loading: false;
  error: null;
}

// common success
export const taskApiResponseSuccess = (
  actionType: string,
  data: TaskData | {}
): TaskAction => ({
  type: TaskActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const taskApiResponseError = (
  actionType: string,
  error: string
): TaskAction => ({
  type: TaskActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//fetch Category Action
export const fetchTaskAction = (reqBody: any) => ({
  type: TaskActionTypes.FETCH_TASKS,
  payload: reqBody,
});

//Add Category Action
export const addTaskAction = (addTaskDetails: any) => ({
  type: TaskActionTypes.ADD_TASK,
  payload: addTaskDetails,
});

// Edit Request SubAdmin
export const editTaskAction = (editTaskDetails: any) => ({
  type: TaskActionTypes.EDIT_TASK,
  payload: editTaskDetails,
});

// Delete Request SubAdmin
export const deleteTaskAction = (taskId: any) => ({
  type: TaskActionTypes.DELETE_TASK,
  payload: taskId,
});

//Fetch SubadminListing
export const fetchSubAdminListAction = () => ({
  type: TaskActionTypes.FETCH_SUBADMIN_LIST,
});

// Edit status Request SubAdmin
export const editStatusAction = (editStatusDetails: any) => ({
  type: TaskActionTypes.EDIT_STATUS_TASK,
  payload: editStatusDetails,
});


// Client Actions

//fetch Category Action
export const fetchClientTaskAction = (reqBody: any) => ({
  type: TaskActionTypes.FETCH_CLIENT_TASK,
  payload: reqBody,
});

//CustomerList Request..
export const customerListAction = (reqBody: any) => ({
  type: TaskActionTypes.CUSTOMER_LIST,
  payload: reqBody,
});