import { SubAdminActionTypes } from "./constant";

export interface SubAdminActionType {
  type:
    | SubAdminActionTypes.API_RESPONSE_SUCCESS
    | SubAdminActionTypes.API_RESPONSE_ERROR
    | SubAdminActionTypes.FETCH_SUBADMIN_REQUEST
    | SubAdminActionTypes.ADD_SUBADMIN_REQUEST
    | SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST
    | SubAdminActionTypes.DELETE_SUBADMIN_REQUEST
    | SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST;
  payload: {} | string;
}

interface SubAdminData {
  id: number;
  email: string;
}

// common success
export const subadminApiResponseSuccess = (
  actionType: string,
  data: SubAdminData | {}
): SubAdminActionType => ({
  type: SubAdminActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const subadminApiResponseError = (
  actionType: string,
  error: string
): SubAdminActionType => ({
  type: SubAdminActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Fetch request SubAdmin List
export const fetchSubAdminAction = (reqBody: any) => ({
  type: SubAdminActionTypes.FETCH_SUBADMIN_REQUEST,
  payload: reqBody,
});

//Fetch request Permission List
export const fetchPermissionListAction = () => ({
  type: SubAdminActionTypes.FETCH_PERMISSION_REQUEST,
});

//Add request SubAdmin List
export const AddSubAdminAction = (addSubAdminDetails: any) => ({
  type: SubAdminActionTypes.ADD_SUBADMIN_REQUEST,
  payload: addSubAdminDetails,
});

//Edit request SubAdmin List
export const EditSubAdminAction = (editSubAdminDetails: any) => ({
  type: SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST,
  payload: editSubAdminDetails,
});

// Delete Request SubAdmin
export const DeleteSubAdminAction = (user_id: any) => ({
  type: SubAdminActionTypes.DELETE_SUBADMIN_REQUEST,
  payload: user_id,
});

// Update status Request SubAdmin
export const editSubAdminStatusAction = (editStatusDetails: any) => ({
  type: SubAdminActionTypes.UPDATE_SUBADMIN_STATUS,
  payload: editStatusDetails,
});

// Update sub admin permission
export const updatePermissionAction = (reqBody: any) => ({
  type: SubAdminActionTypes.UPDATE_PERMISSION,
  payload: reqBody,
});
