import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Formik, Field, Form, FormikProps } from "formik";
import PageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  AddToCartAction,
  GetUserProductAction,
} from "../../redux/UserRedux/UserProduct/actions";
import Spinner from "../../components/Spinner";
import _ from 'lodash'; // Import lodash for debounce
import notify from "../../components/Notification/notify";

interface FormValues {
  quantity: number;
}

const Explore = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loadedProductIds, setLoadedProductIds] = useState<Set<number>>(new Set());
  const [searchQuery, setSearchQuery] = useState<string>(""); // State for search query
  const [filter, setFilter] = useState<string>(""); // State for filter
  const dispatch = useDispatch<AppDispatch>();
  const { productList, loading, error, isAddToCart, AddToCartMessage } =
    useSelector((state: RootState) => ({
      productList: state.UserProductReducer.message,
      loading: state.UserProductReducer.loading,
      error: state.UserProductReducer.error,
      isAddToCart: state.UserProductReducer.isAddToCart,
      AddToCartMessage: state.UserProductReducer.AddToCartMessage,
    }));

  const [reqBody, setReqBody] = useState({
    page_record: "10",
    page_no: "1",
    search: "",
    filter_by: "",
    sort_field: "created_datetime",
    sort_order: "desc",
  });

  // Debounce the search function
  const debouncedSearch = useCallback(
    _.debounce((query: string) => {
      searchProduct(query);
    }, 300), // 300ms debounce delay
    []
  );

  useEffect(() => {
    loadProducts();
  }, [page]);

  useEffect(() => {
    if (
      productList?.statusCode === 200 &&
      productList?.data?.rows !== null &&
      productList?.data?.rows !== undefined
    ) {
      const newProducts = productList?.data?.rows || [];
      const newProductIds: any = new Set(newProducts.map((product: any) => product.product_id));

      if (page === 1) {
        setProducts(newProducts);
        setLoadedProductIds(newProductIds);
      } else {
        const filteredNewProducts = newProducts.filter((product: any) => !loadedProductIds.has(product.product_id));
        setProducts(prevProducts => [...prevProducts, ...filteredNewProducts]);
        setLoadedProductIds((prevIds: any) => new Set([...prevIds, ...newProductIds]));
      }
      if (productList?.data?.hasMore) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    }
  }, [productList, page]);

  useEffect(() => {
    if (
      isAddToCart === true &&
      AddToCartMessage !== null &&
      AddToCartMessage !== undefined &&
      AddToCartMessage?.statusCode === 200
    ) {
      dispatch(GetUserProductAction(reqBody));
    }
  }, [isAddToCart, dispatch, AddToCartMessage]);

  const loadProducts = async () => {
    const updatedReqBody = {
      ...reqBody,
      page_no: page.toString(),
    };
    try {
      dispatch(GetUserProductAction(updatedReqBody));
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const searchProduct = async (value: string) => {
    const updatedReqBody: any = {
      ...reqBody,
      page_no: 1,
      search: value,
    };
    setReqBody(updatedReqBody);
    setPage(1); // Reset page to 1 on search change
    dispatch(GetUserProductAction(updatedReqBody));
  };

  const handleFilterChange = async (value: string) => {
    setFilter(value)
    const updatedReqBody = {
      ...reqBody,
      filter_by: value,
    };
    setReqBody(updatedReqBody);
    setPage(1); // Reset page to 1 on filter change
    dispatch(GetUserProductAction(updatedReqBody));
  };

  const handleIncrement = (
    formik: FormikProps<FormValues>,
    fieldName: keyof FormValues
  ) => {
    const currentValue = formik.values[fieldName];
    formik.setFieldValue(fieldName, currentValue + 1);
  };

  const handleDecrement = (
    formik: FormikProps<FormValues>,
    fieldName: keyof FormValues
  ) => {
    const currentValue = formik.values[fieldName];
    formik.setFieldValue(fieldName, currentValue > 1 ? currentValue - 1 : 1);
  };

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Products", path: "/explore", active: true },
        ]}
        title={"Products"}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="justify-content-between">
                <Col className="col-auto">
                  <form className="d-flex flex-wrap align-items-center">
                    <label htmlFor="inputPassword2" className="visually-hidden">
                      Search
                    </label>
                    <div className="me-3">
                      <input
                        type="search"
                        className="form-control my-1 my-lg-0"
                        id="inputPassword2"
                        placeholder="Search..."
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          debouncedSearch(e.target.value);
                        }}
                        value={searchQuery}
                      />
                    </div>
                    <label htmlFor="status-select" className="me-2">
                      Sort By
                    </label>
                    <div className="me-sm-3">
                      <select
                        className="form-select my-1 my-lg-0"
                        id="status-select"
                        onChange={(e) => handleFilterChange(e.target.value)}
                        value={filter}
                      >
                        <option defaultValue="all">All</option>
                        <option value="pricelow">Price Low</option>
                        <option value="pricehigh">Price High</option>
                      </select>
                    </div>
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {loading ? (
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <div className="text-center">
                    <Spinner className="m-2" color={"primary"} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            {products.length > 0 ? (
              products.map((item: any, index: any) => {
                let initialValues = {
                  quantity: item.qty >= 1 ? item.qty : 1,
                };
                return (
                  <Col key={index} md={6} lg={4} xl={3}>
                    <div className="m-1">
                      <Card className="product-box h-100">
                        <Card.Body>
                          <Link
                            to={`/user/productDetails/${item.product_id}`}
                            className="text-dark"
                          >
                            <div className="bg-white">
                              <img
                                src={
                                  item.wm_product_photos.length > 0
                                    ? item.wm_product_photos[0]?.photo_url
                                    : "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-1.png"
                                }
                                alt=""
                                className="img-fluid"
                                style={{ objectFit: 'cover', minHeight: '300px', maxHeight: '300px', display: 'block', margin: 'auto' }}
                              />
                            </div>
                          </Link>

                          <div className="product-info" style={{ display: 'block', margin: 'auto' }}>
                            <div className="row align-items-center">
                              <Link
                                to={`/user/productDetails/${item.product_id}`}
                                className="text-dark"
                              >
                                <div className="row align-items-center">
                                  <div className="col mb-3">
                                    <h5 className="font-16 mt-0 sp-line-1">
                                      <Link
                                        to={`/user/productDetails`}
                                        className="text-dark"
                                      >
                                        {item.product_name}
                                      </Link>
                                    </h5>
                                    <div className="text-warning mb-2 font-13">
                                      <i className="fa fa-star me-1"></i>
                                      <i className="fa fa-star me-1"></i>
                                      <i className="fa fa-star me-1"></i>
                                      <i className="fa fa-star me-1"></i>
                                      <i className="fa fa-star"></i>
                                    </div>
                                    <h5 className="m-0">
                                      <span className="text-muted">
                                        Stocks : {item.stock ? item?.stock : 0} pcs
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="col-auto mb-3">
                                    <div className="product-price-tag">
                                      ₹ {parseFloat(item.rrr_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                              <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={(values) => {
                                  const data = {
                                    product_id: item.product_id,
                                    qty: values.quantity,
                                  };
                                  dispatch(AddToCartAction(data));
                                }}
                              >
                                {({ formik, values, setFieldValue, setFieldError }: any) => (
                                  <Form>
                                    <div className="row justify-content-center align-items-center">
                                      <div className="col-md-8 d-flex justify-content-start align-items-center">
                                        <Link
                                          to=""
                                          className="me-2 action-icon"
                                          onClick={(e: any) => {
                                            let x: any = values.quantity - 1
                                            let value = parseInt(x, 10);
                                            if (isNaN(value) || value < 1) {
                                              setFieldError('quantity', 'Quantity must be at least 1 and a number');
                                              notify('There should be at least one quantity.', 'error');
                                            } else if (value > item?.stock) {
                                              setFieldError('quantity', `Cannot add more than ${item?.stock} items.`);
                                              notify(`Cannot add more than ${item?.stock} items to the cart.`, 'error');
                                            } else {
                                              setFieldValue('quantity', value);
                                            }
                                          }}
                                        >
                                          <i className="mdi mdi-minus fs-4"></i>
                                        </Link>

                                        {/* <Field
                                        className="form-control text-center"
                                        style={{ width: "60px" }}
                                        type="number"
                                        id="quantity"
                                        name="quantity"
                                        min={1}
                                      /> */}

                                        <Field
                                          type="number"
                                          name="quantity"
                                          min={1}
                                          className="form-control bg-transparent"
                                          placeholder="Qty"
                                          style={{ width: '90px' }}
                                          value={values.quantity}
                                          onBlur={(e: any) => {
                                            const value = parseInt(e.target.value, 10);
                                            if (isNaN(value) || value < 1) {
                                              setFieldError('quantity', 'Quantity must be at least 1 and a number');
                                              notify('There should be at least one quantity.', 'error');
                                            } else if (value > item?.stock) {
                                              setFieldError('quantity', `Cannot add more than ${item?.stock} items.`);
                                              notify(`Cannot add more than ${item?.stock} items to the cart.`, 'error');
                                            } else {
                                              setFieldValue('quantity', value);
                                            }
                                          }}
                                          onChange={(e: any) => {
                                            const value = parseInt(e.target.value, 10);
                                            if (isNaN(value) || value < 1) {
                                              setFieldError('quantity', 'Quantity must be at least 1 and a number');
                                              notify('There should be at least one quantity.', 'error');
                                            } else if (value > item?.stock) {
                                              setFieldError('quantity', `Cannot add more than ${item?.stock} items.`);
                                              notify(`Cannot add more than ${item?.stock} items to the cart.`, 'error');
                                            } else {
                                              setFieldValue('quantity', value);
                                              // const productRes = {
                                              //   product_id: item.product_id,
                                              //   qty: e.target.value,
                                              // };
                                              // dispatch(OrderAddToCartAction(productRes));
                                            }
                                          }}
                                        />

                                        <Link
                                          to=""
                                          className="ms-2 action-icon"
                                          onClick={(e: any) => {
                                            let value = parseInt(values.quantity + 1, 10);
                                            if (isNaN(value) || value < 1) {
                                              setFieldError('quantity', 'Quantity must be at least 1 and a number');
                                              notify('There should be at least one quantity.', 'error');
                                            } else if (value > item?.stock) {
                                              setFieldError('quantity', `Cannot add more than ${item?.stock} items.`);
                                              notify(`Cannot add more than ${item?.stock} items to the cart.`, 'error');
                                            } else {
                                              setFieldValue('quantity', value);
                                            }
                                          }}
                                        >
                                          <i className="mdi mdi-plus fs-4"></i>
                                        </Link>
                                      </div>
                                      {item && item.qty > 0 ? (
                                        <div className="col-md-4 align-item-center text-md-end">
                                          <Button variant="warning" type="submit">
                                            Add more
                                          </Button>
                                        </div>
                                      ) : (
                                        <div className="col-md-4 align-item-center text-md-end">
                                          <Button variant="primary" type="submit">
                                            Add to Cart
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                );
              })
            ) : (
              !loading && (
                <Col>
                  <Card>
                    <Card.Body>
                      <div className="text-center">
                        <h2>No Data</h2>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            )}
          </>
        )}
      </Row>

      {hasMore && !loading && (
        <Row className="justify-content-center my-4">
          <Col xs="auto">
            <Button variant="primary" onClick={handleLoadMore}>
              Load More
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default Explore;