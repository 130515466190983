import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import { productApiResponseError, productApiResponseSuccess } from "./actions";
import { ProductActionTypes } from "./constants";
// import {fetchProductsAPI} from "./api";
import { fetchCategory } from "../../helpers/api/categoryApi";
import { fetchProduct } from "../../helpers";
import {
  UpdateProductStatusApi,
  UpdateProductStockApi,
  addProduct,
  deleteProductDetails,
  editProduct,
  fetchProductDetails,
} from "../../helpers/api/adminProduct";
import notify from "../../components/Notification/notify";
import Swal from "sweetalert2";

interface ProductData {
  payload: any;
  // payload: {
  //   name: string;
  //   price: string;
  //   stock: string;
  // };
  type: string;
}

// ? fetch product saga
function* fetchProductSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      filter_by: payload.filter_by ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(fetchProduct, { data });
    const productData: any = response.data.data;
    yield put(productApiResponseSuccess(ProductActionTypes.FETCH_PRODUCT, productData));
  } catch (error: any) {
    yield put(productApiResponseError(ProductActionTypes.FETCH_PRODUCT, error));
  }
}

export function* watchFetchProduct() {
  yield takeEvery(ProductActionTypes.FETCH_PRODUCT, fetchProductSaga);
}

// ? add product saga

function* addProductSaga(action: any): SagaIterator {
  try {
    const response = yield call(addProduct, action.payload);
    const productData: any = response.data;
    yield put(
      productApiResponseSuccess(ProductActionTypes.ADD_PRODUCT, productData)
    );
    yield call(notify, `${productData.message}`, "success");
  } catch (error: any) {
    yield put(productApiResponseError(ProductActionTypes.ADD_PRODUCT, error));
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddProduct() {
  yield takeEvery(ProductActionTypes.ADD_PRODUCT, addProductSaga);
}

// ? fetch product details saga
function* fetchProductDetailsSaga(action: ProductData): SagaIterator {
  try {
    let { product_id } = action.payload;
    const response = yield call(fetchProductDetails, product_id);
    const productData: any = response.data;
    yield put(
      productApiResponseSuccess(
        ProductActionTypes.FETCH_PRODUCT_DETAILS,
        productData
      )
    );
  } catch (error: any) {
    yield put(
      productApiResponseError(ProductActionTypes.FETCH_PRODUCT_DETAILS, error)
    );
  }
}

export function* watchFetchProductDetailsSaga() {
  yield takeEvery(
    ProductActionTypes.FETCH_PRODUCT_DETAILS,
    fetchProductDetailsSaga
  );
}

// ? edit product saga
function* editProductSaga({ payload: data }: ProductData): SagaIterator {
  try {
    const response = yield call(editProduct, { data });
    const productData: any = response.data;
    yield put(
      productApiResponseSuccess(ProductActionTypes.EDIT_PRODUCT, productData)
    );
    yield call(notify, `${productData.message}`, "success");
  } catch (error: any) {
    yield put(productApiResponseError(ProductActionTypes.EDIT_PRODUCT, error));
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditProduct() {
  yield takeEvery(ProductActionTypes.EDIT_PRODUCT, editProductSaga);
}

// ? product delete saga
function* deleteProductDetailsSaga(action: ProductData): SagaIterator {
  try {
    let { product_id } = action.payload;
    const response = yield call(deleteProductDetails, product_id);
    const productData: any = response.data;
    yield put(
      productApiResponseSuccess(ProductActionTypes.DELETE_PRODUCT, productData)
    );
  } catch (error: any) {
    // Swal.fire("Error", "Something went wrong!", "error");
    yield put(
      productApiResponseError(ProductActionTypes.DELETE_PRODUCT, error)
    );
  }
}

export function* watchDeleteProductDetailsSaga() {
  yield takeEvery(ProductActionTypes.DELETE_PRODUCT, deleteProductDetailsSaga);
}

//Update product status Saga
function* updateProductStatus({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(UpdateProductStatusApi, { data });
    const payload: any = response.data;
    yield put(
      productApiResponseSuccess(
        ProductActionTypes.UPDATE_PRODUCT_STATUS,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      productApiResponseError(ProductActionTypes.UPDATE_PRODUCT_STATUS, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchUpdateOrderStatus() {
  yield takeEvery(
    ProductActionTypes.UPDATE_PRODUCT_STATUS,
    updateProductStatus
  );
}


function* updateProductStock({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(UpdateProductStockApi, { data });
    const payload: any = response.data;
    yield put(
      productApiResponseSuccess(
        ProductActionTypes.UPDATE_PRODUCT_STOCK,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      productApiResponseError(ProductActionTypes.UPDATE_PRODUCT_STOCK, error)
    );
  }
}

export function* watchUpdateOrderStock() {
  yield takeEvery(
    ProductActionTypes.UPDATE_PRODUCT_STOCK,
    updateProductStock
  );
}

function* productSaga() {
  yield all([
    fork(watchFetchProduct),
    fork(watchAddProduct),
    fork(watchFetchProductDetailsSaga),
    fork(watchEditProduct),
    fork(watchDeleteProductDetailsSaga),
    fork(watchUpdateOrderStatus),
    fork(watchUpdateOrderStock),
  ]);
}

export default productSaga;
