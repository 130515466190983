import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  adminProfileApiResponseSuccess,
  adminProfileApiResponseError,
} from "./actions";
import { AdminProfileActionTypes } from "./constants";
import { AdminProfile, editAdminProfile, updateAvatarAPI } from "../../helpers/api/adminProfile";
import notify from "../../components/Notification/notify";
import { APICore } from "../../helpers/api/apiCore";
let api = new APICore();

// Edit Profile Request ......
function* editProfileSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(editAdminProfile, { data });
    const payload: any = response.data;
    yield put(
      adminProfileApiResponseSuccess(
        AdminProfileActionTypes.EDIT_PROFILE_REQUEST,
        payload
      )
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      adminProfileApiResponseError(
        AdminProfileActionTypes.EDIT_PROFILE_REQUEST,
        error
      )
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditProfileSaga() {
  yield takeEvery(
    AdminProfileActionTypes.EDIT_PROFILE_REQUEST,
    editProfileSaga
  );
}

function* AdminProfileGetSaga(): SagaIterator {
  try {
    const response = yield call(AdminProfile);
    const payload: any = response.data;
    const PermissionStorage = JSON.stringify(payload?.data?.permission);
    sessionStorage.setItem("admin_permissions", PermissionStorage);
    api.setLoggedInUser({
      ...payload?.data,
      token: payload?.data?.access_token,
    });
    yield put(
      adminProfileApiResponseSuccess(
        AdminProfileActionTypes.ADMIN_PROFILE_GET,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      adminProfileApiResponseError(
        AdminProfileActionTypes.ADMIN_PROFILE_GET,
        error
      )
    );
  }
}

export function* watchAdminProfileGet() {
  yield takeEvery(
    AdminProfileActionTypes.ADMIN_PROFILE_GET,
    AdminProfileGetSaga
  );
}


function* UpdateAvatarSaga(action: any): SagaIterator {
  try {
    const response = yield call(updateAvatarAPI, action.payload);
    const payload: any = response.data;
    yield put(
      adminProfileApiResponseSuccess(
        AdminProfileActionTypes.UPDATE_AVATAR,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      adminProfileApiResponseError(
        AdminProfileActionTypes.UPDATE_AVATAR,
        error
      )
    );
  }
}

export function* watchUpdateAvatarSaga() {
  yield takeEvery(
    AdminProfileActionTypes.UPDATE_AVATAR,
    UpdateAvatarSaga
  );
}


function* adminProfileSaga() {
  yield all([fork(watchEditProfileSaga), fork(watchAdminProfileGet), fork(watchUpdateAvatarSaga)]);
}

export default adminProfileSaga;
