import { APICore } from "../apiCore";
import config from "../../../config";
const api = new APICore();

// Listing User Product
function FetchUserProduct(params: any) {
  const baseUrl = config.API_URL + "/listProducts";
  return api.create(baseUrl, { ...params?.data });
}

// Listing Admin Profile
function AddToCartApi(params: any) {
  const baseUrl = config.API_URL + "/addToCart";
  return api.create(baseUrl, { ...params?.data });
}

// ? fetch product details
function GetUserProductDetails(product_id: any) {
  const baseUrl = config.API_URL + `/getProductDetails/${product_id}`;
  return api.get(baseUrl, null);
}

export { FetchUserProduct, AddToCartApi, GetUserProductDetails };
