import { SubAdminActionTypes } from "./constant";

const INIT_STATE = {
  permissionDetails: [],
  subAdminDetails: [],
  successMessage: null,
  loading: false,
  error: null,
  is_add: false,
  is_deleted: false,
  subAdminError: null,
  editStatusMessage: null,
  permissionMessage: null,
};

interface subAdminData {
  subAdminDetails: any;
  successMessage: any;
  loading: boolean;
  error: any;
  is_add: boolean;
  is_deleted: boolean;
  subAdminError: any;
  editStatusMessage: any;
  permissionMessage: any;
}

export interface SubAdminActionType {
  type:
    | SubAdminActionTypes.API_RESPONSE_SUCCESS
    | SubAdminActionTypes.API_RESPONSE_ERROR
    | SubAdminActionTypes.FETCH_PERMISSION_REQUEST
    | SubAdminActionTypes.FETCH_SUBADMIN_REQUEST
    | SubAdminActionTypes.ADD_SUBADMIN_REQUEST
    | SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST
    | SubAdminActionTypes.DELETE_SUBADMIN_REQUEST
    | SubAdminActionTypes.UPDATE_SUBADMIN_STATUS
    | SubAdminActionTypes.UPDATE_PERMISSION;
  payload: {
    actionType?: string;
    data?: subAdminData | {};
    error?: string;
  };
}

interface State {
  permissionDetails?: {};
  subAdminDetails?: subAdminData | {};
  successMessage?: any;
  loading?: boolean;
  value?: boolean;
  is_add?: boolean;
  is_edit?: boolean;
  is_deleted?: boolean;
  subAdminError?: any;
  editStatusMessage: any;
  permissionMessage: any;
}

const SubAdminReducer = (
  state: State = INIT_STATE,
  action: SubAdminActionType
): any => {
  switch (action.type) {
    case SubAdminActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SubAdminActionTypes.FETCH_PERMISSION_REQUEST: {
          return {
            ...state,
            permissionDetails: action.payload.data,
            loading: false,
          };
        }
        case SubAdminActionTypes.FETCH_SUBADMIN_REQUEST: {
          return {
            ...state,
            subAdminDetails: action.payload.data,
            loading: false,
          };
        }
        case SubAdminActionTypes.ADD_SUBADMIN_REQUEST: {
          return {
            ...state,
            loading: false,
            successMessage: action.payload.data,
            is_add: false,
          };
        }

        case SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST: {
          return {
            ...state,
            successMessage: action.payload.data,
            is_edit: false,
            loading: false,
          };
        }

        case SubAdminActionTypes.DELETE_SUBADMIN_REQUEST: {
          return {
            ...state,
            loading: false,
            is_deleted: false,
          };
        }

        case SubAdminActionTypes.UPDATE_SUBADMIN_STATUS: {
          return {
            ...state,
            loading: false,
            editStatusMessage: action.payload.data,
            is_deleted: false,
          };
        }

        case SubAdminActionTypes.UPDATE_PERMISSION: {
          return {
            ...state,
            loading: false,
            permissionMessage: action.payload.data,
          };
        }

        default:
          return { ...state };
      }

    case SubAdminActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SubAdminActionTypes.FETCH_PERMISSION_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case SubAdminActionTypes.FETCH_SUBADMIN_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case SubAdminActionTypes.ADD_SUBADMIN_REQUEST: {
          return {
            ...state,
            subAdminError: action.payload.error,
            loading: false,
            is_add: false,
          };
        }

        case SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST: {
          return {
            ...state,
            subAdminError: action.payload.error,
            is_edit: false,
            loading: false,
          };
        }

        case SubAdminActionTypes.DELETE_SUBADMIN_REQUEST: {
          return {
            ...state,
            loading: false,
            is_deleted: false,
          };
        }

        case SubAdminActionTypes.UPDATE_SUBADMIN_STATUS: {
          return {
            ...state,
            loading: false,
            error: action.payload.data,
            is_deleted: false,
          };
        }

        case SubAdminActionTypes.UPDATE_PERMISSION: {
          return {
            ...state,
            loading: false,
            error: action.payload.data,
          };
        }

        default:
          return { ...state };
      }

    case SubAdminActionTypes.FETCH_PERMISSION_REQUEST:
      return { ...state, loading: true };

    case SubAdminActionTypes.FETCH_SUBADMIN_REQUEST:
      return { ...state, loading: true };

    case SubAdminActionTypes.ADD_SUBADMIN_REQUEST:
      return { ...state, loading: true, is_add: true };

    case SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST:
      return { ...state, loading: true, is_edit: true };

    case SubAdminActionTypes.DELETE_SUBADMIN_REQUEST:
      return { ...state, loading: true, is_deleted: true };

    case SubAdminActionTypes.UPDATE_SUBADMIN_STATUS:
      return { ...state, loading: true };

    case SubAdminActionTypes.UPDATE_PERMISSION:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};
export default SubAdminReducer;
