import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Orders
function fetchOrders(params: any) {
  const baseUrl = config.API_URL + "/admin/getAllOrders";
  return api.create(baseUrl, { ...params?.data });
}

// Listing Orders
function fetchNewOrderRequest(params: any) {
  const baseUrl = config.API_URL + "/admin/getOrderRequests";
  return api.create(baseUrl, { ...params?.data });
}

// Actions Orders
function fetchActionOrder(params: any) {
  const baseUrl = config.API_URL + "/admin/orderRequestActions";
  return api.create(baseUrl, { ...params?.data });
}

// Actions Perticular Orders Get
function fetchOrderDetails(order_id: any) {
  const baseUrl = config.API_URL + `/admin/getOrderDetails/${order_id}`;
  return api.get(baseUrl, null);
}

// Update Order status
function UpdateOrderStatusApi(params: any) {
  const baseUrl = config.API_URL + `/admin/changeOrderStatus`;
  return api.create(baseUrl, { ...params?.data });
}

// Add Order qty
function AddOrderQtyApi(params: any) {
  const baseUrl = config.API_URL + `/admin/addItemInOrder`;
  return api.create(baseUrl, { ...params?.data });
}

// Delete Order qty
function DeleteOrderApi(params: any) {
  const baseUrl = config.API_URL + `/admin/deleteItemInOrder`;
  return api.create(baseUrl, { ...params?.data });
}

// Listing Orders Product
function fetchOrderProduct(params: any) {
  const baseUrl = config.API_URL + "/admin/listProductsOrder";
  return api.create(baseUrl, { ...params?.data });
}

// Listing Orders Customers
function listOrderCustomers(params: any) {
  const baseUrl = config.API_URL + "/admin/listCustomersOrder";
  return api.create(baseUrl, { ...params?.data });
}

// Added Orders Product
function addCustomerOrder(params: any) {
  const baseUrl = config.API_URL + "/admin/addCustomerOrder";
  return api.create(baseUrl, { ...params?.data });
}

export {
  fetchOrders,
  fetchNewOrderRequest,
  fetchActionOrder,
  fetchOrderDetails,
  UpdateOrderStatusApi,
  AddOrderQtyApi,
  DeleteOrderApi,
  fetchOrderProduct,
  listOrderCustomers,
  addCustomerOrder
};
