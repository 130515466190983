import { UserCartActionTypes } from "./constants";

const INIT_STATE = {
  message: null,
  deleteMessage: null,
  placeOrderDetails: null,
  isDeleted: false,
  isPlaced: false,
  loading: false,
  error: null,
  isOrderCart: false,
  orderCartMsg: null,

};

export interface UserCartActionType {
  type:
    | UserCartActionTypes.API_RESPONSE_SUCCESS
    | UserCartActionTypes.API_RESPONSE_ERROR
    | UserCartActionTypes.GET_USER_SHOPPING_LIST
    | UserCartActionTypes.GET_REMOVE_PRODUCT
    | UserCartActionTypes.PLACE_ORDER
    | UserCartActionTypes.ORDER_ADD_TO_CART;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  message?: any;
  deleteMessage?: any;
  placeOrderDetails?: any;
  isDeleted?: boolean;
  isPlaced?: boolean;
  loading?: boolean;
  value?: boolean;
  isOrderCart?: boolean;
  orderCartMsg?: any;
}

const UserCartReducer = (
  state: State = INIT_STATE,
  action: UserCartActionType
): any => {
  switch (action.type) {
    case UserCartActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UserCartActionTypes.GET_USER_SHOPPING_LIST: {
          return {
            ...state,
            message: action.payload.data,
            loading: false,
          };
        }

        case UserCartActionTypes.GET_REMOVE_PRODUCT: {
          return {
            ...state,
            deleteMessage: action.payload.data,
            isDeleted: false,
            loading: false,
          };
        }

        case UserCartActionTypes.PLACE_ORDER: {
          return {
            ...state,
            placeOrderDetails: action.payload.data,
            isPlaced: true,
            loading: false,
          };
        }

        case UserCartActionTypes.ORDER_ADD_TO_CART: {
          return {
            ...state,            
            orderCartMsg: action.payload.data,
            isOrderCart: false,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case UserCartActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UserCartActionTypes.GET_USER_SHOPPING_LIST: {
          return {
            ...state,
            error: action.payload.data,
            loading: false,
          };
        }

        case UserCartActionTypes.GET_REMOVE_PRODUCT: {
          return {
            ...state,
            error: action.payload.error,
            isDeleted: false,
            loading: false,
          };
        }

        case UserCartActionTypes.PLACE_ORDER: {
          return {
            ...state,
            error: action.payload.error,
            isPlaced: false,
            loading: false,
          };
        }

        case UserCartActionTypes.ORDER_ADD_TO_CART: {
          return {
            ...state,
            error: action.payload.error,
            isOrderCart: false,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case UserCartActionTypes.GET_USER_SHOPPING_LIST:
      return { ...state, loading: true };

    case UserCartActionTypes.GET_REMOVE_PRODUCT:
      return { ...state, loading: true, isDeleted: true };

    case UserCartActionTypes.PLACE_ORDER:
      return { ...state, loading: true, isPlaced: true };

    case UserCartActionTypes.ORDER_ADD_TO_CART:
      return { ...state, loading: true, isOrderCart: true };

    default:
      return { ...state };
  }
};
export default UserCartReducer;
