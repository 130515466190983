import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// dummy data
// import {
//   UpdateUserStatus,
//   fetchClientCustomerAction
// } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "../../../../redux/store";
import Swal from "sweetalert2";
// import ShowCustomer from "./ShowCustomer";
// import CustomTable from "../../../../components/CustomTable";
import { Formik } from "formik";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  DeleteCustomerAction,
  fetchClientCustomerAction,
  UpdateUserStatus,
} from "../../../redux/actions";
import CustomTable from "../../../components/CustomTable";
import { CUSTOMER_STATUS } from "../../../constants/status";
import AddClientCustomer from "./AddClientCustomer";
import ShowCustomer from "../CRM/Customers/ShowCustomer";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import moment from "moment";
// import { CUSTOMER_STATUS } from "../../../../constants/status";

//Product status
const customerStatus = [
  {
    status: 1,
    name: "Active",
    class: "success",
  },
  {
    status: 2,
    name: "De-active",
    class: "danger",
  },
];

/* name column render */
const NameColumn = ({ row }: { row: any }) => {
  return (
    <div className="table-user">
      <img
        src={
          row?.original?.profile_image
            ? row?.original?.profile_image
            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNUKbV4ZCrVOwTMkfnN10Mfhwp7BSiVb64BzDuE_lA9w&s"
        }
        alt=""
        className="me-2 rounded-circle"
      />
      <Link to="#" className="fw-semibold text-dark">
        {row.original.name}
      </Link>
    </div>
  );
};

/* action column render */
const ActionColumn = () => {
  return (
    <React.Fragment>
      <Link to="#" className="action-icon">
        <i className="mdi mdi-eye"></i>
      </Link>
      <Link to="#" className="action-icon">
        <i className="mdi mdi-square-edit-outline"></i>
      </Link>
      <Link to="#" className="action-icon">
        <i className="mdi mdi-delete"></i>
      </Link>
    </React.Fragment>
  );
};

/* date column render */
function formatUnixTimestamp(unixTimestamp: any) {
  // Convert Unix timestamp to milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Define month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month, day, year, hours, and minutes
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Add leading zero to minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Construct the formatted string
  const formattedDate = `${month} ${day} ${year} ${hours}:${formattedMinutes} ${period}`;

  return formattedDate;
}

const DateColumn = ({ row }: { row: any }) => {
  const created_date = formatUnixTimestamp(row.original?.created_datetime);
  return (
    <>
      <span className="text-dark"> {created_date} </span>
    </>
  );
};

// main component
const ClientCustomers = () => {
  /*
   *   modal handling
   */
  const [show, setShow] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [customerList, setCustomerList] = useState<any>([]);
  const [customerData, setCustomerData] = useState<any>({});
  const [userStatuses, setUserStatuses] = useState<any>({});
  const [user, setUser] = useState<any>({});

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const [reqBody, setReqBody] = useState<any>({
    page_record: pageSize && 10,
    page_no: pageIndex && 1,
    search: "",
    filter_by: "",
    sort_field: "created_datetime",
    sort_order: "desc",
    date_range: {}
  });

  const {
    customerDetails,
    loading,
    error,
    changeStatusDetails,
    userStatus,
    updateUserData,
  } = useSelector((state: RootState) => ({
    customerDetails: state.CustomerReducer.customerDetails,
    loading: state.CustomerReducer.loading,
    error: state.CustomerReducer.error,
    changeStatusDetails: state.CustomerReducer.changeStatusDetails,
    userStatus: state.CustomerReducer.userStatus,
    updateUserData: state.CustomerReducer.updateUserData,
  }));

  // give page size
  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    }
    // {
    //   text: "All",
    //   value: list.length,
    // },
  ];

  //Fetch Customer List useEffect
  // useEffect(() => {
  //   dispatch(fetchClientCustomerAction({}));
  // }, [dispatch]);

  useEffect(() => {
    if (
      customerDetails?.statusCode === 200 &&
      customerDetails?.data !== undefined &&
      customerDetails?.data !== null
    ) {
      setCustomerList(customerDetails?.data?.rows);
      setTotalRecords(customerDetails?.data?.count);
    }
  }, [customerDetails]);

  useEffect(() => {
    if (changeStatusDetails !== undefined && changeStatusDetails !== null) {
      onCloseModal();
      dispatch(fetchClientCustomerAction({}));
    }
  }, [changeStatusDetails, dispatch]);

  useEffect(() => {
    if (userStatus) {
      onCloseModal();
      dispatch(fetchClientCustomerAction({}));
    }
  }, [userStatus, dispatch]);

  useEffect(() => {
    if (updateUserData) {
      onCloseModal();
      dispatch(fetchClientCustomerAction({}));
    }
  }, [updateUserData, dispatch]);

  // const onOpenModal = () => setShow(true);

  /*
    handle form submission
    */
  const onSubmit = () => {
    onCloseModal();
  };

  /* status column render */
  const StatusColumn = ({ row, rowIndex }: { row: any; rowIndex: number }) => {
    const handleChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      userId: any
    ) => {
      const selectedStatus = parseInt(e.target.value);
      const data = {
        user_id: userId,
        status: selectedStatus,
      };
      dispatch(UpdateUserStatus(data));
      setUserStatuses((prevStatuses: any) => ({
        ...prevStatuses,
        [userId]: selectedStatus,
      }));
    };
    return (
      <Formik
        initialValues={{ status: row.original.status }}
        onSubmit={() => { }}
      >
        {({ values }) => (
          <select
            className="form-select bg-transparent text-dark"
            id={`status_${rowIndex}`}
            name={`status_${rowIndex}`}
            value={userStatuses[row.original.user_id] || row.original.status}
            onChange={(e) => handleChange(e, row.original.user_id)}
          >
            {CUSTOMER_STATUS.length > 0 &&
              CUSTOMER_STATUS.map((item: any) => (
                <option key={item.status} value={item.status}>
                  {item.name}
                </option>
              ))}
          </select>
        )}
      </Formik>
    );
  };

  const columns = [
    {
      Header: "ID",
      accessor: "user_code",
      classes: "table-user",
      Cell: ({ row }: any) => (
        <>
          <Link to="#" className="fw-bold text-dark">
            {row?.original?.user_code ? `#${row?.original?.user_code}` : "-"}
          </Link>
        </>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      sort: false,
      Cell: NameColumn,
      // classes: "table-user",
      className: "table-user text-capitalize",
    },
    {
      Header: "Company Name",
      accessor: "wm_user_profile.company_name",
      Cell: ({ row }: any) => (
        <>
          <span className="text-capitalize text-dark">
            {row?.original?.wm_user_profile?.company_name
              ? row?.original?.wm_user_profile?.company_name
              : "-"}
          </span>
        </>
      ),
      sort: false,
      className: "table-user text-capitalize",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }: any) => (
        <>
          <span className="text-capitalize text-dark">
            {row?.original?.wm_user_profile?.phone_number
              ? row?.original?.wm_user_profile?.phone_number
              : "-"}
          </span>
        </>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      sort: false,
    },
    {
      Header: "Password",
      accessor: "user_password",
      Cell: ({ row, rowIndex }: { row: any; rowIndex: number }) => {
        const [showPassword, setShowPassword] = useState(false);

        const togglePasswordVisibility = () => {
          setShowPassword(!showPassword);
        };
        const password = row.original.user_password;
        return (
          <>
            <div className="d-flex justify-content-left align-items-center">
              <span
                className={
                  showPassword ? "mdi mdi-eye-off me-2" : "mdi mdi-eye me-2"
                }
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              ></span>
              <div
                className="password-container text-truncate text-dark"
                style={{ maxWidth: "150px" }}
              >
                <span>
                  {showPassword
                    ? password
                    : "*".repeat(row.original.user_password.length)}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      Header: "Create Date",
      accessor: "created_datetime",
      Cell: DateColumn,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: false,
      style: { minWidth: "150px" },
      Cell: StatusColumn,
    },
    {
      Header: "Action",
      accessor: "action",
      style: { minWidth: "150px" },
      Cell: ({ row }: any) => (
        <>
          <Link
            to="#"
            className="action-icon text-dark"
            onClick={() => onOpenShowModal(row.original)}
          >
            <i className="mdi mdi-eye"></i>
          </Link>
          <Link
            to=""
            className="action-icon text-dark"
            onClick={() => onOpenEditModal(row.original)}
          >
            <i className="mdi mdi-square-edit-outline"></i>
          </Link>
          <Link
            to=""
            className="action-icon text-dark"
            onClick={() => handleDelete(row.original)}
          >
            <i className="mdi mdi-delete"></i>
          </Link>
        </>
      ),
    },
  ];

  const handleDelete = (data: any) => {
    const id: any = data?.user_id;
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(DeleteCustomerAction(id));
          Swal.fire("Deleted!", "Your item has been deleted.", "success").then(
            () => {
              // Dispatch fetch_data action after success message is shown
              dispatch(fetchClientCustomerAction({}));
            }
          );
        } else {
          Swal.fire("Cancelled", "Your action has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const onOpenEditModal = (row: any) => {
    let _obj = {
      user_id: row?.user_id,
      name: row?.name,
      email: row?.email,
      password: row?.user_password,
      company_name: row.wm_user_profile.company_name,
      address_main_line: row.wm_user_profile.address_main_line,
      city: row.wm_user_profile.city,
      state: row.wm_user_profile.state,
      postcode: row.wm_user_profile.postcode,
      country: row.wm_user_profile.country,
      phone_number: row.wm_user_profile.phone_number,
      landline_number: row.wm_user_profile.landline_number,
      gst_number: row.wm_user_profile.gst_number,
    };
    setUser(_obj);
    setShow(true);
  };

  const onCloseModal = () => {
    setShow(false);
    setShowDetails(false);
    setCustomerData({});
  };
  const onOpenShowModal = (row: any) => {
    setShowDetails(true);
    setCustomerData(row);
  };

  const onOpenModal = () => {
    setUser({});
    setShow(true);
  };

  // Function to handle search using API call
  const searchUsers = async (value: string) => {
    const updatedReqBody = {
      ...reqBody,
      search: value,
    };
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const sortUsers = async (value: any) => {
    let updatedReqBody = {
      ...reqBody,
      sort_field: value.column ?? "created_datetime",
      sort_order: value.direction ?? "desc",
    };
    if (!value.direction) {
      updatedReqBody = {
        ...reqBody,
        sort_field: "created_datetime",
        sort_order: "desc",
      };
    }
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const InvokeAPI = async (reqBody: any) => {
    try {
      dispatch(fetchClientCustomerAction(reqBody));
      const totalRecords = customerDetails?.data?.count || 0;
      const rows = customerDetails?.data?.rows || [];
      setCustomerList(rows);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const fetchData = async ({ pageIndex, pageSize, search }: { pageIndex: number; pageSize: number; search?: any }) => {
    const updatedReqBody = {
      ...reqBody,
      page_no: pageIndex + 1,
      page_record: pageSize,
      search: search,
    };
    setReqBody(updatedReqBody);
    setPageSize(pageSize)
    await InvokeAPI(updatedReqBody);
  };

  const AddClientButton = () => (
    <div className="text-lg-end mt-xl-0 mt-2 d-flex align-items-center">
      <Button
        className="btn btn-primary btn-md mb-2"
        onClick={onOpenModal}
        style={{ backgroundColor: "#13219D" }}
      >
        <span className="">
          <i className="mdi mdi-plus-circle me-1"></i>Add Client
          Customers
        </span>
        {/* Client Customer */}
      </Button>
    </div>
  )

  const filterDate = async (dates: any) => {
    // Store selected dates in ISO format in an array
    const filterValue: string[] = [];
    if (dates[0] && dates[1]) {
      const startDate = moment(dates[0]).startOf('day'); // Start of the selected date
      const endDate = moment(dates[1]).endOf('day'); // End of the selected date

      filterValue.push(startDate.unix().toString(), endDate.unix().toString());
      // filterValue.push(moment(dates[0]).unix().toString(), moment(dates[1]).unix().toString());
    }

    const updatedReqBody = {
      ...reqBody,
      date_range: {
        from_date: filterValue[0],
        to_date: filterValue[1],
      },
    };

    setReqBody(updatedReqBody);

    await InvokeAPI(updatedReqBody); // Ensure this function is defined
  };

  const ResetFilter: React.FC = () => {
    return (
      <div className="">
        <Button
          className="btn btn-danger"
          onClick={async () => {
            setSelectedDates(null)
            reqBody.date_range = {}
            reqBody.filter_by = "0"
            await InvokeAPI(reqBody);
          }}
        // style={{ backgroundColor: "#13219D" }}
        >
          {/* <i className="mdi mdi-plus-circle me-1"></i> */}
          Reset
        </Button>
      </div>
    );
  };

  const [selectedDates, setSelectedDates] = useState<DateRange | null>(null);

  const FilterControls: React.FC = () => {

    const handleDateChange = (dates: DateRange | null) => {
      setSelectedDates(dates);
      filterDate(dates); // Your filtering function
    };

    const handleClean = () => {
      setSelectedDates(null); // Clear dates
      filterDate(null); // Your filtering function for no dates
      filterDate(reqBody);
    };

    return (
      <>
        <div className="d-flex flex-wrap align-items-center">
          <div className="bd-highlight me-2">
            <DateRangePicker
              value={selectedDates}
              onChange={handleDateChange}
              onClean={handleClean}
              format="dd-MM-yyyy" // Adjust format as needed
              style={{ width: 300 }} // Customize width
            // style={{ width: 300, backgroundColor: "#3e436c", color: "white" }}
            />
          </div>
          <div className="">
            <ResetFilter />
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Client", path: "" },
          {
            label: "Client Customers",
            path: "/apps/crm/clientCustomer",
            active: true,
          },
        ]}
        title={"Client Customers"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body style={{ backgroundColor: "lightblue" }}>
              <Row className="justify-content-between">
                <Col className="col-auto">

                </Col>
                <Col className="col-auto">
                  {/* <div className="text-lg-end mt-xl-0 mt-2 d-flex align-items-center">
                    <Button
                      className="btn btn-primary btn-md mb-2 me-2"
                      onClick={onOpenModal}
                      style={{ backgroundColor: "#13219D" }}
                    >
                      <span className="">
                        <i className="mdi mdi-plus-circle me-1"></i>Add Client
                        Customers
                      </span>
                    </Button>
                  </div> */}
                </Col>

                <div className="">
                  {/* {
                    customerList &&
                    <CustomTable
                      columns={columns}
                      data={customerList}
                      pageSize={pageSize}
                      pagination={true}
                      theadClass="table-light"
                      searchBoxClass="mb-2"
                      isSearchable={false}
                      isSortable={true}
                      searchFunction={searchUsers}
                      sortFunction={sortUsers}
                      fetchData={fetchData}
                      sizePerPageList={sizePerPageList}
                      totalRecords={totalRecords}
                    />
                  } */}

                  <CustomTable
                    loading={loading}
                    columns={columns}
                    data={customerList}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pagination={true}
                    theadClass="table-light"
                    searchBoxClass="mb-2"
                    isSearchable={true}
                    isSortable={false}
                    sortFunction={sortUsers}
                    fetchData={fetchData}
                    sizePerPageList={sizePerPageList}
                    totalRecords={totalRecords}
                    isCustomActionButton={true}
                    addButton={<AddClientButton />}
                    isFilterConfigured={true}
                    filterControls={<FilterControls />}
                  />


                  {/* <CustomTable
                    columns={columns}
                    data={customerList}
                    pageSize={10}
                    pagination={true}
                    theadClass="table-light"
                    searchBoxClass="mb-2"
                    // isSearchable={true}
                    isSortable={true}
                    searchFunction={searchUsers}
                    sortFunction={sortUsers}
                  /> */}
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <div className="text-lg-end mt-xl-0 mt-2">
          <Button className="btn btn-primary mb-2 me-2" onClick={onOpenModal}>
            <i className="mdi mdi-basket me-1"></i> Add Client Customer
          </Button>
        </div>
        <Col>
          <Card>
            <Card.Body>
              <CustomTable
                columns={columns}
                data={customerList}
                pageSize={10}
                pagination={true}
                theadClass="table-light"
                searchBoxClass="mb-2"
                isSearchable={true}
                isSortable={true}
                searchFunction={searchUsers}
                sortFunction={sortUsers}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      {show && (
        <AddClientCustomer show={show} userData={user} onHide={onCloseModal} />
      )}

      {showDetails && (
        <ShowCustomer
          customer={customerData}
          show={showDetails}
          onHide={onCloseModal}
        />
      )}
    </React.Fragment>
  );
};

export default ClientCustomers;
