import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// account
function fetchProduct(params: any) {
  const baseUrl = config.API_URL + "/listProducts";
  return api.create(baseUrl, { ...params?.data });
}
// Dhaval

// ? Add product API
function addProduct(data: any) {
  const baseUrl = config.API_URL + "/addProduct";
  return api.create(baseUrl, data);
  // return api.createWithFile(baseUrl, data);
}

// ? fetch product details
function fetchProductDetails(product_id: any) {
  const baseUrl = config.API_URL + `/getProductDetails/${product_id}`;
  return api.get(baseUrl, null);
}

// ? Edit product API
function editProduct(params: any) {
  const baseUrl = config.API_URL + `/editProduct/${params?.data?.product_id}`;
  delete params?.data?.product_id;
  return api.update(baseUrl, { ...params?.data });
}

// ? fetch product details
function deleteProductDetails(product_id: any) {
  const baseUrl = config.API_URL + `/deleteProduct/${product_id}`;
  return api.delete(baseUrl);
}

// Update Order status
function UpdateProductStatusApi(params: any) {
  const baseUrl = config.API_URL + `/admin/changeProductStatus`;
  return api.create(baseUrl, { ...params?.data });
}

function UpdateProductStockApi(params: any) {
  const baseUrl = config.API_URL + `/admin/updateStock`;
  return api.create(baseUrl, { ...params?.data });
}


export {
  fetchProduct,
  addProduct,
  fetchProductDetails,
  editProduct,
  deleteProductDetails,
  UpdateProductStatusApi,
  UpdateProductStockApi
};
