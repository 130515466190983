import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// components
import PageTitle from "../../components/PageTitle";
import { FormInput } from "../../components";
import FileUploader from "../../components/FileUploader";
import UserProfileForm from "./UserProfileForm";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

const MyAccount = () => {
  const [editorState, setEditorState] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();

  const data: any = sessionStorage.getItem("session_user_details");
  useEffect(() => {
    const user_data = JSON.parse(data);
    setUserDetails(user_data);
  }, [data]);

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      firstname: yup.string().required("Please enter First Name"),
      lastname: yup.string().required("Please enter Last Name"),
      email: yup.string().required("Please enter Email"),
      age: yup.string().required("Please enter Age"),
    })
  );

  /*
   * form methods
   */
  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  /**
   * On editor body change
   */
  const onEditorStateChange = (editorStates: any) => {
    setEditorState(editorStates);
  };

  const onSubmit = (data: any) => {
    console.log("profile data ::: ", data);
  };

  useEffect(() => {

  }, [dispatch])
  return (
    <>
      <div className="container-fluid bg-white">

        <PageTitle
          breadCrumbItems={[
            // { label: "setting", path: "/admin/profile" },
            {
              label: "My Profile",
              path: "/user/myProfile",
              active: true,
            },
          ]}
          title={"My Profile"}
        />

        <UserProfileForm />
        {/* <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={6}> 
            <Card>
              <Card.Body> 
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  General
                </h5>
 
                <FormInput
                  name="firstname"
                  label="First Name"
                  placeholder="Enter First Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="firstname"
                  errors={errors}
                  control={control}
                  defaultValue={userDetails?.firstName}
                />
                <FormInput
                  name="lastname"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="lastname"
                  errors={errors}
                  control={control}
                  defaultValue={userDetails?.lastName}
                />
                <FormInput
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  containerClass={"mb-3"}
                  register={register}
                  key="email"
                  errors={errors}
                  control={control}
                  defaultValue={userDetails?.email}
                />
                 

                <Row>
                  <Col>
                    <div className="text-center mb-3">
                      <button
                        type="button"
                        className="btn w-sm btn-light waves-effect me-1"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn w-sm btn-success waves-effect waves-light me-1"
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  User Images
                </h5>
                <FileUploader />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form> */}

      </div>

    </>
  );
};

export default MyAccount;
