import { ProductActionTypes } from "./constants";

export interface ProductAction {
  type:
  | ProductActionTypes.API_RESPONSE_SUCCESS
  | ProductActionTypes.API_RESPONSE_ERROR
  | ProductActionTypes.FETCH_PRODUCT
  | ProductActionTypes.ADD_PRODUCT
  | ProductActionTypes.FETCH_PRODUCT_DETAILS
  | ProductActionTypes.EDIT_PRODUCT
  | ProductActionTypes.DELETE_PRODUCT
  | ProductActionTypes.UPDATE_PRODUCT_STATUS;
  payload: {} | any;
}

interface ProductData {
  categoryDetails: [];
  productData: [];
  loading: false;
  error: null;
}

// common success
export const productApiResponseSuccess = (
  actionType: string,
  data: ProductData | {}
): ProductAction => ({
  type: ProductActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const productApiResponseError = (
  actionType: string,
  error: string
): ProductAction => ({
  type: ProductActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//fetch Category Action
export const fetchProductAction = (reqBody: any) => ({
  type: ProductActionTypes.FETCH_PRODUCT,
  payload: reqBody,
});

//add product action
export const addProductAction = (formData: any) => ({
  type: ProductActionTypes.ADD_PRODUCT,
  payload: formData,
});

//fetch product details
export const fetchProductDetailsAction = (product_id: any) => ({
  type: ProductActionTypes.FETCH_PRODUCT_DETAILS,
  payload: { product_id },
});

//fetch product details
export const EditProductAction = (productData: any) => ({
  type: ProductActionTypes.EDIT_PRODUCT,
  payload: productData,
});

//fetch product details
export const DeleteProductAction = (product_id: any) => ({
  type: ProductActionTypes.DELETE_PRODUCT,
  payload: { product_id },
});

//Update product status
export const UpdateProductStatus = (status: any) => ({
  type: ProductActionTypes.UPDATE_PRODUCT_STATUS,
  payload: status,
});

//Update product stock
export const UpdateProductStock = (data: any) => ({
  type: ProductActionTypes.UPDATE_PRODUCT_STOCK,
  payload: data,
});