import { CustomerActionTypes } from "./constants";

const INIT_STATE = {
  customerDetails: null,
  pendingRequestDetails: null,
  actionRequestDetails: null,
  changeStatusDetails: null,
  loading: false,
  error: null,
  userStatus: null,
};

export interface PermissionActionType {
  type:
    | CustomerActionTypes.API_RESPONSE_SUCCESS
    | CustomerActionTypes.API_RESPONSE_ERROR
    | CustomerActionTypes.FETCH_CUSTOMER_REQUEST
    | CustomerActionTypes.FETCH_PENDING_REQUEST
    | CustomerActionTypes.ACTION_PENDING_REQUEST
    | CustomerActionTypes.CHANGE_STATUS
    | CustomerActionTypes.UPDATE_USER_STATUS
    | CustomerActionTypes.ADD_NEW_CUSTOMER
    | CustomerActionTypes.FETCH_CLIENT_CUSTOMER
    | CustomerActionTypes.UPDATE_CUSTOMER
    | CustomerActionTypes.DELETE_CUSTOMER;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  customerDetails?: any;
  pendingRequestDetails?: any;
  actionRequestDetails?: any;
  changeStatusDetails?: any;
  loading?: boolean;
  value?: boolean;
  userStatus?: any;
}

const CustomerReducer = (
  state: State = INIT_STATE,
  action: PermissionActionType
): any => {
  switch (action.type) {
    case CustomerActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CustomerActionTypes.FETCH_CUSTOMER_REQUEST: {
          return {
            ...state,
            customerDetails: action.payload.data,
            loading: false,
          };
        }
        case CustomerActionTypes.FETCH_CLIENT_CUSTOMER: {
          return {
            ...state,
            customerDetails: action.payload.data,
            loading: false,
          };
        }

        case CustomerActionTypes.FETCH_PENDING_REQUEST: {
          return {
            ...state,
            pendingRequestDetails: action.payload.data,
            loading: false,
          };
        }

        case CustomerActionTypes.ACTION_PENDING_REQUEST: {
          return {
            ...state,
            actionRequestDetails: action.payload.data,
            loading: false,
          };
        }

        case CustomerActionTypes.CHANGE_STATUS: {
          return {
            ...state,
            changeStatusDetails: action.payload.data,
            loading: false,
          };
        }

        case CustomerActionTypes.UPDATE_USER_STATUS: {
          return {
            ...state,
            userStatus: action.payload.data,
            loading: false,
          };
        }

        case CustomerActionTypes.ADD_NEW_CUSTOMER: {
          return {
            ...state,
            userStatus: action.payload.data,
            loading: false,
          };
        }

        case CustomerActionTypes.UPDATE_CUSTOMER: {
          return {
            ...state,
            updateUserData: action.payload.data,
            loading: false,
          };
        }

        case CustomerActionTypes.DELETE_CUSTOMER: {
          return {
            ...state,
            productDetails: action.payload.data,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case CustomerActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CustomerActionTypes.FETCH_CUSTOMER_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case CustomerActionTypes.FETCH_PENDING_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case CustomerActionTypes.ACTION_PENDING_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case CustomerActionTypes.CHANGE_STATUS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case CustomerActionTypes.UPDATE_USER_STATUS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case CustomerActionTypes.ADD_NEW_CUSTOMER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case CustomerActionTypes.UPDATE_CUSTOMER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case CustomerActionTypes.DELETE_CUSTOMER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case CustomerActionTypes.FETCH_CUSTOMER_REQUEST:
      return { ...state, loading: true };

    case CustomerActionTypes.FETCH_CLIENT_CUSTOMER:
      return { ...state, loading: true };

    case CustomerActionTypes.FETCH_PENDING_REQUEST:
      return { ...state, loading: true };

    case CustomerActionTypes.ACTION_PENDING_REQUEST:
      return { ...state, loading: true };

    case CustomerActionTypes.CHANGE_STATUS:
      return { ...state, loading: true };

    case CustomerActionTypes.UPDATE_USER_STATUS:
      return { ...state, loading: true };

    case CustomerActionTypes.ADD_NEW_CUSTOMER:
      return { ...state, loading: true };

    case CustomerActionTypes.UPDATE_CUSTOMER:
      return { ...state, loading: true };

    case CustomerActionTypes.DELETE_CUSTOMER:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};
export default CustomerReducer;
