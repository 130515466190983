export enum ProductActionTypes {
  API_RESPONSE_SUCCESS = "@@product/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@product/API_RESPONSE_ERROR",
  FETCH_PRODUCT = "@@product/FETCH_PRODUCT",
  ADD_PRODUCT = "@@product/ADD_PRODUCT",
  FETCH_PRODUCT_DETAILS = "@@product/FETCH_PRODUCT_DETAILS",
  EDIT_PRODUCT = "@@product/EDIT_PRODUCT",
  DELETE_PRODUCT = "@@product/DELETE_PRODUCT",
  UPDATE_PRODUCT_STATUS = "@@product/UPDATE_PRODUCT_STATUS",
  UPDATE_PRODUCT_STOCK = "@@product/UPDATE_PRODUCT_STOCK",
}
