import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Permission
function fetchPermission(params: any) {
  const baseUrl = config.API_URL + "/admin/listPermission";
  return api.get(baseUrl, { ...params?.data });
}

// Listing subAdmin
function fetchSubAdmin(params: any) {
  const baseUrl = config.API_URL + "/admin/listAdmin";
  return api.create(baseUrl, { ...params?.data });
}

//Adding SubAdmin
function addSubAdmin(params: any) {
  const baseUrl = config.API_URL + "/admin/addAdmin";
  const response = api.create(baseUrl, { ...params?.data });
  return response;
}

//Editing SubAdmin
function editSubAdmin(params: any) {
  const baseUrl = config.API_URL + `/admin/editAdmin`;
  return api.update(baseUrl, { ...params?.data });
}

//Deleting SubAdmin
function deleteSubAdmin(params: any) {
  let temp = {
    user_id: params.data,
  };
  const baseUrl = config.API_URL + `/admin/deleteAdmin`;
  return api.deleteResponse(baseUrl, temp);
}

//Update SubAdmin Status
function updateSubAdminStatus(params: any) {
  const baseUrl = config.API_URL + `/admin/changeAdminStatus`;
  return api.create(baseUrl, { ...params?.data });
}

//Update SubAdmin Status
function updatePermission(params: any) {
  const baseUrl = config.API_URL + `/admin/updateAdminPermissions`;
  return api.create(baseUrl, { ...params?.data });
}

export {
  fetchPermission,
  fetchSubAdmin,
  addSubAdmin,
  editSubAdmin,
  deleteSubAdmin,
  updateSubAdminStatus,
  updatePermission,
};
