import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button, Card, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import Swal from "sweetalert2";
import {
  ChangeCustomerStatus,
  fetchCustomerAction,
} from "../../../../redux/actions";

interface FormValues {
  status: any;
}

interface ShowCustomerProps {
  show: boolean;
  customer: any;
  onHide: () => void;
}

const statusVal = [
  // {
  //     status: 0,
  //     name: 'Not Active',
  // },
  {
    status: 1,
    name: "Active",
  },
  {
    status: 2,
    name: "Blocked",
  },
];

const ShowCustomer = ({ show, customer, onHide }: ShowCustomerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const initialValues: FormValues = {
    status: customer?.status || "",
  };

  const handleSubmit = (
    values: FormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    // Handle submission here, e.g., send data to backend
    if (Object.keys(customer).length > 0) {
      //Update Call
      const formData = {
        user_id: customer?.user_id,
        status: parseInt(values?.status),
      };
      dispatch(ChangeCustomerStatus(formData));
    }
    // resetForm();
  };

  const handleRemove = (data: any) => {
    const id: any = data;
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Dispatch the delete action
          const formData = {
            user_id: customer?.user_id,
            status: 3,
          };
          dispatch(ChangeCustomerStatus(formData));

          // Show success message after dispatching action
          Swal.fire("Deleted!", "Your item has been deleted.", "success").then(
            () => {
              // Dispatch fetch_data action after success message is shown
              // dispatch(fetchCustomerAction());
            }
          );
        } else {
          Swal.fire("Cancelled", "Your action has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };
  const imageUrl = "https://i.pravatar.cc/300";
  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light" onHide={onHide} closeButton>
        <Modal.Title className="m-0">Customer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Card className="text-center">
          <Card.Body>
            <Row>
              <Col md={4}>
                <img
                  src={
                    customer?.profile_image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNUKbV4ZCrVOwTMkfnN10Mfhwp7BSiVb64BzDuE_lA9w&s"
                  }
                  className="rounded-circle avatar-xxl img-thumbnail"
                  alt=""
                  style={{ width: 100, height: 100 }}
                />
                <h4 className="mb-0 text-capitalize">{customer?.name}</h4>
                <p className="text-muted">{customer?.email}</p>
              </Col>
              <Col md={8}>
                <div className="text-start">
                  <div className="border-bottom">
                    <h4>Account Information</h4>
                  </div>

                  <div className="text-start mt-1">
                    <p className="text-muted mb-2 font-13">
                      <strong>Full Name :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.name}
                      </span>
                    </p>

                    <p className="text-muted mb-2 font-13">
                      <strong>Email :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.email}
                      </span>
                    </p>

                    <p className="text-muted mb-2 font-13">
                      <strong>Mobile :</strong>
                      <span className="ms-2">
                        {customer?.wm_user_profile?.phone_number}
                      </span>
                    </p>

                    <p className="text-muted mb-1 font-13">
                      <strong>Location :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.wm_user_profile?.country}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="text-start mt-3">
                  <div className="border-bottom">
                    <h4>Comapny Information</h4>
                  </div>

                  <div className="text-start mt-3">
                    <p className="text-muted mb-2 font-13">
                      <strong>Contact Name :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.name}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>Company Name :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.wm_user_profile?.company_name}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>LandLine Number :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.wm_user_profile?.landline_number}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>GST Number :</strong>
                      <span className="ms-2">
                        {customer?.wm_user_profile?.gst_number}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>Address :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.wm_user_profile?.address_main_line}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>City :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.wm_user_profile?.city}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>State :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.wm_user_profile?.state}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>Country :</strong>
                      <span className="ms-2 text-capitalize">
                        {customer?.wm_user_profile?.country}
                      </span>
                    </p>
                    <p className="text-muted mb-2 font-13">
                      <strong>Post Code :</strong>
                      <span className="ms-2">
                        {customer?.wm_user_profile?.postcode}
                      </span>
                    </p>
                    
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="mb-3">
              <label htmlFor="user_id">Name</label>
              <div>{customer?.name}</div>
            </div>

            <div className="mb-3">
              <label htmlFor="user_id">E-Mail</label>
              <div>{customer?.email}</div>
            </div>

            <div className="row">
              <div className="col-md-8 mb-3">
                <label htmlFor="user_id">Status</label>
                <Field
                  as="select"
                  className="form-select"
                  id="user_id"
                  name="status"
                >
                  <option value="" disabled>
                    Select an status
                  </option>

                  {statusVal.length > 0 &&
                    statusVal.map((item: any) => (
                      <option key={item.status} value={item.status}>
                        {item.name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage name="status" component="div" />
              </div>
              <div className="col-md-4 mt-3">
                <Button
                  className="btn btn-danger"
                  onClick={() => handleRemove(customer.user_id)}
                >
                  Remove User Permanently
                </Button>
              </div>
            </div>

            <div className="text-end mt-3">
              <button className="btn btn-success me-2" type="submit">
                Update
              </button>
              <Button onClick={onHide}>Close</Button>
            </div>
          </Form>
        </Formik> */}
      </Modal.Body>
    </Modal>
  );
};
export default ShowCustomer;
