export enum CustomerActionTypes {
  API_RESPONSE_SUCCESS = "@@customers/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@customers/API_RESPONSE_ERROR",
  FETCH_CUSTOMER_REQUEST = "@@customers/FETCH_CUSTOMER_REQUEST",
  FETCH_PENDING_REQUEST = "@@customers/FETCH_PENDING_REQUEST",
  ACTION_PENDING_REQUEST = "@@customers/ACTION_PENDING_REQUEST",
  CHANGE_STATUS = "@@customers/CHANGE_STATUS",
  UPDATE_USER_STATUS = "@@customers/UPDATE_USER_STATUS",
  ADD_NEW_CUSTOMER = "@@customers/ADD_NEW_CUSTOMER",
  FETCH_CLIENT_CUSTOMER = "@@customers/FETCH_CLIENT_CUSTOMER",
  UPDATE_CUSTOMER = "@@customers/UPDATE_CUSTOMER",
  DELETE_CUSTOMER = "@@customers/DELETE_CUSTOMER",
}
