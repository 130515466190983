import { AdminProfileActionTypes } from "./constants";

export interface AdminProfileActionType {
  type:
  | AdminProfileActionTypes.API_RESPONSE_SUCCESS
  | AdminProfileActionTypes.API_RESPONSE_ERROR
  // | AdminProfileActionTypes.FETCH_PROFILE_REQUEST
  | AdminProfileActionTypes.EDIT_PROFILE_REQUEST
  | AdminProfileActionTypes.ADMIN_PROFILE_GET
  | AdminProfileActionTypes.UPDATE_AVATAR;
  payload: {} | string;
}

// common success
export const adminProfileApiResponseSuccess = (
  actionType: string,
  data: any | {}
): AdminProfileActionType => ({
  type: AdminProfileActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const adminProfileApiResponseError = (
  actionType: string,
  error: string
): AdminProfileActionType => ({
  type: AdminProfileActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Action request Customers List
export const editProfileAction = (editProfileData: any) => ({
  type: AdminProfileActionTypes.EDIT_PROFILE_REQUEST,
  payload: editProfileData,
});

//Fetch request Admin Profile List
export const AdminProfileGet = () => ({
  type: AdminProfileActionTypes.ADMIN_PROFILE_GET,
});

//Fetch request Admin Profile image
export const UpdateAvatar = (formData: any) => ({
  type: AdminProfileActionTypes.UPDATE_AVATAR,
  payload: formData
});