import { AdminProfileActionTypes } from "./constants";

const INIT_STATE = {
    adminProfileDetails: null,
    editProfileDetails: null,
    loading: false,
    error: null,
    AdminData: null,
}

export interface AdminProfileActionType {
    type:
    | AdminProfileActionTypes.API_RESPONSE_SUCCESS
    | AdminProfileActionTypes.API_RESPONSE_ERROR
    | AdminProfileActionTypes.EDIT_PROFILE_REQUEST
    | AdminProfileActionTypes.ADMIN_PROFILE_GET
    | AdminProfileActionTypes.UPDATE_AVATAR
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    adminProfileDetails?: any,
    editProfileDetails?: any,
    loading?: boolean,
    value?: boolean,
    AdminData?: any,
}

const AdminProfileReducer = (state: State = INIT_STATE, action: AdminProfileActionType): any => {
    switch (action.type) {
        case AdminProfileActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AdminProfileActionTypes.EDIT_PROFILE_REQUEST: {
                    return {
                        ...state,
                        editProfileDetails: action.payload.data,
                        loading: false,
                    };
                }

                case AdminProfileActionTypes.ADMIN_PROFILE_GET: {
                    return {
                        ...state,
                        AdminData: action.payload.data,
                        loading: false,
                    };
                }

                case AdminProfileActionTypes.UPDATE_AVATAR: {
                    return {
                        ...state,
                        avatarData: action.payload.data,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case AdminProfileActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AdminProfileActionTypes.EDIT_PROFILE_REQUEST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }

                case AdminProfileActionTypes.ADMIN_PROFILE_GET: {
                    return {
                        ...state,
                        loading: false,
                    };
                }

                case AdminProfileActionTypes.UPDATE_AVATAR: {
                    return {
                        ...state,
                        loading: false,
                    };
                }

                default:
                    return { ...state };
            }

        case AdminProfileActionTypes.EDIT_PROFILE_REQUEST:
            return { ...state, loading: true };

        case AdminProfileActionTypes.ADMIN_PROFILE_GET:
            return { ...state, loading: true };

        case AdminProfileActionTypes.UPDATE_AVATAR:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
}
export default AdminProfileReducer;