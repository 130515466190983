import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  userOrderApiResponseError,
  userOrderApiResponseSuccess,
} from "./actions";
import { UserOrderActionTypes } from "./constants";
import notify from "../../../components/Notification/notify";
import { FetchUserOrderList } from "../../../helpers/api/UserApi/UserOrders";

// Fetch User Profile ......

function* GetUserOrderList({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(FetchUserOrderList, { data });
    const _payload: any = response.data;
    yield put(userOrderApiResponseSuccess(UserOrderActionTypes.GET_USER_ORDER_LIST, _payload));
  } catch (error: any) {
    yield put(userOrderApiResponseError(UserOrderActionTypes.GET_USER_ORDER_LIST, error));
  }
}

export function* watchUserProductList() {
  yield takeEvery(UserOrderActionTypes.GET_USER_ORDER_LIST, GetUserOrderList);
}

function* UserOrderSaga() {
  yield all([fork(watchUserProductList)]);
}

export default UserOrderSaga;
