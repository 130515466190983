import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  customerApiResponseError,
  customerApiResponseSuccess,
} from "./actions";
import { CustomerActionTypes } from "./constants";
import {
  UpdateUserStatusApi,
  actionPendingRequest,
  addNewCustomerAPI,
  changeCustomerStatus,
  deleteCustomer,
  fetchClientCustomer,
  fetchCustomer,
  fetchPending,
  updateCustomerAPI,
} from "../../helpers/api/customerApi";
import notify from "../../components/Notification/notify";
import Swal from "sweetalert2";

// Fetch Permission ......
function* fetchCustomerSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      filter_by: payload.filter_by ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
      date_range: payload.date_range ?? "",
    };
    const response = yield call(fetchCustomer, { data });
    const _res: any = response.data;
    yield put(
      customerApiResponseSuccess(
        CustomerActionTypes.FETCH_CUSTOMER_REQUEST,
        _res
      )
    );
  } catch (error: any) {
    yield put(
      customerApiResponseError(
        CustomerActionTypes.FETCH_CUSTOMER_REQUEST,
        error
      )
    );
  }
}

export function* watchFetchCustomer() {
  yield takeEvery(
    CustomerActionTypes.FETCH_CUSTOMER_REQUEST,
    fetchCustomerSaga
  );
}

// Fetch PendingRequest ......
function* fetchPendingRequestSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
      date_range: payload.date_range ?? "",
    };
    const response = yield call(fetchPending, { data });
    const _payload: any = response.data;
    yield put(
      customerApiResponseSuccess(
        CustomerActionTypes.FETCH_PENDING_REQUEST,
        _payload
      )
    );
  } catch (error: any) {
    yield put(
      customerApiResponseError(CustomerActionTypes.FETCH_PENDING_REQUEST, error)
    );
  }
}

export function* watchFetchPendingRequest() {
  yield takeEvery(
    CustomerActionTypes.FETCH_PENDING_REQUEST,
    fetchPendingRequestSaga
  );
}

// Actions Request ......
function* fetchActionsRequestSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(actionPendingRequest, { data });
    const payload: any = response.data;
    yield put(
      customerApiResponseSuccess(
        CustomerActionTypes.ACTION_PENDING_REQUEST,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      customerApiResponseError(
        CustomerActionTypes.ACTION_PENDING_REQUEST,
        error
      )
    );
  }
}

export function* watchActionRequestSaga() {
  yield takeEvery(
    CustomerActionTypes.ACTION_PENDING_REQUEST,
    fetchActionsRequestSaga
  );
}

// change customer status
function* changeCustomerStatusSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(changeCustomerStatus, { data });
    const payload: any = response.data;
    yield put(
      customerApiResponseSuccess(CustomerActionTypes.CHANGE_STATUS, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      customerApiResponseError(CustomerActionTypes.CHANGE_STATUS, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchCustomerStatus() {
  yield takeEvery(CustomerActionTypes.CHANGE_STATUS, changeCustomerStatusSaga);
}

//Update Order status Saga
function* updateUserStatus({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(UpdateUserStatusApi, { data });
    const payload: any = response.data;
    yield put(
      customerApiResponseSuccess(
        CustomerActionTypes.UPDATE_USER_STATUS,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      customerApiResponseError(CustomerActionTypes.UPDATE_USER_STATUS, error)
    );
  }
}

export function* watchUpdateUserStatus() {
  yield takeEvery(CustomerActionTypes.UPDATE_USER_STATUS, updateUserStatus);
}

// * Add new customer
function* addNewCustomerSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(addNewCustomerAPI, { data });
    const payload: any = response.data;
    yield put(
      customerApiResponseSuccess(CustomerActionTypes.ADD_NEW_CUSTOMER, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      customerApiResponseError(CustomerActionTypes.ADD_NEW_CUSTOMER, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddNewCustomerSagaSaga() {
  yield takeEvery(CustomerActionTypes.ADD_NEW_CUSTOMER, addNewCustomerSaga);
}

// Fetch client customer ......
function* fetchClientCustomerSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      // filter_by: payload.filter_by ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
      date_range: payload.date_range ?? "",
    };
    const response = yield call(fetchClientCustomer, { data });
    const _res: any = response.data;
    yield put(
      customerApiResponseSuccess(
        CustomerActionTypes.FETCH_CLIENT_CUSTOMER,
        _res
      )
    );
  } catch (error: any) {
    yield put(
      customerApiResponseError(CustomerActionTypes.FETCH_CLIENT_CUSTOMER, error)
    );
  }
}

export function* watchFetchClientCustomer() {
  yield takeEvery(
    CustomerActionTypes.FETCH_CLIENT_CUSTOMER,
    fetchClientCustomerSaga
  );
}

// ? update customer
function* updateCustomerSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(updateCustomerAPI, { data });
    const payload: any = response.data;
    yield put(
      customerApiResponseSuccess(CustomerActionTypes.UPDATE_CUSTOMER, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      customerApiResponseError(CustomerActionTypes.UPDATE_CUSTOMER, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchUpdateCustomerSagaSaga() {
  yield takeEvery(CustomerActionTypes.UPDATE_CUSTOMER, updateCustomerSaga);
}

// ? Customer delete saga
function* deleteCustomerDetailsSaga(action: any): SagaIterator {
  try {
    let { user_id } = action.payload;
    const response = yield call(deleteCustomer, user_id);
    const productData: any = response.data;
    yield put(
      customerApiResponseSuccess(
        CustomerActionTypes.DELETE_CUSTOMER,
        productData
      )
    );
  } catch (error: any) {
    Swal.fire("Error", "Something went wrong!", "error");
    yield put(
      customerApiResponseError(CustomerActionTypes.DELETE_CUSTOMER, error)
    );
  }
}

export function* watchDeleteCustomerDetailsSaga() {
  yield takeEvery(
    CustomerActionTypes.DELETE_CUSTOMER,
    deleteCustomerDetailsSaga
  );
}

function* pendingRequestSaga() {
  yield all([
    fork(watchFetchCustomer),
    fork(watchFetchPendingRequest),
    fork(watchActionRequestSaga),
    fork(watchCustomerStatus),
    fork(watchUpdateUserStatus),
    fork(watchAddNewCustomerSagaSaga),
    fork(watchFetchClientCustomer),
    fork(watchUpdateCustomerSagaSaga),
    fork(watchDeleteCustomerDetailsSaga),
  ]);
}

export default pendingRequestSaga;
