import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AddPermissionsAction } from "../../../redux/actions";

interface FormValues {
  name: string;
}

interface AddSubAdminsProps {
  show: boolean;
  onHide: () => void;
}

const AddPermissionForm = ({ show, onHide }: AddSubAdminsProps) => {
  const dispatch = useDispatch();

  // Define Yup validation schema
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    // name: Yup.string().required("Name name is required").matches(/^[a-zA-Z]+$/, "Name must contain only letters").test('no-spaces', 'Spaces are not allowed', (value: any) => /^[A-Z][a-z]*(\s[A-Z][a-z]*)*$/.test(value)),

    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z]+$/, "Name must contain only letters")
      // .test('no-spaces', 'Spaces are not allowed', (value: any) => !/\s/.test(value))
  });

  // Handle form submission
  const handleSubmit = async (values: FormValues, { resetForm }: any) => {
    try {
      const resData = {
        name: values.name.toLowerCase(),
        slug: values.name.toLowerCase()
      };
      // Dispatch action to add permission
      dispatch(AddPermissionsAction(resData));

      // Reset form after successful submission
      resetForm();
    } catch (error) {
      console.error("Failed to add permission:", error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light" onHide={onHide} closeButton>
        <Modal.Title className="m-0">Add Permission </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={{ name: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched,  dirty, isValid  }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Permission name
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter permission"
                  className={`form-control ${
                    errors.name && touched.name ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="text-end">
                <button type="submit" className="btn btn-success" disabled={!dirty || !isValid}>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddPermissionForm;
