import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  userCartApiResponseError,
  userCartApiResponseSuccess,
} from "./actions";
import { UserCartActionTypes } from "./constants";
import notify from "../../../components/Notification/notify";
import {
  DeleteProductFromCartApi,
  FetchUserShoppingList,
  OrderAddToCartApi,
  PlaceOrderApi,
} from "../../../helpers/api/UserApi/UserCarts";
import Swal from "sweetalert2";

// Fetch User Shopping List ......

function* GetUserShoppingList(): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: "",
      sort_field: "created_datetime",
      sort_order: "desc",
    };
    const response = yield call(FetchUserShoppingList, { data });
    const payload: any = response.data;
    yield put(
      userCartApiResponseSuccess(
        UserCartActionTypes.GET_USER_SHOPPING_LIST,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      userCartApiResponseError(
        UserCartActionTypes.GET_USER_SHOPPING_LIST,
        error
      )
    );
  }
}

export function* watchUserShoppingList() {
  yield takeEvery(
    UserCartActionTypes.GET_USER_SHOPPING_LIST,
    GetUserShoppingList
  );
}

//Delete Product From Cart User side...

function* DeleteProductFromCartSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(DeleteProductFromCartApi, { data });
    const payload: any = response.data;
    // Swal.fire(
    //   "Deleted!",
    //   "Your Product has been removed SuccessFully.",
    //   "success"
    // );
    yield put(
      userCartApiResponseSuccess(
        UserCartActionTypes.GET_REMOVE_PRODUCT,
        payload
      )
    );
  } catch (error: any) {
    Swal.fire("Error", "Something went wrong!", "error");
    yield put(
      userCartApiResponseError(UserCartActionTypes.GET_REMOVE_PRODUCT, error)
    );
  }
}

export function* watchDeleteProductFromCart() {
  yield takeEvery(
    UserCartActionTypes.GET_REMOVE_PRODUCT,
    DeleteProductFromCartSaga
  );
}

// Place Order from Cart
function* PlaceOrderSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(PlaceOrderApi, { data });
    const payload: any = response.data;
    yield put(
      userCartApiResponseSuccess(UserCartActionTypes.PLACE_ORDER, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(userCartApiResponseError(UserCartActionTypes.PLACE_ORDER, error));
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchPlaceOrder() {
  yield takeEvery(UserCartActionTypes.PLACE_ORDER, PlaceOrderSaga);
}


// Add to Cart Order
function* OrderAddToCartSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(OrderAddToCartApi, { data });
    const payload: any = response.data;
    yield put(
      userCartApiResponseSuccess(UserCartActionTypes.ORDER_ADD_TO_CART, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      userCartApiResponseError(UserCartActionTypes.ORDER_ADD_TO_CART, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchOrderAddToCart() {
  yield takeEvery(UserCartActionTypes.ORDER_ADD_TO_CART, OrderAddToCartSaga);
}

function* UserCartSaga() {
  yield all([
    fork(watchUserShoppingList),
    fork(watchDeleteProductFromCart),
    fork(watchPlaceOrder),
    fork(watchOrderAddToCart),
  ]);
}

export default UserCartSaga;
