import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// dummy data
import { orders, OrdersItemTypes } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchNewOrdersRequestAction,
  orderAction,
} from "../../../redux/actions";
import Swal from "sweetalert2";
import CustomTable from "../../../components/CustomTable";

/* order column render */
const OrderColumn = ({ row }: { row: any }) => {
  return (
    <>
      <Link to={`/admin/orderRequest/details/${row.original.user_orders_id}`} className="fw-bold text-dark">
        #{row.original.user_order_number}
      </Link>
    </>
  );
};

/* product column render */
const ProductsColumn = ({ row }: { row: any }) => {
  const displayedItems = row.original.wm_user_order_items && row.original.wm_user_order_items.slice(0, 3);
  const hasMoreItems = row.original.wm_user_order_items.length && row.original.wm_user_order_items.length > 3;
  const countImage = row.original.wm_user_order_items.length ? row.original.wm_user_order_items.length - 3 : 0
  return (
    <>
      <div className="d-flex">
        {(displayedItems || []).map((item: any, index: any) => {
          return (
            <Link to="#" key={index}>
              <img
                src={
                  item?.wm_product?.wm_product_photos &&
                    item?.wm_product?.wm_product_photos.length > 0
                    ? item?.wm_product?.wm_product_photos[0]?.photo_url
                    : "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-5.png"
                }
                alt={item.name}
                className="rounded me-2"
                height="40"
              // width="40"
              />
            </Link>
          );
        })}
        {hasMoreItems && (
          <div className="d-flex fs-4 bg-white justify-content-center align-items-center rounded" style={{ width: '40px', height: '48px' }}>+{countImage}</div>
        )}
      </div>
    </>
  );
};

/* orderdate column render */
function formatUnixTimestamp(unixTimestamp: any) {
  // Convert Unix timestamp to milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Define month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month, day, year, hours, and minutes
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Add leading zero to minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Construct the formatted string
  const formattedDate = `${month} ${day} ${year} ${hours}:${formattedMinutes} ${period}`;

  return formattedDate;
}

const OrderDateColumn = ({ row }: { row: any }) => {
  const order_date = formatUnixTimestamp(row.original?.user_order_date);
  return (
    <>
      <span className="text-dark"> {order_date}</span>
    </>
  );
};

/* payment column render */
const PaymentStatusColumn = ({ row }: { row: any }) => {
  return (
    <>
      <h5>
        <span
          className={classNames("badge", {
            "bg-soft-success text-success": row.original.payment_status === 1,
            "bg-soft-info text-info": row.original.payment_status === 0,
          })}
        >
          {row.original.payment_status === 1 && (
            <i className="mdi mdi-bitcoin me-1"></i>
          )}
          {row.original.payment_status === 0 && (
            <i className="mdi mdi-cash me-1"></i>
          )}
          {row.original.payment_status === 1 ? "Paid" : "Due"}
        </span>
      </h5>
    </>
  );
};

/* status column render */
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <>
      <h5>
        <span
          className={classNames("badge", {
            "bg-success": row.original.order_status === 4,
            "bg-danger": row.original.order_status === 2,
            "bg-info": row.original.order_status === 0,
            "bg-warning": row.original.order_status === 1,
            "bg-secondary": row.original.order_status === 3,
          })}
        >
          {/* {row.original.order_status} */}
          {row.original.status === 0
            ? "Request"
            : row.original.status === 1
              ? "Processing/ Accept"
              : row.original.status === 2
                ? "Rejected"
                : row.original.status === 3
                  ? "Dispatch"
                  : row.original.status === 4
                    ? "Delivered"
                    : "Request"}
        </span>
      </h5>
    </>
  );
};

/* action column render */
const ActionColumn = () => {
  return (
    <>
      {/* <Link to="#" className="action-icon">
        {" "}
        <i className="mdi mdi-eye"></i>
        
      </Link> */}
      <Link to="#" className="action-icon">
        <Button className="btn btn-success mb-2 me-1">
          <i className="mdi mdi-check"></i>
        </Button>
      </Link>
      <Link to="#" className="action-icon">
        <Button className="btn btn-danger mb-2 me-1">
          <i className="mdi mdi-delete"></i>
        </Button>
      </Link>
    </>
  );
};

// main component
const OrderRequests = () => {
  const [orderList, setOrderList] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const [reqBody, setReqBody] = useState<any>({
    page_record: pageSize && 10,
    page_no: pageIndex && 1,
    search: "",
    filter_by: "",
    sort_field: "created_datetime",
    sort_order: "desc",
  });

  const { newOrderRequestDetails, loading, error } = useSelector(
    (state: RootState) => ({
      newOrderRequestDetails: state.OrdersReducer.newOrderRequestDetails,
      loading: state.OrdersReducer.loading,
      error: state.OrdersReducer.error,
    })
  );

  // change order status group
  // const changeOrderStatusGroup = (OrderStatusGroup: string) => {
  //   let updatedData = [...orders];
  //   //  filter
  //   updatedData =
  //     OrderStatusGroup === "All"
  //       ? orders
  //       : [...orders].filter((o) =>
  //           o.payment_status?.includes(OrderStatusGroup)
  //         );
  //   setOrderList(updatedData);
  // };

  // Accept Order Functions....

  const onAcceptOrder = (data: any) => {
    const resData = {
      user_orders_id: data.user_orders_id,
      action: 1,
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(orderAction(resData));

          Swal.fire(
            "Order Accepted!",
            "Your Order has been taken.",
            "success"
          ).then(() => {
            dispatch(fetchNewOrdersRequestAction({}));
          });
        } else {
          Swal.fire("Cancelled", "Your Order has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  // Rejected Order Functions....

  const onRejectOrder = (data: any) => {
    const resData = {
      user_orders_id: data.user_orders_id,
      action: 2,
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(orderAction(resData));

          Swal.fire(
            "Order Rejected!",
            "Your Order has been rejected.",
            "success"
          ).then(() => {
            dispatch(fetchNewOrdersRequestAction({}));
          });
        } else {
          Swal.fire("Cancelled", "Your Order has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  // get all columns
  const columns = [
    {
      Header: "Order ID",
      accessor: "user_order_number",
      Cell: OrderColumn,
    },
    {
      Header: "Customer ID",
      accessor: "customer_id",
      Cell: ({ row }: any) => (
        <>
          <span className="text-capitalize">
            {row?.original?.wm_user?.user_code
              ? row?.original?.wm_user?.user_code
              : "-"}
          </span>
        </>
      ),
    },
    {
      Header: "Name",
      accessor: "wm_user",
      sort: false,
      Cell: ({ row }: any) => (
        <>
          <span className="text-capitalize">
            {row?.original?.wm_user?.name
              ? row?.original?.wm_user?.name
              : "-"}
          </span>
        </>
      ),
      className: "table-user text-capitalize",
    },
    {
      Header: "Products",
      accessor: "wm_user_order_items",
      Cell: ProductsColumn,
    },
    {
      Header: "Date",
      accessor: "order_date",
      Cell: OrderDateColumn,
    },
    // {
    //   Header: "Payment Status",
    //   accessor: "payment_status",
    //   sort: true,
    //   Cell: PaymentStatusColumn
    // },
    {
      Header: "Total",
      accessor: "total_amount",
      Cell: ({ row }: any) => (
        <>
          ₹{" "}
          {row.original.total_amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </>
      ),
      sort: false,
    },
    {
      Header: "Order Status",
      accessor: "order_status",
      sort: false,
      Cell: StatusColumn,
    },
    {
      Header: "Action",
      accessor: "action",
      // Cell: ActionColumn
      Cell: ({ row }: any) => (
        <>
          <Button
            className="btn btn-success mb-2 me-1"
            onClick={() => onAcceptOrder(row.original)}
          >
            <i className="mdi mdi-check"></i>
          </Button>
          <Button
            className="btn btn-danger mb-2 me-1"
            onClick={() => onRejectOrder(row.original)}
          >
            <i className="mdi mdi-delete"></i>
          </Button>
        </>
      ),
    },
  ];

  // get page list to display
  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    // {
    //   text: "All",
    //   value: orderList.length,
    // },
  ];

  useEffect(() => {
    if (
      newOrderRequestDetails?.statusCode === 200 &&
      newOrderRequestDetails?.data?.rows !== undefined &&
      newOrderRequestDetails?.data !== null
    ) {
      setOrderList(newOrderRequestDetails?.data?.rows);
      setTotalRecords(newOrderRequestDetails?.data?.count);
    }
  }, [newOrderRequestDetails]);

  // useEffect(() => {
  //   dispatch(fetchNewOrdersRequestAction({}));
  // }, [dispatch]);

  // Function to handle search using API call
  const searchOrderRequest = async (value: string) => {
    const updatedReqBody = {
      ...reqBody,
      search: value,
    };
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const sortOrderRequest = async (value: any) => {
    let updatedReqBody = {
      ...reqBody,
      sort_field: value.column ?? "created_datetime",
      sort_order: value.direction ?? "desc",
    };
    if (!value.direction) {
      updatedReqBody = {
        ...reqBody,
        sort_field: "created_datetime",
        sort_order: "desc",
      };
    }
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const InvokeAPI = async (reqBody: any) => {
    try {
      dispatch(fetchNewOrdersRequestAction(reqBody));
      const totalRecords = newOrderRequestDetails?.data?.count || 0;
      const rows = newOrderRequestDetails?.data?.rows || [];
      setOrderList(rows);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const fetchData = async ({ pageIndex, pageSize, search }: { pageIndex: number; pageSize: number; search?: any }) => {
    const updatedReqBody = {
      ...reqBody,
      page_no: pageIndex + 1,
      page_record: pageSize,
      search: search,
    };
    setReqBody(updatedReqBody);
    setPageSize(pageSize)
    await InvokeAPI(updatedReqBody);
  };
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Orders", path: "" },
          {
            label: "New Orders Requests",
            path: "/apps/ecommerce/OrderRequests",
            active: true,
          },
        ]}
        title={"New Orders Requests"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body style={{ backgroundColor: "lightblue" }}>
              <Row className="justify-content-between">
                <Col className="col-auto">

                </Col>
                <Col className="col-auto"></Col>

                <div className="">

                  <CustomTable
                    loading={loading}
                    columns={columns}
                    data={orderList}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pagination={true}
                    theadClass="table-light"
                    searchBoxClass="mb-2"
                    isSearchable={true}
                    isSortable={false}
                    sortFunction={sortOrderRequest}
                    fetchData={fetchData}
                    sizePerPageList={sizePerPageList}
                    totalRecords={totalRecords}
                    isCustomActionButton={false}
                  // addButton={<AddTaskButton />}
                  />

                  {/* {
                    orderList &&
                    <CustomTable
                      columns={columns}
                      data={orderList}
                      pageSize={pageSize}
                      pagination={true}
                      theadClass="table-light"
                      searchBoxClass="mb-2"
                      isSearchable={false}
                      isSortable={true}
                      searchFunction={searchOrderRequest}
                      sortFunction={sortOrderRequest}
                      fetchData={fetchData}
                      sizePerPageList={sizePerPageList}
                      totalRecords={totalRecords}
                    />
                  } */}

                  {/* <CustomTable
                    columns={columns}
                    data={orderList}
                    pageSize={10}
                    pagination={true}
                    theadClass="table-light"
                    searchBoxClass="mb-2"
                    // isSearchable={true}
                    isSortable={true}
                    // Remove isSearchable, isSortable, and onSortChange props
                    // Remove onSearchChange prop and handle searching using search function
                    searchFunction={searchOrderRequest}
                    sortFunction={sortOrderRequest}
                  /> */}
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderRequests;
