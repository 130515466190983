import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

// layout constants
import { LayoutTypes } from "../constants/layout";

// strore
import { RootState } from "../redux/store";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import TwoColumnLayout from "../layouts/TwoColumn";

import {
  authProtectedFlattenRoutes,
  authProtectedUserFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import { APICore } from "../helpers/api/apiCore";

interface IRoutesProps { }

const AllRoutes = (props: IRoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const { auth } = useSelector((state: RootState) => ({
    auth: state.Auth,
  }));

  // console.log(auth, "auth........");
  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();
  const api = new APICore();
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {[1, 2].includes(auth?.user?.user_role)
            ? authProtectedFlattenRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  api.isUserAuthenticated() === false ? (
                    <Navigate
                      to={{
                        pathname: "/auth/login",
                        // hash:route.path,
                        // search: "next=" + route.path,
                      }}
                    />
                  ) : (
                    <Layout {...props}>{route.element}</Layout>
                  )
                }
                key={idx}
              />
            ))
            : authProtectedUserFlattenRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  api.isUserAuthenticated() === false ? (
                    <Navigate
                      to={{
                        pathname: "/auth/login",
                        // hash:route.path,
                        // search: "next=" + route.path,
                      }}
                    />
                  ) : (
                    <Layout {...props}>{route.element}</Layout>
                  )
                }
                key={idx}
              />
            ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
