import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  permissionsApiResponseError,
  permissionsApiResponseSuccess,
} from "./actions";
import { PermissionActionTypes } from "./constants";
import {
  addPermission,
  deletePermission,
  fetchPermission,
} from "../../helpers/api/permissionApi";
import notify from "../../components/Notification/notify";
import Swal from "sweetalert2";

// Fetch Permission ......

function* fetchPermissionSaga(): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: "",
      sort_field: "created_datetime",
      sort_order: "desc",
    };
    const response = yield call(fetchPermission, { data });
    const payload: any = response.data;
    yield put(
      permissionsApiResponseSuccess(
        PermissionActionTypes.FETCH_PERMISSION_REQUEST,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      permissionsApiResponseError(
        PermissionActionTypes.FETCH_PERMISSION_REQUEST,
        error
      )
    );
  }
}

export function* watchFetchPermission() {
  yield takeEvery(
    PermissionActionTypes.FETCH_PERMISSION_REQUEST,
    fetchPermissionSaga
  );
}

//Add SubAdmin User .....

function* AddPermissionSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(addPermission, { data });
    const payload: any = response.data;
    yield put(
      permissionsApiResponseSuccess(
        PermissionActionTypes.ADD_PERMISSION_REQUEST,
        payload
      )
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      permissionsApiResponseError(
        PermissionActionTypes.ADD_PERMISSION_REQUEST,
        error
      )
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddPermission() {
  yield takeEvery(
    PermissionActionTypes.ADD_PERMISSION_REQUEST,
    AddPermissionSaga
  );
}

//Delete Permission User...

function* DeletePermissionSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(deletePermission, { data });
    const payload: any = response.data.data;
    yield put(
      permissionsApiResponseSuccess(
        PermissionActionTypes.DELETE_PERMISSION_REQUEST,
        payload
      )
    );
    Swal.fire("Success", "Delete Permission Successfully!", "success");
  } catch (error: any) {
    yield put(
      permissionsApiResponseError(
        PermissionActionTypes.DELETE_PERMISSION_REQUEST,
        error
      )
    );
    Swal.fire("Error!", `${error.message}`, "error");
  }
}

export function* watchDeletePermission() {
  yield takeEvery(
    PermissionActionTypes.DELETE_PERMISSION_REQUEST,
    DeletePermissionSaga
  );
}

function* permissionSaga() {
  yield all([
    fork(watchFetchPermission),
    fork(watchAddPermission),
    fork(watchDeletePermission),
  ]);
}

export default permissionSaga;
