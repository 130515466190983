// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
  | AuthActionTypes.API_RESPONSE_SUCCESS
  | AuthActionTypes.API_RESPONSE_ERROR
  | AuthActionTypes.FORGOT_PASSWORD
  | AuthActionTypes.FORGOT_PASSWORD_CHANGE
  | AuthActionTypes.LOGIN_USER
  | AuthActionTypes.LOGOUT_USER
  | AuthActionTypes.RESET
  | AuthActionTypes.SIGNUP_USER
  | AuthActionTypes.VERIFY_OTP
  | AuthActionTypes.RESET_PASSWORD;
  payload: {} | string;
}

interface UserData {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (
  data: any,
): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: data,
});

export const logoutUser = (token: any): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: token,
});

export const SignUpUser = (reqBody: any): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: reqBody,
});

export const signupUser = (
  name: string,
  email: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { name, email, password },
});

export const forgotPassword = (email: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const verifyOTP = (reqBody: any): AuthActionType => ({
  type: AuthActionTypes.VERIFY_OTP,
  payload: reqBody,
});

export const resetPassword = (reqBody: any): AuthActionType => ({
  type: AuthActionTypes.RESET_PASSWORD,
  payload: reqBody,
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
