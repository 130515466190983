import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import { taskApiResponseSuccess, taskApiResponseError } from "./actions";
import { TaskActionTypes } from "./constants";
// import {fetchProductsAPI} from "./api";
import {
  CustomerList,
  addTask,
  clientTask,
  deleteTask,
  editStatusTask,
  editTask,
  fetchSubAdminList,
  fetchTask,
} from "../../helpers/api/tasksApi";
import notify from "../../components/Notification/notify";
import Swal from "sweetalert2";

interface TaskData {
  payload: {
    name: string;
    price: string;
    stock: string;
  };
  type: string;
}

function* fetchTaskSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      filter_by: parseInt(payload.filter_by) ?? 0,
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(fetchTask, { data });
    const productData: any = response.data;
    yield put(taskApiResponseSuccess(TaskActionTypes.FETCH_TASKS, productData));
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.FETCH_TASKS, error));
  }
}

export function* watchFetchTask() {
  yield takeEvery(TaskActionTypes.FETCH_TASKS, fetchTaskSaga);
}

function* addTaskSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(addTask, { data });
    const payload: any = response.data;
    yield put(taskApiResponseSuccess(TaskActionTypes.ADD_TASK, payload));
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.ADD_TASK, error));
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddTask() {
  yield takeEvery(TaskActionTypes.ADD_TASK, addTaskSaga);
}

//Edit Task details

function* EditTaskSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(editTask, { data });
    const payload: any = response.data;
    yield put(taskApiResponseSuccess(TaskActionTypes.EDIT_TASK, payload));
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.EDIT_TASK, error));
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditTask() {
  yield takeEvery(TaskActionTypes.EDIT_TASK, EditTaskSaga);
}

//Delete Task  Details...

function* DeleteTaskSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(deleteTask, { data });
    const payload: any = response.data.data;
    yield put(taskApiResponseSuccess(TaskActionTypes.DELETE_TASK, payload));
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.DELETE_TASK, error));
  }
}

export function* watchDeleteTask() {
  yield takeEvery(TaskActionTypes.DELETE_TASK, DeleteTaskSaga);
}

//Fetch SubAdmin List
function* fetchSubAdminListSaga(): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: "",
      sort_field: "created_datetime",
      sort_order: "desc",
    };
    const response = yield call(fetchSubAdminList, { data });
    const payload: any = response.data;
    yield put(
      taskApiResponseSuccess(TaskActionTypes.FETCH_SUBADMIN_LIST, payload)
    );
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.FETCH_SUBADMIN_LIST, error));
  }
}

export function* watchFetchSubAdminList() {
  yield takeEvery(TaskActionTypes.FETCH_SUBADMIN_LIST, fetchSubAdminListSaga);
}

//Edit Status Task details

function* EditStatusSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(editStatusTask, { data });
    const payload: any = response.data;
    yield put(
      taskApiResponseSuccess(TaskActionTypes.EDIT_STATUS_TASK, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.EDIT_STATUS_TASK, error));
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditStatus() {
  yield takeEvery(TaskActionTypes.EDIT_STATUS_TASK, EditStatusSaga);
}


//Fetch Customer List
function* fetchCustomerList(): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: "",
      sort_field: "created_datetime",
      sort_order: "desc",
    };
    const response = yield call(CustomerList, {});
    const payload: any = response.data;
    yield put(
      taskApiResponseSuccess(TaskActionTypes.CUSTOMER_LIST, payload)
    );
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.CUSTOMER_LIST, error));
  }
}

export function* watchCustomerList() {
  yield takeEvery(TaskActionTypes.CUSTOMER_LIST, fetchCustomerList);
}


function* fetchClientTaskSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(clientTask, { data });
    const productData: any = response.data;
    yield put(taskApiResponseSuccess(TaskActionTypes.FETCH_CLIENT_TASK, productData));
  } catch (error: any) {
    yield put(taskApiResponseError(TaskActionTypes.FETCH_CLIENT_TASK, error));
  }
}

export function* watchFetchClientTask() {
  yield takeEvery(TaskActionTypes.FETCH_CLIENT_TASK, fetchClientTaskSaga);
}
function* taskSaga() {
  yield all([
    fork(watchFetchTask),
    fork(watchAddTask),
    fork(watchEditTask),
    fork(watchDeleteTask),
    fork(watchFetchSubAdminList),
    fork(watchEditStatus),
    fork(watchCustomerList),
    fork(watchFetchClientTask),
  ]);
}

export default taskSaga;
