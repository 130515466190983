import { APICore } from "../apiCore";
import config from "../../../config";
const api = new APICore();

// Listing Shopping from cart 
function FetchUserShoppingList(params: any) {
  const baseUrl = config.API_URL + "/getShoppingList";
  return api.get(baseUrl, { });
}

function DeleteProductFromCartApi(params: any) {
    const id = params?.data?.product_id
    const baseUrl = config.API_URL + `/removeFromCart/${id}`;
    return api.deleteResponse(baseUrl, {...params?.data});
  }


// Place Order API
function PlaceOrderApi(params: any) {
  const baseUrl = config.API_URL + "/placeOrder";
  return api.create(baseUrl, { ...params?.data});
}

// Listing Admin Profile
function OrderAddToCartApi(params: any) {
  const baseUrl = config.API_URL + "/addToCart";
  return api.create(baseUrl, { ...params?.data });
}

export {
    FetchUserShoppingList,
    DeleteProductFromCartApi,
    PlaceOrderApi,
    OrderAddToCartApi
};
