import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Category
function fetchCategory() {
  const baseUrl = config.API_URL + "/admin/listProductCategory";
  return api.get(baseUrl, {});
}

//Adding Category
function addCategory(params: any) {
  const baseUrl = config.API_URL + "/admin/addProductCategory";
  return api.create(baseUrl, { ...params?.data });
}

//Editing Category
function editCategory(params: any) {
  const baseUrl = config.API_URL + `/admin/updateProductCategory`;
  return api.update(baseUrl, { ...params?.data });
}

//Deleting Category
function deleteCategory(params: any) {
  const baseUrl = config.API_URL + "/admin/deleteProductCategory";
  return api.deleteResponse(baseUrl, params?.data);
}

export { fetchCategory, addCategory, editCategory, deleteCategory };
