import { OrdersActionTypes } from "./constants";

const INIT_STATE = {
  ordersDetails: null,
  newOrderRequestDetails: null,
  actionOrderDetails: null,
  loading: false,
  orderIdDetails: null,
  error: null,
  is_action: false,
  orderStatus: null,
  addOrderQty: null,
  deleteOrder: null,
  orderProductData: null,
};

export interface OrdersActionType {
  type:
  | OrdersActionTypes.API_RESPONSE_SUCCESS
  | OrdersActionTypes.API_RESPONSE_ERROR
  | OrdersActionTypes.FETCH_ORDER_REQUEST
  | OrdersActionTypes.FETCH_NEW_ORDER_REQUEST
  | OrdersActionTypes.ACTIONS_ORDER_REQUEST
  | OrdersActionTypes.FETCH_ORDER_DETAILS
  | OrdersActionTypes.UPDATE_ORDER_STATUS
  | OrdersActionTypes.ADD_ORDER
  | OrdersActionTypes.DELETE_ORDER
  | OrdersActionTypes.ORDER_PRODUCT_LIST
  | OrdersActionTypes.LIST_CUSTOMERS_ORDER
  | OrdersActionTypes.ADD_CUSTOMER_ORDER;
  payload: {
    actionType?: string;
    data?: any | {};
    error?: string;
  };
}

interface State {
  ordersDetails?: any;
  newOrderRequestDetails?: any;
  actionOrderDetails?: any;
  orderIdDetails?: any;
  loading?: boolean;
  value?: boolean;
  is_action?: boolean;
  orderStatus?: any;
  addOrderQty?: any;
  deleteOrder?: any;
  orderProductData?: any;
}

const OrdersReducer = (
  state: State = INIT_STATE,
  action: OrdersActionType
): any => {
  switch (action.type) {
    case OrdersActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case OrdersActionTypes.FETCH_ORDER_REQUEST: {
          return {
            ...state,
            ordersDetails: action.payload.data,
            loading: false,
          };
        }

        case OrdersActionTypes.FETCH_NEW_ORDER_REQUEST: {
          return {
            ...state,
            newOrderRequestDetails: action.payload.data,
            loading: false,
          };
        }

        case OrdersActionTypes.ACTIONS_ORDER_REQUEST: {
          return {
            ...state,
            actionOrderDetails: action.payload.data,
            is_order_accepted: false,
            loading: false,
          };
        }

        case OrdersActionTypes.FETCH_ORDER_DETAILS: {
          return {
            ...state,
            orderIdDetails: action.payload.data,
            loading: false,
          };
        }

        case OrdersActionTypes.UPDATE_ORDER_STATUS: {
          return {
            ...state,
            orderStatus: action.payload.data,
            loading: false,
          };
        }

        case OrdersActionTypes.ADD_ORDER: {
          return {
            ...state,
            addOrderQty: action.payload.data,
            loading: false,
          };
        }

        case OrdersActionTypes.DELETE_ORDER: {
          return {
            ...state,
            deleteOrder: action.payload.data,
            isDeleted: true,
            loading: false,
          };
        }

        case OrdersActionTypes.ORDER_PRODUCT_LIST: {
          return {
            ...state,
            orderProductData: action.payload.data,
            loading: false,
          };
        }

        case OrdersActionTypes.LIST_CUSTOMERS_ORDER: {
          return {
            ...state,
            orderCustomersData: action.payload.data,
            loading: false,
          };
        }

        case OrdersActionTypes.ADD_CUSTOMER_ORDER: {
          return {
            ...state,
            addCustomerOrder: action.payload.data,
            loading: false,
            isOrderAdded: true,
          };
        }

        default:
          return { ...state };
      }

    case OrdersActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case OrdersActionTypes.FETCH_ORDER_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case OrdersActionTypes.FETCH_NEW_ORDER_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case OrdersActionTypes.ACTIONS_ORDER_REQUEST: {
          return {
            ...state,
            is_order_accepted: true,
            loading: false,
          };
        }

        case OrdersActionTypes.FETCH_ORDER_DETAILS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case OrdersActionTypes.UPDATE_ORDER_STATUS: {
          return {
            ...state,
            orderStatusError: action.payload.error,
            loading: false,
          };
        }

        case OrdersActionTypes.ADD_ORDER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case OrdersActionTypes.DELETE_ORDER: {
          return {
            ...state,
            error: action.payload.error,
            isDeleted: false,
            loading: false,
          };
        }

        case OrdersActionTypes.ORDER_PRODUCT_LIST: {
          return {
            ...state,
            loading: false,
          };
        }

        case OrdersActionTypes.LIST_CUSTOMERS_ORDER: {
          return {
            ...state,
            loading: false,
          };
        }

        case OrdersActionTypes.ADD_CUSTOMER_ORDER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isOrderAdded: false,
          };
        }

        default:
          return { ...state };
      }

    case OrdersActionTypes.FETCH_ORDER_REQUEST:
      return { ...state, loading: true };

    case OrdersActionTypes.FETCH_NEW_ORDER_REQUEST:
      return { ...state, loading: true };

    case OrdersActionTypes.ACTIONS_ORDER_REQUEST:
      return { ...state, loading: true, is_order_accepted: true };

    case OrdersActionTypes.FETCH_ORDER_DETAILS:
      return { ...state, loading: true };

    case OrdersActionTypes.UPDATE_ORDER_STATUS:
      return { ...state, loading: true };

    case OrdersActionTypes.ADD_ORDER:
      return { ...state, loading: true };

    case OrdersActionTypes.DELETE_ORDER:
      return { ...state, loading: true };

    case OrdersActionTypes.ORDER_PRODUCT_LIST:
      return { ...state, loading: true };

    case OrdersActionTypes.LIST_CUSTOMERS_ORDER:
      return { ...state, loading: true };

    case OrdersActionTypes.ADD_CUSTOMER_ORDER:
      return { ...state, loading: true, isOrderAdded: false };

    default:
      return { ...state };
  }
};
export default OrdersReducer;
