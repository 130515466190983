import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

// components
import PageTitle from "../../../components/PageTitle";

import product1 from "../../../assets/images/products/product-1.png";
import product2 from "../../../assets/images/products/product-2.png";
import product3 from "../../../assets/images/products/product-3.png";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  AddOrderQty,
  DeleteOrder,
  UpdateOrderStatus,
  fetchNewOrdersRequestAction,
  fetchOrderDetailsAction,
  orderAction,
} from "../../../redux/actions";
import classNames from "classnames";
import { Formik } from "formik";
import { ORDER_STATUS, PAYMENT_STATUS } from "../../../constants/status";
import Swal from "sweetalert2";

interface OrderItem {
  id: number;
  name: string;
  quantity: number;
  image: string;
  price: string;
  total: string;
}

interface ShippingAddress {
  provider: string;
  address: string;
  phone: string;
  mobile: string;
}

interface Billing {
  type: string;
  provider: string;
  valid: string;
}

interface Delivery {
  provider: string;
  order_id: string;
  payment_mode: string;
}

interface OrderDetailsType {
  id: string;
  tracking_id: string;
  order_status?: string;
  items: OrderItem[];
  sub_total: string;
  shipping_charge: string;
  tax: string;
  net_total: string;
  shipping: ShippingAddress;
  billing: Billing;
  delivery: Delivery;
}

// track order
const TrackOrder = () => {
  return (
    <>
      <div className="track-order-list">
        <ul className="list-unstyled">
          <li className="completed">
            <h5 className="mt-0 mb-1">Order Placed</h5>
            <p className="text-muted">
              April 21 2019 <small className="text-muted">07:22 AM</small>{" "}
            </p>
          </li>
          <li className="completed">
            <h5 className="mt-0 mb-1">Packed</h5>
            <p className="text-muted">
              April 22 2019 <small className="text-muted">12:16 AM</small>
            </p>
          </li>
          <li>
            <span className="active-dot dot"></span>
            <h5 className="mt-0 mb-1">Shipped</h5>
            <p className="text-muted">
              April 22 2019 <small className="text-muted">05:16 PM</small>
            </p>
          </li>
          <li>
            <h5 className="mt-0 mb-1"> Delivered</h5>
            <p className="text-muted">Estimated delivery within 3 days</p>
          </li>
        </ul>

        <div className="text-center mt-4">
          <Link to="#" className="btn btn-primary">
            Show Details
          </Link>
        </div>
      </div>
    </>
  );
};

interface CartSummaryTypes {
  gross_total?: number;
  discount?: number;
  shipping_charge?: number;
  tax?: number;
  net_total?: number;
}

// summary
const CartSummary = (props: { summary: CartSummaryTypes }) => {
  const summary = props.summary || {};

  return (
    <>
      <div className="border p-3 mt-4 mt-lg-0 rounded">
        <h4 className="header-title mb-3">Order Summary</h4>

        <div className="table-responsive">
          <table className="table mb-0">
            <tbody>
              <tr>
                <td>Grand Total :</td>
                <td>{summary.gross_total!.toFixed(2)}</td>
              </tr>
              {/* <tr>
                <td>Discount : </td>
                <td>{summary.discount!.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Shipping Charge :</td>
                <td>{summary.shipping_charge!.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Estimated Tax : </td>
                <td>{summary.tax!.toFixed(2)}</td>
              </tr> */}
              <tr>
                <th>Total :</th>
                <th>
                  ₹{" "}
                  {summary
                    .net_total!.toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

// Item Table
// const Items = ({ orders }: { orders: any }) => {
//   const [orderDetailList, setOrderDetailList] = useState<any>();
//   const [orderID, setOrderID] = useState<any>();

//   const dispatch = useDispatch<AppDispatch>();
//   const [summary, setSummary] = useState<CartSummaryTypes>({
//     gross_total: 0.0,
//     discount: 0.0,
//     shipping_charge: 0,
//     tax: 0,
//     net_total: 0,
//   });

//   const { addOrderQty, loading, error, isDeleted, orderIdDetails } =
//     useSelector((state: RootState) => ({
//       addOrderQty: state.OrdersReducer.addOrderQty,
//       loading: state.OrdersReducer.loading,
//       error: state.OrdersReducer.error,
//       isDeleted: state.OrdersReducer.isDeleted,
//       orderIdDetails: state.OrdersReducer.orderIdDetails,
//     }));
//   useEffect(() => {
//     setOrderDetailList(orders);
//     // dispatch(fetchOrderDetailsAction(orders.user_orders_id));
//   }, []);

//   useEffect(() => {
//     if (
//       orderIdDetails?.statusCode === 200 &&
//       orderIdDetails?.data !== undefined
//     ) {
//       setOrderDetailList(orderIdDetails.data);
//     }
//   }, [orderIdDetails]);

//   useEffect(() => {
//     if (orderDetailList && orderDetailList?.wm_user_order_items.length > 0) {
//       const totalBill = orderDetailList?.wm_user_order_items.reduce(
//         (total: any, item: any) => {
//           const { wm_product, qty } = item;
//           return total + parseInt(wm_product?.rrr_price) * parseInt(qty);
//         },
//         0
//       );
//       setSummary({
//         ...summary,
//         gross_total: totalBill,
//         net_total: totalBill,
//       });
//     } else {
//       setSummary({
//         gross_total: 0.0,
//         discount: 0.0,
//         shipping_charge: 0,
//         tax: 0,
//         net_total: 0,
//       });
//     }
//   }, [orders, orderDetailList, orderIdDetails]);

//   useEffect(() => {
//     if (
//       addOrderQty !== null &&
//       addOrderQty !== undefined &&
//       addOrderQty.statusCode === 200
//     ) {
//       dispatch(fetchOrderDetailsAction(orders.user_orders_id));
//     } else {
//       console.log("in else");
//     }
//   }, [addOrderQty, dispatch]);
//   useEffect(() => {
//     if (isDeleted) {
//       dispatch(fetchOrderDetailsAction(orders.user_orders_id));
//     } else {
//       console.log("in else");
//     }
//   }, [isDeleted, dispatch]);

//   const onQtyChange = (e: any, item: any) => {
//     const orderRes = {
//       user_orders_id: item.user_orders_id,
//       product_id: item.product_id,
//       qty: e.target.value,
//     };
//     dispatch(AddOrderQty(orderRes));
//     setOrderID(item.user_orders_id);
//   };

//   const removeItem = (e: any, id: any, user_orders_id: any) => {
//     const dataRes = {
//       user_orders_id: user_orders_id,
//       product_id: id,
//     };
//     try {
//       Swal.fire({
//         title: "Are you sure?",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes",
//       }).then(async (result) => {
//         if (result.isConfirmed) {
//           // Dispatch the delete action
//           dispatch(DeleteOrder(dataRes));
//           // Show success addOrderQty after dispatching action

//           // if(deleteMessage.statusCode === 200 && deleteMessage !== null && deleteMessage !== undefined){
//           Swal.fire(
//             "Deleted!",
//             "Your Product has been removed SuccessFully.",
//             "success"
//           ).then(async () => {
//             // Dispatch fetch_data action after success message is shown
//             // await dispatch(GetShoppingListAction());
//           });
//           // }else{
//           //   Swal.fire("Error", "Something went wrong!", "error");
//           // }
//         } else {
//           Swal.fire("Cancelled", "Your Product has been cancelled :)", "info");
//         }
//       });
//     } catch (error: any) {
//       Swal.fire("Error", "Something went wrong!", "error");
//     }
//   };

//   return (
//     <>
//       <Row>
//         <Col>
//           <Card>
//             <Card.Body>
//               <Row>
//                 <Col lg={8}>
//                   <div className="table-responsive">
//                     <table className="table table-borderless table-centered mb-0">
//                       <thead className="table-light">
//                         <tr>
//                           <th>Product</th>
//                           <th>Price</th>
//                           <th>Quantity</th>
//                           <th>Total</th>
//                           <th style={{ width: "50px" }}></th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {(
//                           (orderDetailList &&
//                             orderDetailList?.wm_user_order_items) ||
//                           []
//                         ).map((item: any, idx: any) => {
//                           return (
//                             <tr key={idx}>
//                               <td>
//                                 <img
//                                   src={
//                                     item?.wm_product?.wm_product_photos &&
//                                     item?.wm_product?.wm_product_photos.length >
//                                       0
//                                       ? item?.wm_product?.wm_product_photos[0]
//                                           ?.photo_url
//                                       : "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-5.png"
//                                   }
//                                   alt={item.name}
//                                   title="contact-img"
//                                   className="rounded me-3"
//                                   height="40"
//                                 />

//                                 <p className="m-0 d-inline-block align-middle font-16">
//                                   <Link
//                                     to=""
//                                     // to="/apps/ecommerce/product-details"
//                                     className="text-body"
//                                   >
//                                     {item?.wm_product?.product_name}
//                                   </Link>
//                                   <br />
//                                   <small className="me-2">
//                                     <b>Size:</b>{" "}
//                                     {item?.wm_product?.attribute_size}{" "}
//                                   </small>
//                                   <small>
//                                     <b>Color:</b>{" "}
//                                     <span className="text-capitalize">
//                                       {item?.wm_product?.attribute_color}{" "}
//                                     </span>
//                                   </small>
//                                 </p>
//                               </td>
//                               <td>
//                                 {parseInt(item?.wm_product?.rrr_price).toFixed(
//                                   2
//                                 )}
//                               </td>
//                               <td>
//                                 <input
//                                   type="number"
//                                   min="1"
//                                   // value={item.qty}
//                                   defaultValue={item.qty}
//                                   className="form-control"
//                                   placeholder="Qty"
//                                   style={{ width: "90px" }}
//                                   onBlur={(e: any) => {
//                                     onQtyChange(e, item);
//                                   }}
//                                 />
//                                 {/* {item.qty} */}
//                               </td>
//                               <td>
//                                 {(
//                                   parseInt(item?.wm_product?.rrr_price) *
//                                   parseInt(item?.qty)
//                                 ).toFixed(2)}
//                               </td>

//                               <td>
//                                 <Button
//                                   // to=""
//                                   // className="action-icon"
//                                   onClick={(e: any) => {
//                                     removeItem(
//                                       e,
//                                       item?.wm_product?.product_id,
//                                       item?.user_orders_id
//                                     );
//                                   }}
//                                 >
//                                   <i className="mdi mdi-delete"></i>
//                                 </Button>
//                               </td>
//                             </tr>
//                           );
//                         })}
//                       </tbody>
//                     </table>
//                   </div>
//                 </Col>

//                 <Col lg={4}>
//                   <CartSummary summary={summary} />
//                 </Col>
//               </Row>

//               {/* <div className="text-end mt-3">
//                 <button
//                   className="btn btn-success"
//                   onClick={() => {
//                     onHide();
//                   }}
//                 >
//                   Close
//                 </button>
//               </div> */}
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </>
//   );
// };
//Old Item Table
const Items = ({ order }: { order: any }) => {
  const subTotal = order?.wm_user_order_items.reduce(
    (acc: number, item: any) => {
      return acc + item?.wm_product?.rrr_price * item?.qty;
    },
    0
  );

  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered table-centered mb-0">
          <thead className="table-light">
            <tr>
              <th>Product name</th>
              <th>Product</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {(order?.wm_user_order_items || []).map((item: any, index: any) => {
              // {(Object.entries(orderDataDetails.wm_product).length > 0 && orderDataDetails.wm_product).map((item: any, index: any) => {
              return (
                <tr key={index}>
                  <th scope="row">{item?.wm_product?.product_name}</th>
                  <td>
                    <img
                      src={
                        item?.wm_product?.wm_product_photos &&
                          item?.wm_product?.wm_product_photos.length > 0
                          ? item?.wm_product?.wm_product_photos[0]?.photo_url
                          : "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-5.png"
                      }
                      alt=""
                      height="40"
                    />
                  </td>
                  <td>{item?.qty}</td>
                  <td>{item?.wm_product?.rrr_price}</td>
                  <td>{item?.total_amount}</td>
                </tr>
              );
            })}
            {/* <tr>
              <th scope="row" colSpan={4} className="text-end">
                Sub Total :
              </th>
              <td>
                <div className="fw-bold">{subTotal}</div>
              </td>
            </tr> */}
            {/* <tr>
              <th scope="row" colSpan={4} className="text-end">
                Shipping Charge :
              </th>
              <td>{order.shipping_charge}</td>
            </tr>
            <tr>
              <th scope="row" colSpan={4} className="text-end">
                Estimated Tax :
              </th>
              <td>{order.tax}</td>
            </tr> */}
            <tr>
              <th scope="row" colSpan={4} className="text-end">
                Total :
              </th>
              <td>
                <div className="fw-bold">
                  ₹{" "}
                  {parseFloat(subTotal)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

// shipping info
const ShippingInfo = (props: { details: any }) => {
  const details = props.details || {};
  return (
    <React.Fragment>
      <h5 className="font-family-primary fw-semibold text-capitalize">
        Customer Name: {details?.name || "-"}
      </h5>

      <p className="mb-2">
        <span className="fw-semibold me-2">Address:</span>{" "}
        {details?.wm_user_profile?.address_main_line +
          ", " +
          details?.wm_user_profile?.city +
          ", " +
          details?.wm_user_profile?.postcode || "-"}
      </p>
      <p className="mb-2">
        <span className="fw-semibold me-2">Phone:</span>{" "}
        {details?.wm_user_profile?.phone_number || "-"}
      </p>
      <p className="mb-0">
        <span className="fw-semibold me-2">GST:</span>{" "}
        {details?.wm_user_profile?.gst_number || "-"}
      </p>
    </React.Fragment>
  );
};

// billing info
const BillingInfo = (props: { details: Billing }) => {
  const details = props.details || {};
  return (
    <React.Fragment>
      <ul className="list-unstyled mb-0">
        <li>
          <p className="mb-2">
            <span className="fw-semibold me-2">Payment Type:</span>{" "}
            {details.type}
          </p>
          <p className="mb-2">
            <span className="fw-semibold me-2">Provider:</span>{" "}
            {details.provider}
          </p>
          <p className="mb-2">
            <span className="fw-semibold me-2">Valid Date:</span>{" "}
            {details.valid}
          </p>
          <p className="mb-0">
            <span className="fw-semibold me-2">CVV:</span> xxx
          </p>
        </li>
      </ul>
    </React.Fragment>
  );
};

// delivery info
const DeliveryInfo = (props: { details: Delivery }) => {
  const details = props.details || {};
  return (
    <React.Fragment>
      <div className="text-center">
        <i className="mdi mdi-truck-fast h2 text-muted"></i>
        <h5>
          <b>{details.provider}</b>
        </h5>
        <p className="mb-1">
          <span className="fw-semibold">Order ID :</span> {details.order_id}
        </p>
        <p className="mb-0">
          <span className="fw-semibold">Payment Mode :</span>{" "}
          {details.payment_mode}
        </p>
      </div>
    </React.Fragment>
  );
};

const StatusOrder = ({ order }: { order: any }) => {
  return (
    <React.Fragment>
      <span
        className={classNames("badge", {
          "bg-soft-success text-success": order === 1 || order === 4,
          "bg-soft-success text-info": order === 3,
          "bg-soft-danger text-danger": order === 2,
          "bg-soft-secondary text-secondary": order === 0,
        })}
      >
        {order === 1
          ? "Accepted / Processing"
          : order === 2
            ? "Rejected"
            : order === 0
              ? "Request"
              : order === 3
                ? "Dispatch"
                : order === 4
                  ? "Delivered"
                  : "Request"}
      </span>
    </React.Fragment>
  );
};

// order details
const OrderRequestsDetails = () => {
  let { order_id } = useParams();
  const [orderData, setOrderData] = useState<any>({});
  const [orderStatuses, setOrderStatuses] = useState<any>({});
  const [paymentStatuses, setPaymentStatuses] = useState<any>({});
  const dispatch = useDispatch<AppDispatch>();

  // * product data
  const { orderIdDetails, loading, error, is_order_accepted } = useSelector(
    (state: RootState) => ({
      orderIdDetails: state.OrdersReducer.orderIdDetails,
      loading: state.OrdersReducer.loading,
      error: state.OrdersReducer.error,
      is_order_accepted: state.OrdersReducer.is_order_accepted,
    })
  );

  useEffect(() => {
    dispatch(fetchOrderDetailsAction(order_id));
  }, [dispatch]);

  useEffect(() => {
    if (
      orderIdDetails?.statusCode === 200 &&
      orderIdDetails?.data !== undefined
    ) {
      setOrderData(orderIdDetails.data);
    }
  }, [orderIdDetails]);

  const order: OrderDetailsType = {
    id: "#BM31",
    tracking_id: "894152012012",
    order_status: "Packed",
    items: [
      {
        id: 1,
        name: "Polo Navy blue T-shirt",
        image: product1,
        quantity: 1,
        price: "$39",
        total: "$39",
      },
      {
        id: 2,
        name: "Red Hoodie for men",
        image: product2,
        quantity: 2,
        price: "$46",
        total: "$92",
      },
      {
        id: 3,
        name: "Red Hoodie for men",
        image: product3,
        quantity: 1,
        price: "$46",
        total: "$46",
      },
    ],
    sub_total: "$177",
    shipping_charge: "$24",
    tax: "$12",
    net_total: "$213",
    shipping: {
      provider: "Stanley Jones",
      address: "3559 Roosevelt Wilson Lane San Bernardino, CA 92405",
      phone: "(123) 456-7890 ",
      mobile: "(+01) 12345 67890",
    },
    billing: {
      type: "Credit Card",
      provider: "Visa ending in 2851",
      valid: "02/2020",
    },
    delivery: {
      provider: "UPS Delivery",
      order_id: "#BM31",
      payment_mode: "COD",
    },
  };

  const OrderStatusColumn = ({ row }: { row: any }) => {
    const handleChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      userOrdersId: any
    ) => {
      const selectedStatus = parseInt(e.target.value);
      const data = {
        user_orders_id: userOrdersId,
        status: selectedStatus,
        action: "order_status",
      };
      dispatch(UpdateOrderStatus(data));
      setOrderStatuses((prevStatuses: any) => ({
        ...prevStatuses,
        [userOrdersId]: selectedStatus,
      }));
    };
    return (
      <Formik
        initialValues={{ order_status: row.order_status }}
        onSubmit={() => { }}
      >
        {({ values }) => (
          <select
            className="form-select bg-transparent text-dark w-50"
            id={`order_status`}
            name={`order_status`}
            value={orderStatuses[row.user_orders_id] || row.order_status}
            onChange={(e) => handleChange(e, row.user_orders_id)}
          // disabled={row.order_status == 2 ? true : false}
          >
            {ORDER_STATUS.length > 0 &&
              ORDER_STATUS.map((item: any) => (
                <option key={item.status} value={item.status}>
                  {item.name}
                </option>
              ))}
          </select>
        )}
      </Formik>
    );
  };

  const PaymentStatusColumn = ({
    row,
  }: // rowIndex,
    {
      row: any;
      // rowIndex: number;
    }) => {
    const handleChange = (
      e: React.ChangeEvent<HTMLSelectElement>,
      userOrdersId: any
    ) => {
      const selectedStatus = parseInt(e.target.value);
      const data = {
        user_orders_id: userOrdersId,
        status: selectedStatus,
        action: "payment_status",
      };
      dispatch(UpdateOrderStatus(data));
      setPaymentStatuses((prevStatuses: any) => ({
        ...prevStatuses,
        [userOrdersId]: selectedStatus,
      }));
    };
    return (
      <Formik
        initialValues={{ payment_status: row.payment_status }}
        onSubmit={() => { }}
      >
        {({ values }) => (
          <select
            className="form-select bg-transparent text-dark w-50"
            // id={`payment_status_${rowIndex}`}
            // name={`payment_status_${rowIndex}`}
            id={`payment_status`}
            name={`payment_status`}
            value={paymentStatuses[row.user_orders_id] || row.payment_status}
            onChange={(e) => handleChange(e, row.user_orders_id)}
          // disabled={row.order_status == 2 ? true : false}
          >
            {PAYMENT_STATUS.length > 0 &&
              PAYMENT_STATUS.map((item: any) => (
                <option key={item.status} value={item.status}>
                  {item.name}
                </option>
              ))}
          </select>
        )}
      </Formik>
    );
  };

  const navigate = useNavigate();
  const [isOrderAccept, setIsOrderAccept] = useState(false)

  useEffect(() => {
    if (isOrderAccept) {
      if (!is_order_accepted) {
        let msg = "Something went wrong."
        Swal.fire("Error!", msg, "error").then(() => {
        });
      } else {
        navigate("/admin/orderRequests/");
      }
    }
    setIsOrderAccept(false)
  }, [isOrderAccept])

  // Accept Order Functions....

  const onAcceptOrder = (data: any) => {
    const resData = {
      user_orders_id: data.user_orders_id,
      action: 1,
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(orderAction(resData));
          setIsOrderAccept(true)
          Swal.fire(
            "Order Accepted!",
            "Your Order has been taken.",
            "success"
          ).then(() => {
            dispatch(fetchNewOrdersRequestAction({}));
          });
        } else {
          Swal.fire("Cancelled", "Your Order has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  // Rejected Order Functions....

  const onRejectOrder = (data: any) => {
    const resData = {
      user_orders_id: data.user_orders_id,
      action: 2,
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(orderAction(resData));
          setIsOrderAccept(true)
          Swal.fire(
            "Order Rejected!",
            "Your Order has been rejected.",
            "success"
          ).then(() => {
            dispatch(fetchNewOrdersRequestAction({}));
          });
        } else {
          Swal.fire("Cancelled", "Your Order has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/admin/orderRequest/details" },
          {
            label: "Order Request Detail",
            path: "/admin/orderRequest/details",
            active: true,
          },
        ]}
        title={"Order Request Detail"}
      />

      <Row>
        <Col lg={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Customer Information</h4>
              {orderData && <ShippingInfo details={orderData.wm_user} />}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8}>
          <Card>
            <Card.Body>
              <Row className="mb-0">
                <Col lg={4} md={12} className="mb-3 mb-lg-0">
                  <div className="d-flex align-items-center">
                    <h4 className="header-title">
                      Items from Order #{orderData.user_order_number}
                    </h4>
                  </div>
                </Col>

                {/* <Col lg={4} md={6} className="mb-3">
                  <div className="d-flex align-items-center">
                    <h5 className="me-2">Payment Status:</h5>
                    <PaymentStatusColumn row={orderData} />
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <div className="d-flex align-items-center">
                    <h5 className="me-2">Order Status:</h5>
                    <OrderStatusColumn row={orderData} />
                  </div>
                </Col> */}
              </Row>
              {Object.keys(orderData).length > 0 && <Items order={orderData} />}
              <div className="d-flex mt-3 justify-content-end align-items-center">
                <Button
                  className="btn btn-success mb-2 me-1"
                  onClick={() => onAcceptOrder(orderData)}
                >
                  Accept <i className="mdi mdi-check"></i>
                </Button>
                <Button
                  className="btn btn-danger mb-2 me-1"
                  onClick={() => onRejectOrder(orderData)}
                >
                  Reject <i className="mdi mdi-delete"></i>
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        {/* <Col lg={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Shipping Information</h4>
              {orderData && <ShippingInfo details={orderData.wm_user} />}
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default OrderRequestsDetails;
