import { TaskActionTypes } from "./constants";

const INIT_STATE = {
  taskDetails: [],
  loading: false,
  error: null,
  is_add: false,
  is_edit: false,
  addSuccessMessage: null,
  is_deleted: false,
  editSuccessMessage: null,
  subAdminDetails: null,
  editStatusMessage: null,
  isStatus: false,
  CustomerDetails: null,
  clientTaskDetails: null,
};

interface taskData {
  taskDetails: any;
  loading: boolean;
  error: string | null;
  is_add: boolean;
  is_edit: boolean;
  addSuccessMessage: any;
  is_deleted: boolean;
  editSuccessMessage: any;
  subAdminDetails: any;
  editStatusMessage: any;
  isStatus: boolean;
  CustomerDetails: any;
  clientTaskDetails: any;
}

export interface TaskActionType {
  type:
    | TaskActionTypes.API_RESPONSE_SUCCESS
    | TaskActionTypes.API_RESPONSE_ERROR
    | TaskActionTypes.FETCH_TASKS
    | TaskActionTypes.FETCH_TASKS_ID
    | TaskActionTypes.ADD_TASK
    | TaskActionTypes.EDIT_TASK
    | TaskActionTypes.DELETE_TASK
    | TaskActionTypes.FETCH_SUBADMIN_LIST
    | TaskActionTypes.EDIT_STATUS_TASK
    | TaskActionTypes.CUSTOMER_LIST
    | TaskActionTypes.FETCH_CLIENT_TASK;
  payload: {
    actionType?: string;
    data?: taskData | {};
    error?: string;
  };
}

interface State {
  taskDetails?: taskData | {};
  loading?: boolean;
  value?: boolean;
  is_add: boolean;
  is_edit: boolean;
  addSuccessMessage?: any;
  is_deleted?: boolean;
  editSuccessMessage?: any;
  subAdminDetails?: any;
  editStatusMessage?: any;
  isStatus?: boolean;
  CustomerDetails?: any;
  clientTaskDetails?: any;
}

const TaskReducer = (
  state: State = INIT_STATE,
  action: TaskActionType
): any => {
  switch (action.type) {
    case TaskActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case TaskActionTypes.FETCH_TASKS: {
          return {
            ...state,
            taskDetails: action.payload.data,
            loading: false,
          };
        }

        case TaskActionTypes.FETCH_SUBADMIN_LIST: {
          return {
            ...state,
            subAdminDetails: action.payload.data,
            // loading: false,
          };
        }

        case TaskActionTypes.ADD_TASK: {
          return {
            ...state,
            addSuccessMessage: action.payload.data,
            is_add: false,
            loading: false,
          };
        }

        case TaskActionTypes.EDIT_TASK: {
          return {
            ...state,
            editSuccessMessage: action.payload.data,
            is_edit: false,
            loading: false,
          };
        }

        case TaskActionTypes.DELETE_TASK: {
          return {
            ...state,
            loading: false,
            is_deleted: false,
          };
        }

        case TaskActionTypes.EDIT_STATUS_TASK: {
          return {
            ...state,
            editStatusMessage: action.payload.data,
            isStatus: false,
            loading: false,
          };
        }

        case TaskActionTypes.FETCH_CLIENT_TASK: {
          return {
            ...state,
            clientTaskDetails: action.payload.data,
            loading: false,
          };
        }

        case TaskActionTypes.CUSTOMER_LIST: {
          return {
            ...state,
            CustomerDetails: action.payload.data,
            // loading: false,
          };
        }

        default:
          return { ...state };
      }

    case TaskActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case TaskActionTypes.FETCH_TASKS: {
          return {
            ...state,
            loading: false,
          };
        }

        case TaskActionTypes.FETCH_SUBADMIN_LIST: {
          return {
            ...state,
            // loading: false,
            error: action.payload.error,
          };
        }

        case TaskActionTypes.ADD_TASK: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            is_add: false,
          };
        }

        case TaskActionTypes.EDIT_TASK: {
          return {
            ...state,
            error: action.payload.error,
            is_edit: false,
            loading: false,
          };
        }

        case TaskActionTypes.DELETE_TASK: {
          return {
            ...state,
            loading: false,
            is_deleted: false,
          };
        }

        case TaskActionTypes.EDIT_STATUS_TASK: {
          return {
            ...state,
            TaskStatuserror: action.payload.error,
            isStatus: false,
            loading: false,
          };
        }

        case TaskActionTypes.CUSTOMER_LIST: {
          return {
            ...state,
            // loading: false,
            error: action.payload.error,
          };
        }

        default:
          return { ...state };
      }

    case TaskActionTypes.FETCH_TASKS:
      return { ...state, loading: true };

    case TaskActionTypes.FETCH_SUBADMIN_LIST:
      return { ...state };

    case TaskActionTypes.ADD_TASK:
      return { ...state, loading: true, is_add: true };

    case TaskActionTypes.EDIT_TASK:
      return { ...state, loading: true, is_edit: true };

    case TaskActionTypes.DELETE_TASK:
      return { ...state, loading: true, is_deleted: true };

    case TaskActionTypes.EDIT_STATUS_TASK:
      return { ...state, loading: true, isStatus: true };

    case TaskActionTypes.CUSTOMER_LIST:
      return { ...state };

    default:
      return { ...state };
  }
};
export default TaskReducer;
