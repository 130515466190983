import { error } from "console";
import { UserOrderActionTypes } from "./constants";

const INIT_STATE = {
message: null,
  loading: false,
  error: null,
};

export interface UserOrderActionType {
  type:
    | UserOrderActionTypes.API_RESPONSE_SUCCESS
    | UserOrderActionTypes.API_RESPONSE_ERROR
    | UserOrderActionTypes.GET_USER_ORDER_LIST;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  message?: any;
  loading?: boolean;
  value?: boolean;
}

const UserOrderReducer = (
  state: State = INIT_STATE,
  action: UserOrderActionType
): any => {
  switch (action.type) {
    case UserOrderActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UserOrderActionTypes.GET_USER_ORDER_LIST: {
          return {
            ...state,
            userOrderData: action.payload.data,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case UserOrderActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UserOrderActionTypes.GET_USER_ORDER_LIST: {
          return {
            ...state,
            error: action.payload.data,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case UserOrderActionTypes.GET_USER_ORDER_LIST:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};
export default UserOrderReducer;
