import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import { FormInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  AddSubAdminAction,
  addTaskAction,
  editTaskAction,
  fetchSubAdminListAction,
} from "../../../redux/actions";

interface FormValues {
  title: string;
  task_description: string;
  user_id: string;
}

interface AddSubadminTaskProps {
  show: boolean;
  task: any;
  onHide: () => void;
  onSubmit: (value: any) => void;
}

const AddSubAdminTask = ({
  show,
  task,
  onHide,
  onSubmit,
}: AddSubadminTaskProps) => {
  const [subAdminList, setSubAdminList] = useState<any>([]);
  const dispatch = useDispatch<AppDispatch>();

  const { subAdminDetails, loading, error } = useSelector(
    (state: RootState) => ({
      subAdminDetails: state.TaskReducer.subAdminDetails,
      loading: state.TaskReducer.loading,
      error: state.TaskReducer.error,
    })
  );

  useEffect(() => {
    if (
      subAdminDetails?.statusCode === 200 &&
      subAdminDetails?.data !== undefined &&
      subAdminDetails?.data !== null
    ) {
      setSubAdminList(subAdminDetails?.data);
    }
  }, [subAdminDetails]);

  const initialValues: FormValues = {
    title: task?.title || "",
    task_description: task?.task_description || "",
    user_id: task?.user_id || "",
  };

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    title: Yup.string().required("Title is required"),
    // .matches(/^\S+$/, "Spaces are not allowed"),
    task_description: Yup.string().required("Description is required"),
    // .matches(/^\S+$/, "Spaces are not allowed"),
    user_id: Yup.string().required("Assignee is required"),
  });

  useEffect(() => {
    dispatch(fetchSubAdminListAction());
  }, [dispatch]);

  const handleSubmit = (
    values: FormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    const reqBody:any = {
      title: values.title,
      task_description: values.task_description,
      user_id: values.user_id,
      type: 0,
    };
    // Handle submission here, e.g., send data to backend
    if (Object.keys(task).length > 0) {
      //Update Call
      reqBody.task_id = task?.task_id;
      dispatch(editTaskAction(reqBody));
    } else {
      //Add Call
      dispatch(addTaskAction(reqBody));
      resetForm();
    }
  };

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-light" onHide={onHide} closeButton>
        <Modal.Title className="m-0">
          {task && Object.keys(task).length > 0 ? "Update" : "Add"} Task Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="title">Title</label>
                <Field
                  placeholder="Enter title"
                  name="title"
                  id="title"
                  type="text"
                  className={`form-control mb-1 ${
                    errors.title && touched.title ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="description">Task Description</label>
                <Field
                  placeholder="Enter description"
                  name="task_description"
                  id="task_description"
                  as="textarea"
                  className={`form-control mb-1 ${
                    errors.task_description && touched.task_description
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage
                  name="task_description"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="user_id">Assign To</label>
                <Field
                  as="select"
                  className="form-select"
                  id="user_id"
                  name="user_id"
                >
                  <option value="" disabled>
                    Select an admin
                  </option>

                  {subAdminList.length > 0 &&
                    subAdminList.map((item: any) => (
                      <option key={item.user_id} value={item.user_id}>
                        {item.name}
                      </option>
                    ))}
                  {/* <option value="user2">User 2</option>
                <option value="user3">User 3</option> */}
                </Field>
                <ErrorMessage name="user_id" component="div" />
              </div>
              <div className="text-end mt-3">
                <button className="btn btn-success" type="submit">
                  {task && Object.keys(task).length > 0 ? "Update" : "Create"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default AddSubAdminTask;
