import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { Card } from "react-bootstrap";
import FileUploader from "../../components/FileUploader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchUserProfileAction } from "../../redux/actions";

function UserProfileForm() {
  // const { Formik } = formik;
  const dispatch = useDispatch<AppDispatch>();

  const { userProfileDetails, editUserProfileDetails, loading, error } =
    useSelector((state: RootState) => ({
      userProfileDetails: state.UserProfileReducer.userProfileDetails,
      editUserProfileDetails: state.UserProfileReducer.editUserProfileDetails,
      loading: state.UserProfileReducer.loading,
      error: state.UserProfileReducer.error
    }));

  const schema = yup.object().shape({
    // firstName: yup.string().required(),
    // lastName: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    country: yup.string().required(),
    companyName: yup.string().required(),
    phone: yup.string().required(),
    landLineNumber: yup.string().required(),
    GSTNumber: yup.string().required(),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted")
  });

  const [userDetails, setUserDetails] = useState<any>();
  // const data: any = sessionStorage.getItem("session_user_details");
  // useEffect(() => {
  //   const user_data = JSON.parse(data);
  //   setUserDetails(user_data);
  // }, [data]);

  useEffect(() => {
    dispatch(fetchUserProfileAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      userProfileDetails?.status_code === 200 &&
      userProfileDetails?.data !== undefined &&
      userProfileDetails?.data !== null
    ) {
      setUserDetails(userProfileDetails?.data);
      // if(userProfileDetails?.data?.profile_image?.data !== null){
      //   handleDisplayImage(userProfileDetails?.data?.profile_image?.data);
      // }
    }
  }, [userProfileDetails]);

  const handleSubmit = (values: any) => {
    console.log("FormData ::::: ", values);
  };

  return (
    <Formik
      initialValues={{
        name: userDetails?.name || "",
        email: userDetails?.email || "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        companyName: "",
        phone: "",
        landLineNumber: "",
        GSTNumber: "",
        terms: false
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Row>
            <Col lg={6}>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                  </h5>
                  <Form>
                    <Row className="mb-3">
                      <div className=" col-md-6">
                        <label htmlFor="name">Name</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter name"
                          // defaultValue={userDetails?.name}
                          name="name"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="name" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="email">Email</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter email"
                          name="email"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <div className="">
                        <label htmlFor="address">Address</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter address"
                          name="address"
                          type="textarea"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="address" />
                        </div>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <div className="col-md-6">
                        <label htmlFor="city">City</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter city"
                          name="city"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="city" />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="state">State</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter state"
                          name="state"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="state" />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="zip">Zip</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter zip"
                          name="zip"
                          type="number"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="zip" />
                        </div>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <div className="col-md-3 mb-3">
                        <label htmlFor="country">Country</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter country"
                          name="country"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="country" />
                        </div>
                      </div>

                      <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                        Company info
                      </h5>
                      <div className="col-md-12">
                        <label htmlFor="companyName">Company Name</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter company Name"
                          name="companyName"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="companyName" />
                        </div>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <div className="col-md-6">
                        <label htmlFor="phone">Phone</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter phone"
                          name="phone"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="phone" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="landLineNumber">Land-line No.</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter Land-line No"
                          name="landLineNumber"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="landLineNumber" />
                        </div>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <div className="col-md-6">
                        <label htmlFor="GSTNumber">GST No.</label>
                        <Field
                          className="form-control mb-1"
                          placeholder="Enter GST No."
                          name="GSTNumber"
                          type="text"
                        />
                        <div style={{ color: "red" }}>
                          <ErrorMessage name="GSTNumber" />
                        </div>
                      </div>
                    </Row>

                    <Row>
                      <Col>
                        <div className="text-center mb-3">
                          {/* <button
                        type="button"
                        className="btn w-sm btn-light waves-effect me-1"
                      >
                        Cancel
                      </button> */}
                          <Button
                            variant="success"
                            type="submit"
                            className="waves-effect waves-light me-1"
                          >
                            Update
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    User Images
                  </h5>
                  <FileUploader />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        );
      }}
    </Formik>
  );
}

export default UserProfileForm;
