import { UserCartActionTypes } from "./constants";

export interface UserCartActionType {
  type:
    | UserCartActionTypes.API_RESPONSE_SUCCESS
    | UserCartActionTypes.API_RESPONSE_ERROR
    | UserCartActionTypes.GET_USER_SHOPPING_LIST
    | UserCartActionTypes.GET_REMOVE_PRODUCT
    | UserCartActionTypes.ORDER_ADD_TO_CART;
  payload: {} | string;
}

// common success
export const userCartApiResponseSuccess = (
  actionType: string,
  data: any | {}
): UserCartActionType => ({
  type: UserCartActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const userCartApiResponseError = (
  actionType: string,
  error: string
): UserCartActionType => ({
  type: UserCartActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Get Shopping List action
export const GetShoppingListAction = () => ({
  type: UserCartActionTypes.GET_USER_SHOPPING_LIST,
});


//Delete Product from cart List action
export const GetRemoveProductFromCartAction = (deleteCartDetails: any) => ({
    type: UserCartActionTypes.GET_REMOVE_PRODUCT,
    payload: deleteCartDetails
});


//Place Order action
export const PlaceOrderAction = (orderId: any) => ({
  type: UserCartActionTypes.PLACE_ORDER,
  payload: orderId
});

//Add to cart Order action
export const OrderAddToCartAction = (orderAddToCartData: any) => ({
  type: UserCartActionTypes.ORDER_ADD_TO_CART,
  payload: orderAddToCartData
});