import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import classNames from "classnames";

// components
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";

import AddCustomer from "./AddCustomer";

// dummy data
import { customers } from "./data";
import {
  actionCustomerRequest,
  fetchPendingRequestAction
} from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import Swal from "sweetalert2";
import CustomTable from "../../../../components/CustomTable";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { DateRange } from "rsuite/esm/DateRangePicker";
import moment from "moment";

/* name column render */
const NameColumn = ({ row }: { row: any }) => {
  return (
    <div className="table-user">
      <img
        src={row?.original?.profile_image ? row?.original?.profile_image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNUKbV4ZCrVOwTMkfnN10Mfhwp7BSiVb64BzDuE_lA9w&s"}

        alt=""
        className="me-2 rounded-circle"
      />
      <Link to="#" className="fw-semibold text-dark">
        {row.original.name}
      </Link>
    </div>
  );
};

/* status column render */
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <React.Fragment>
      <span
        className={classNames("badge", {
          "bg-soft-success text-success": row.original.status === "Active",
          "bg-soft-danger text-danger": row.original.status === "Blocked"
        })}
      >
        {row.original.status}
      </span>
    </React.Fragment>
  );
};

/* date column render */
function formatUnixTimestamp(unixTimestamp: any) {
  // Convert Unix timestamp to milliseconds
  const date = new Date(unixTimestamp * 1000);

  // Define month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  // Get month, day, year, hours, and minutes
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;
  // Add leading zero to minutes if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  // Construct the formatted string
  const formattedDate = `${month} ${day} ${year} ${hours}:${formattedMinutes} ${period}`;
  return formattedDate;
}

const DateColumn = ({ row }: { row: any }) => {
  const created_date = formatUnixTimestamp(row.original?.created_datetime);
  return (
    <>
      <span className="text-dark"> {created_date} </span>
    </>
  );
};

// main component
const PendingRequest = () => {
  /*
   *   modal handeling
   */
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [pendingRequestList, setPendingRequestList] = useState<any>([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const [reqBody, setReqBody] = useState<any>({
    page_record: pageSize && 10,
    page_no: pageIndex && 1,
    search: "",
    filter_by: "",
    sort_field: "created_datetime",
    sort_order: "desc",
    date_range: {}
  });

  const { pendingRequestDetails, loading, error } = useSelector(
    (state: RootState) => ({
      pendingRequestDetails: state.CustomerReducer.pendingRequestDetails,
      loading: state.CustomerReducer.loading,
      error: state.CustomerReducer.error
    })
  );

  // give page size
  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    }
    // {
    //   text: "All",
    //   value: list.length,
    // },
  ];


  //Fetch Pending Request List useEfffect
  // useEffect(() => {
  //   dispatch(fetchPendingRequestAction({}));
  // }, [dispatch]);

  useEffect(() => {
    if (
      pendingRequestDetails?.statusCode === 200 &&
      pendingRequestDetails?.data !== undefined &&
      pendingRequestDetails?.data !== null
    ) {
      setPendingRequestList(pendingRequestDetails?.data?.rows);
      setTotalRecords(pendingRequestDetails?.data?.count);
    }
  }, [pendingRequestDetails]);

  // Accept Order Functions....

  const onAcceptRequest = (data: any) => {
    const resData = {
      user_id: data.user_id,
      action: 1
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(actionCustomerRequest(resData));

          Swal.fire(
            "Request Accepted!",
            "Your Request has been taken.",
            "success"
          ).then(() => {
            dispatch(fetchPendingRequestAction({}));
          });
        } else {
          Swal.fire("Cancelled", "Your Request has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  // Rejected Order Functions....
  const onRejectRequest = (data: any) => {
    const resData = {
      user_id: data.user_id,
      action: 2
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(actionCustomerRequest(resData));

          Swal.fire(
            "Request Rejected!",
            "Your Request has been rejected.",
            "success"
          ).then(() => {
            dispatch(fetchPendingRequestAction({}));
          });
        } else {
          Swal.fire("Cancelled", "Your Request has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  /* action column render */
  const ActionColumn = ({ row }: any) => {
    return (
      <>
        <Dropdown className="btn-group" drop="start">
          <Dropdown.Toggle variant="light" className="table-action-btn btn-sm">
            <i className="mdi mdi-dots-horizontal"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="start">
            <Dropdown.Item onClick={() => onAcceptRequest(row.original)} className="d-flex align-items-center">
              <i className="mdi mdi-check me-2 text-muted font-18"></i>
              Approve
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onRejectRequest(row.original)} className="d-flex align-items-center">
              <i className="mdi mdi-delete me-2 text-muted font-18"></i>
              Reject
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sort: false,
      Cell: NameColumn,
      className: "table-user text-capitalize"
    },
    {
      Header: "Company Name",
      accessor: "wm_user_profile.company_name",
      Cell: ({ row }: any) => (
        <>
          <span className="text-capitalize">
            {row?.original?.wm_user_profile?.company_name
              ? row?.original?.wm_user_profile?.company_name
              : "-"}
          </span>
        </>
      ),
      sort: false,
      className: "table-user text-capitalize",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }: any) => (
        <>
          <span className="text-capitalize">
            {row?.original?.wm_user_profile?.phone_number
              ? row?.original?.wm_user_profile?.phone_number
              : "-"}
          </span>
        </>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      sort: false
    },
    {
      Header: "Password",
      accessor: "user_password",
      Cell: ({ row, rowIndex }: { row: any; rowIndex: number }) => {
        const [showPassword, setShowPassword] = useState(false);

        const togglePasswordVisibility = () => {
          setShowPassword(!showPassword);
        };
        const password = row.original.user_password;
        return (
          <>
            <div className="d-flex justify-content-left align-items-center">
              <span
                className={
                  showPassword ? "mdi mdi-eye-off me-2" : "mdi mdi-eye me-2"
                }
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              ></span>
              <div
                className="password-container text-truncate"
                style={{ maxWidth: "150px" }}
              >
                <span>
                  {showPassword
                    ? password
                    : "*".repeat(row.original.user_password.length)}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      Header: "Type",
      accessor: "is_crm_client",
      sort: false,
      Cell: ({ row }: any) => (
        <>
          {
            row?.original?.is_crm_client === 0 ?
              <span className="text-capitalize">
                Customer
              </span>
              : <span className="text-capitalize">
                Leads
              </span>
          }
        </>
      ),
    },
    {
      Header: "Create Date",
      accessor: "created_datetime",
      sort: false,
      Cell: DateColumn
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ActionColumn,
      sort: false
    }
  ];
  const onCloseModal = () => setShow(false);
  const onOpenModal = () => setShow(true);

  /*
    handle form submission
    */
  const onSubmit = () => {
    onCloseModal();
  };

  // Function to handle search using API call
  const searchUserRequest = async (value: string) => {
    const updatedReqBody = {
      ...reqBody,
      search: value
    };
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const sortUserRequest = async (value: any) => {
    let updatedReqBody = {
      ...reqBody,
      sort_field: value.column ?? "created_datetime",
      sort_order: value.direction ?? "desc"
    };
    if (!value.direction) {
      updatedReqBody = {
        ...reqBody,
        sort_field: "created_datetime",
        sort_order: "desc"
      };
    }
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const InvokeAPI = async (reqBody: any) => {
    try {
      dispatch(fetchPendingRequestAction(reqBody));
      const totalRecords = pendingRequestDetails?.data?.count || 0;
      const rows = pendingRequestDetails?.data?.rows || [];
      setPendingRequestList(rows);
      setTotalRecords(totalRecords);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const fetchData = async ({ pageIndex, pageSize, search }: { pageIndex: number; pageSize: number; search?: any }) => {
    const updatedReqBody = {
      ...reqBody,
      page_no: pageIndex + 1,
      page_record: pageSize,
      search: search,
    };
    setReqBody(updatedReqBody);
    setPageSize(pageSize)
    await InvokeAPI(updatedReqBody);
  };

  const filterDate = async (dates: any) => {
    // Store selected dates in ISO format in an array
    const filterValue: string[] = [];
    if (dates[0] && dates[1]) {
      const startDate = moment(dates[0]).startOf('day'); // Start of the selected date
      const endDate = moment(dates[1]).endOf('day'); // End of the selected date

      filterValue.push(startDate.unix().toString(), endDate.unix().toString());
      // filterValue.push(moment(dates[0]).unix().toString(), moment(dates[1]).unix().toString());
    }

    const updatedReqBody = {
      ...reqBody,
      date_range: {
        to_date: filterValue[0],
        from_date: filterValue[1],
      },
    };

    setReqBody(updatedReqBody);

    await InvokeAPI(updatedReqBody); // Ensure this function is defined
  };

  const ResetFilter: React.FC = () => {
    return (
      <div className="">
        <Button
          className="btn btn-danger"
          onClick={async () => {
            setSelectedDates(null)
            reqBody.date_range = {}
            // reqBody.filter_by = "0"
            await InvokeAPI(reqBody);
          }}
        // style={{ backgroundColor: "#13219D" }}
        >
          {/* <i className="mdi mdi-plus-circle me-1"></i> */}
          Reset
        </Button>
      </div>
    );
  };

  const [selectedDates, setSelectedDates] = useState<DateRange | null>(null);

  const FilterControls: React.FC = () => {
    const handleDateChange = (dates: DateRange | null) => {
      setSelectedDates(dates);
      filterDate(dates); // Your filtering function
    };

    const handleClean = () => {
      setSelectedDates(null); // Clear dates
      filterDate(null); // Your filtering function for no dates
      filterDate(reqBody);
    };

    return (
      <>
        <div className="d-flex flex-wrap align-items-center">
          <div className="bd-highlight me-2">
            <DateRangePicker
              value={selectedDates}
              onChange={handleDateChange}
              onClean={handleClean}
              format="dd-MM-yyyy" // Adjust format as needed
              style={{ width: 300 }} // Customize width
            // style={{ width: 300, backgroundColor: "#3e436c", color: "white" }}
            />
          </div>
          <div className="">
            <ResetFilter />
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Customers", path: "" },
          {
            label: "Pending Request",
            path: "/apps/crm/pendingRequest",
            active: true
          }
        ]}
        title={"New Account Requests"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body style={{ backgroundColor: "lightblue" }}>
              <Row className="justify-content-between">
                <Col className="col-auto">

                </Col>
                <Col className="col-auto">
                  <div className="text-lg-end mt-xl-0 mt-2">
                  </div>
                </Col>

                <div className="">

                  <CustomTable
                    loading={loading}
                    columns={columns}
                    data={pendingRequestList}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    pagination={true}
                    theadClass="table-light"
                    searchBoxClass="mb-2"
                    isSearchable={true}
                    isSortable={false}
                    sortFunction={sortUserRequest}
                    fetchData={fetchData}
                    sizePerPageList={sizePerPageList}
                    totalRecords={totalRecords}
                    isCustomActionButton={false}
                    isFilterConfigured={true}
                    filterControls={<FilterControls />}
                  // addButton={<AddOrderButton />}
                  />

                  {/* <CustomTable
                    columns={columns}
                    data={pendingRequestList}
                    pageSize={10}
                    pagination={true}
                    theadClass="table-light"
                    searchBoxClass="mb-2"
                    // isSearchable={true}
                    isSortable={true}
                    searchFunction={searchUserRequest}
                    sortFunction={sortUserRequest}
                  /> */}
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="align-items-center mb-2">
                <Col lg={8}></Col>
              </Row>

              <Table
                columns={columns}
                data={pendingRequestList}
                pageSize={12}
                isSortable={true}
                pagination={true}
                tableClass="table-nowrap table-striped"
              />

              <CustomTable
                columns={columns}
                data={pendingRequestList}
                pageSize={10}
                pagination={true}
                theadClass="table-light"
                searchBoxClass="mb-2"
                isSearchable={true}
                isSortable={true}
                searchFunction={searchUserRequest}
                sortFunction={sortUserRequest}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      {/* add customer modal */}
      {/* <AddCustomer
        show={show}
        onHide={onCloseModal}
        userData={{}}
        onSubmit={onSubmit}
      /> */}
    </React.Fragment>
  );
};

export default PendingRequest;
