import { UserProductActionTypes } from "./constants";

export interface UserProductActionType {
  type:
  | UserProductActionTypes.API_RESPONSE_SUCCESS
  | UserProductActionTypes.API_RESPONSE_ERROR
  | UserProductActionTypes.GET_USER_PRODUCT_LIST
  | UserProductActionTypes.GET_USER_PRODUCT_DETAILS
  | UserProductActionTypes.ADD_TO_CART;
  payload: {} | string;
}

// common success
export const userProductApiResponseSuccess = (
  actionType: string,
  data: any | {}
): UserProductActionType => ({
  type: UserProductActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const userProductApiResponseError = (
  actionType: string,
  error: string
): UserProductActionType => ({
  type: UserProductActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Get Product List action
export const GetUserProductAction = (reqBody: any) => ({
  type: UserProductActionTypes.GET_USER_PRODUCT_LIST,
  payload: reqBody,
});

//Add to cart Product action
export const AddToCartAction = (addToCartData: any) => ({
  type: UserProductActionTypes.ADD_TO_CART,
  payload: addToCartData
});

//fetch user product details
export const GetUserProductDetailsAction = (product_id: any) => ({
  type: UserProductActionTypes.GET_USER_PRODUCT_DETAILS,
  payload: { product_id },
});
