import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  userProductApiResponseError,
  userProductApiResponseSuccess,
} from "./actions";
import { UserProductActionTypes } from "./constants";
import {
  AddToCartApi,
  FetchUserProduct,
  GetUserProductDetails,
} from "../../../helpers/api/UserApi/UserProducts";
import notify from "../../../components/Notification/notify";

// Fetch User Profile ......

function* GetUserProductList({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      filter_by: payload.filter_by ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(FetchUserProduct, { data });
    const _payload: any = response.data;
    yield put(
      userProductApiResponseSuccess(
        UserProductActionTypes.GET_USER_PRODUCT_LIST,
        _payload
      )
    );
  } catch (error: any) {
    yield put(
      userProductApiResponseError(
        UserProductActionTypes.GET_USER_PRODUCT_LIST,
        error
      )
    );
  }
}

export function* watchUserProductList() {
  yield takeEvery(
    UserProductActionTypes.GET_USER_PRODUCT_LIST,
    GetUserProductList
  );
}

// Add to Cart Product
function* AddToCartSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(AddToCartApi, { data });
    const payload: any = response.data;
    yield put(
      userProductApiResponseSuccess(UserProductActionTypes.ADD_TO_CART, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      userProductApiResponseError(UserProductActionTypes.ADD_TO_CART, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddToCart() {
  yield takeEvery(UserProductActionTypes.ADD_TO_CART, AddToCartSaga);
}

// ? fetch product details saga
function* GetUserProductDetailsSaga(action: any): SagaIterator {
  try {
    let { product_id } = action.payload;
    const response = yield call(GetUserProductDetails, product_id);
    const productData: any = response.data;
    yield put(
      userProductApiResponseSuccess(
        UserProductActionTypes.GET_USER_PRODUCT_DETAILS,
        productData
      )
    );
  } catch (error: any) {
    yield put(
      userProductApiResponseError(
        UserProductActionTypes.GET_USER_PRODUCT_DETAILS,
        error
      )
    );
  }
}

export function* watchGetUserProductDetailsSaga() {
  yield takeEvery(
    UserProductActionTypes.GET_USER_PRODUCT_DETAILS,
    GetUserProductDetailsSaga
  );
}

function* UserProductSaga() {
  yield all([
    fork(watchUserProductList),
    fork(watchAddToCart),
    fork(watchGetUserProductDetailsSaga),
  ]);
}

export default UserProductSaga;
