import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";
import { resetPassword_API, verifyOTP_API } from "../../helpers/api/auth";
import notify from "../../components/Notification/notify";

interface UserData {
  payload: {
    username: string;
    password: string;
    name: string;
    email: string;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: data,
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { data });
    response.user_role = 0
    const user = response.data;
    // NOTE - You can change this according to response format from your api
    api.setLoggedInUser({ ...user?.data, token: user?.data?.access_token });
    // api.setLoggedInUser({token:user?.data?.access_token, user_id:user?.data?.user_id, role: user?.data?.user_role, profile_image: user?.data?.profile_image, name:user?.data?.name });

    // api.setLoggedInUser(user);
    setAuthorization(user?.data["access_token"]);
    // setAuthorization(user["access_token"]);

    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, { ...user?.data, token: user?.data?.access_token }));
    // yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, {user}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout({ payload: data }: any): SagaIterator {
  try {
    let dataRes = {
      'access_token': data
    }
    yield call(logoutApi, { dataRes });
    sessionStorage.removeItem('admin_permissions')
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { name, email, password },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { name, email, password });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
    yield call(notify, `${response.data.message}`, "success");
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    yield call(notify, `${error.message}`, "error");
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* verifyOTP({ payload }: any): SagaIterator {
  try {
    const response = yield call(verifyOTP_API, payload);
    yield put(
      authApiResponseSuccess(AuthActionTypes.VERIFY_OTP, response.data)
    );
    yield call(notify, `${response.data.message}`, "success");
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.VERIFY_OTP, error));
    yield call(notify, `${error.message}`, "error");
  }
}
export function* watchVerifyOTP(): any {
  yield takeEvery(AuthActionTypes.VERIFY_OTP, verifyOTP);
}

function* resetPassword({ payload }: any): SagaIterator {
  try {
    const response = yield call(resetPassword_API, payload);
    yield put(
      authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD, response.data)
    );
    yield call(notify, `${response.data.message}`, "success");
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD, error));
    yield call(notify, `${error.message}`, "error");
  }
}
export function* watchResetPassword(): any {
  yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchVerifyOTP),
    fork(watchResetPassword),
  ]);
}

export default authSaga;
