import { CustomerActionTypes } from "./constants";

export interface CustomerActionType {
  type:
    | CustomerActionTypes.API_RESPONSE_SUCCESS
    | CustomerActionTypes.API_RESPONSE_ERROR
    | CustomerActionTypes.FETCH_CUSTOMER_REQUEST
    | CustomerActionTypes.FETCH_PENDING_REQUEST
    | CustomerActionTypes.CHANGE_STATUS
    | CustomerActionTypes.UPDATE_USER_STATUS
    | CustomerActionTypes.DELETE_CUSTOMER;
  payload: {} | string;
}

// common success
export const customerApiResponseSuccess = (
  actionType: string,
  data: any | {}
): CustomerActionType => ({
  type: CustomerActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const customerApiResponseError = (
  actionType: string,
  error: string
): CustomerActionType => ({
  type: CustomerActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Fetch request SubAdmin List
export const fetchCustomerAction = (reqBody: any) => ({
  type: CustomerActionTypes.FETCH_CUSTOMER_REQUEST,
  payload: reqBody,
});

//Fetch request SubAdmin List
export const fetchClientCustomerAction = (reqBody: any) => ({
  type: CustomerActionTypes.FETCH_CLIENT_CUSTOMER,
  payload: reqBody,
});

//Fetch request pending List
export const fetchPendingRequestAction = (reqBody: any) => ({
  type: CustomerActionTypes.FETCH_PENDING_REQUEST,
  payload: reqBody,
});

//Action request Customers List
export const actionCustomerRequest = (actionRequestData: any) => ({
  type: CustomerActionTypes.ACTION_PENDING_REQUEST,
  payload: actionRequestData,
});

//Change status for Customers request
export const ChangeCustomerStatus = (changeCustomerDetails: any) => ({
  type: CustomerActionTypes.CHANGE_STATUS,
  payload: changeCustomerDetails,
});

//Update User status
export const UpdateUserStatus = (user_status: any) => ({
  type: CustomerActionTypes.UPDATE_USER_STATUS,
  payload: user_status,
});

//Update User
export const AddNewCustomer = (userData: any) => ({
  type: CustomerActionTypes.ADD_NEW_CUSTOMER,
  payload: userData,
});

//Update User
export const UpdateCustomer = (userData: any) => ({
  type: CustomerActionTypes.UPDATE_CUSTOMER,
  payload: userData,
});

export const DeleteCustomerAction = (user_id: any) => ({
  type: CustomerActionTypes.DELETE_CUSTOMER,
  payload: { user_id },
});
