import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Admin Profile
function fetchUserProfile() {
  const baseUrl = config.API_URL + "/fetchUserProfile";
  return api.create(baseUrl, {});
}

//Editing Admin PRofile
function editUserProfile(params: any) {
  const baseUrl = config.API_URL + `/updateUserProfile`;
  return api.update(baseUrl, { ...params?.data });
}

// Listing Admin Profile
function updateUserAvatarAPI(data: any) {
  const baseUrl = config.API_URL + "/updateAvatar";
  return api.createWithFile(baseUrl, data);
}

export {
  fetchUserProfile,
  editUserProfile,
  updateUserAvatarAPI
};
