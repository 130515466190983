export enum OrdersActionTypes {
    API_RESPONSE_SUCCESS = "@@orders/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@orders/API_RESPONSE_ERROR",

    FETCH_ORDER_REQUEST = "@@orders/FETCH_ORDER_REQUEST",
    FETCH_NEW_ORDER_REQUEST = "@@orders/FETCH_NEW_ORDER_REQUEST",
    ACTIONS_ORDER_REQUEST = "@@orders/ACTIONS_ORDER_REQUEST",
    FETCH_ORDER_DETAILS = "@@orders/FETCH_ORDER_DETAILS",
    UPDATE_ORDER_STATUS = "@@orders/UPDATE_ORDER_STATUS",
    ADD_ORDER = "@@orders/ADD_ORDER",
    DELETE_ORDER = "@@orders/DELETE_ORDER",
    ORDER_PRODUCT_LIST = "@@orders/ORDER_PRODUCT_LIST",
    LIST_CUSTOMERS_ORDER = "@@orders/LIST_CUSTOMERS_ORDER",
    ADD_CUSTOMER_ORDER = "@@orders/ADD_CUSTOMER_ORDER",
}
