import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Dropdown, Tab, Modal } from "react-bootstrap";
import classNames from "classnames";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

// dummy data
import { orders, OrdersItemTypes } from "../Ecommerce/data";
import AddSubAdminTask from "./addClientTask";

// dummy data
import { customers } from "../Ecommerce/data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  customerListAction,
  DeleteSubAdminAction,
  deleteTaskAction,
  editStatusAction,
  fetchClientTaskAction,
  fetchCustomerAction,
  fetchPermissionListAction,
  fetchSubAdminAction,
  fetchTaskAction,
} from "../../../redux/actions";
import notify from "../../../components/Notification/notify";
import NotificationContainer from "../../../components/Notification/NotificationContainer";
import Swal from "sweetalert2";
import ShowSubAdminTask from "./ClientTaskDetailsModal";
import CustomTable from "../../../components/CustomTable";
import AddClientTask from "./addClientTask";
import ShowClientTask from "./ClientTaskDetailsModal";
import { Formik } from "formik";
import { TASK_STATUS } from "../../../constants/status";

const TaskStatus = [
  {
    status: 1,
    name: "Pending",
    class: "success",
  },
  {
    status: 0,
    name: "Finished",
    class: "danger",
  },
];

/* name column render */
const NameColumn = ({ row }: { row: any }) => {
  return (
    <div className="table-user">
      <img src={row.original.avatar} alt="" className="me-2 rounded-circle" />
      <Link to="#" className="text-body fw-semibold">
        {row.original.name}
      </Link>
    </div>
  );
};

/* last order column render */
const LastOrderColumn = ({ row }: { row: any }) => {
  return (
    <>
      {row.original.last_order.date}{" "}
      <small className="text-muted">{row.original.last_order.time}</small>
    </>
  );
};

// main component
const ClientTask = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [customerList, setCustomerList] = useState<any>([]);
  const [list, setList] = useState<any>([]);
  const [task, setTask] = useState<any>({});
  const [taskClientList, setTaskClientList] = useState<any>([]);
  const [taskStatuses, setTaskStatuses] = useState<any>({});

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState<any>({});

  const [isSubAdmin, setIsSubAdmin] = useState<boolean>(false);
  useEffect(() => {
    let userData: any = sessionStorage.getItem("session_user_details");
    userData = JSON.parse(userData);
    if (userData?.user_role === 2) {
      setIsSubAdmin(true);
    }
  }, [isSubAdmin]);

  const [reqBody, setReqBody] = useState<any>({
    page_record: pageSize && 10,
    page_no: pageIndex && 1,
    search: "",
    filter_by: "",
    sort_field: "created_datetime",
    sort_order: "desc",
  });

  const {
    loading,
    error,
    is_add,
    is_deleted,
    is_edit,
    addSuccessMessage,
    editSuccessMessage,
    editStatusMessage,
    isStatus,
    CustomerDetails,
    clientTaskDetails,
    TaskStatuserror
  } = useSelector((state: RootState) => ({
    loading: state.TaskReducer.loading,
    error: state.TaskReducer.error,
    is_add: state.TaskReducer.is_add,
    is_deleted: state.TaskReducer.is_deleted,
    is_edit: state.TaskReducer.is_edit,
    addSuccessMessage: state.TaskReducer.addSuccessMessage,
    editSuccessMessage: state.TaskReducer.editSuccessMessage,
    editStatusMessage: state.TaskReducer.editStatusMessage,
    isStatus: state.TaskReducer.isStatus,
    CustomerDetails: state.TaskReducer.CustomerDetails,
    clientTaskDetails: state.TaskReducer.clientTaskDetails,
    TaskStatuserror: state.TaskReducer.TaskStatuserror,
  }));

  //Fetch subAdmin List useEfffect
  useEffect(() => {
    dispatch(customerListAction({}));
    // dispatch(fetchClientTaskAction({}));
  }, [dispatch]);

  const onCloseModal = () => {
    setShow(false);
    setShowDetails(false);
    setTask({});
  };
  const onOpenModal = () => setShow(true);

  const onOpenEditModal = (row: any) => {
    setShow(true);
    setTask(row);
  };

  const onHandleSubmit = (formdata: any) => {
    onCloseModal();
  };

  useEffect(() => {
    if (
      CustomerDetails?.statusCode === 200 &&
      CustomerDetails?.data !== undefined
    ) {
      setCustomerList(CustomerDetails?.data);
    }
  }, [CustomerDetails]);

  useEffect(() => {
    if (
      clientTaskDetails?.statusCode === 200 &&
      clientTaskDetails?.data?.rows !== undefined
    ) {
      setTaskClientList(clientTaskDetails?.data?.rows);
      setTotalRecords(clientTaskDetails?.data?.count);
    }
  }, [clientTaskDetails]);

  useEffect(() => {
    if (
      is_add === false &&
      addSuccessMessage !== undefined &&
      addSuccessMessage !== null
    ) {
      onCloseModal();
      dispatch(fetchClientTaskAction({}));
    }
  }, [is_add, addSuccessMessage, dispatch]);

  useEffect(() => {
    if (
      is_edit === false &&
      editSuccessMessage !== undefined &&
      editSuccessMessage !== null
    ) {
      onCloseModal();
      dispatch(fetchClientTaskAction({}));
    }
  }, [is_edit, editSuccessMessage, dispatch]);

  // useEffect(() => {
  //   if (is_deleted === false) {
  //     onCloseModal();
  //     dispatch(fetchClientTaskAction({}));
  //   }
  // }, [is_deleted, dispatch]);

  // give page size
  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    }
    // {
    //   text: "All",
    //   value: list.length,
    // },
  ];

  const handleEdit = (id: any) => {
    console.log("Enter Handle Edit", id);
  };

  const handleDelete = (data: any) => {
    const id: any = data?.task_id;
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Dispatch the delete action
          dispatch(deleteTaskAction(id));
          // Show success message after dispatching action
          Swal.fire("Deleted!", "Your item has been deleted.", "success").then(
            () => {
              // Dispatch fetch_data action after success message is shown
              dispatch(fetchClientTaskAction({}));
            }
          );
        } else {
          Swal.fire("Cancelled", "Your action has been cancelled :)", "info");
        }
      });
      // dispatch(fetchClientTaskAction({}));
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const onOpenShowModal = (row: any) => {
    setShowDetails(true);
    setTask(row);
  };

  // ? Changes product status...
  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    taskId: any
  ) => {
    const selectedStatus = parseInt(e.target.value);
    const data = {
      task_id: taskId,
      status: selectedStatus,
    };
    dispatch(editStatusAction(data));
    setStatus((prevStatuses: any) => ({
      [taskId]: selectedStatus,
    }))
  };

  // useEffect(() => {
  //   if (TaskStatuserror?.statusCode === 400) {
  //     notify(`${TaskStatuserror.message}`, "error");
  //     setTaskStatuses((prevStatuses: any) => ({
  //       ...prevStatuses,
  //     }));
  //   }
  // }, [TaskStatuserror])

  useEffect(() => {
    if (editStatusMessage) {
      // notify(`${editStatusMessage.message}`, "success");
      setTaskStatuses((prevStatuses: any) => ({
        ...status,
      }));
    }
  }, [editStatusMessage])

  
  useEffect(() => {
    if (editStatusMessage?.statusCode === 200) {
      dispatch(fetchClientTaskAction(reqBody));
    }
  }, [editStatusMessage])

  /* status column render */
  const StatusColumn = ({ row, rowIndex }: { row: any; rowIndex: number }) => {
    return (
      <Formik
        initialValues={{ status: row.original.status }}
        onSubmit={() => { }}
      >
        {({ values }) => (
          <select
            className="form-select bg-transparent text-dark"
            id={`status_${rowIndex}`}
            name={`status_${rowIndex}`}
            value={taskStatuses[row.original?.task_id] || row.original?.status}
            onChange={(e) => handleChange(e, row.original.task_id)}
          >
            {TASK_STATUS.length > 0 &&
              TASK_STATUS.map((item: any) => (
                <option key={item.status} value={item.status}>
                  {item.name}
                </option>
              ))}
          </select>
        )}
      </Formik>
    );
  };



  // old name column code
  // const NameColumn = ({ row }: { row: any }) => {
  //   const imageUrl = "https://i.pravatar.cc/300";
  //   const list = row.original.customer_list;
  //   const matchedCustomers = customerList.filter((customer: any) =>
  //     list.includes(customer.user_id)
  //   );

  //   // State to track popup visibility for each customer
  //   const [popupVisibility, setPopupVisibility] = useState<{
  //     [key: string]: boolean;
  //   }>({});

  //   const handleMouseEnter = (customerId: string) => {
  //     setPopupVisibility((prevState) => ({
  //       ...prevState,
  //       [customerId]: true,
  //     }));
  //   };

  //   const handleMouseLeave = (customerId: string) => {
  //     setPopupVisibility((prevState) => ({
  //       ...prevState,
  //       [customerId]: false,
  //     }));
  //   };

  //   return (
  //     <div className="table-user text-dark">
  //       {matchedCustomers.map((customer: any) => (
  //         <div
  //           key={customer.user_id}
  //           className="rounded-pill bg-light d-inline-flex align-items-center px-2 py-1 me-2 mb-2"
  //           onMouseEnter={() => handleMouseEnter(customer.user_id)}
  //           onMouseLeave={() => handleMouseLeave(customer.user_id)}
  //         >
  //           <img
  //             src={customer?.profile_image ? customer?.profile_image : imageUrl}
  //             alt={customer.name}
  //             className="me-1 rounded-circle"
  //             style={{ width: 25, height: 25 }}
  //           />
  //           <span>{customer.name}</span>
  //           {popupVisibility[customer.user_id] && (
  //             <div
  //               className="popup"
  //               style={{
  //                 position: "absolute",
  //                 backgroundColor: "white",
  //                 border: "1px solid #ccc",
  //                 padding: "0px",
  //                 borderRadius: "5px",
  //                 boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  //                 zIndex: "999",
  //                 width: "350px",
  //               }}
  //             >
  //               <Card className="text-center">
  //                 <Card.Body>
  //                   <img
  //                     src={imageUrl}
  //                     className="rounded-circle avatar-xxl img-thumbnail"
  //                     alt=""
  //                     style={{ width: 100, height: 100 }}
  //                   />
  //                   <h4 className="mb-0 text-capitalize">{customer?.name}</h4>
  //                   <p className="text-muted">{customer?.name}@mailsac.com</p>
  //                   <hr />
  //                   <div className="text-start mt-3">
  //                     <p className="text-muted mb-2 font-13">
  //                       <strong>Full Name :</strong>
  //                       <span className="ms-2 text-capitalize">
  //                         {customer?.name}
  //                       </span>
  //                     </p>

  //                     <p className="text-muted mb-2 font-13">
  //                       <strong>Mobile :</strong>
  //                       <span className="ms-2">
  //                         {/* {userDetails?.wm_user_profile?.phone_number} */}
  //                         1234567890
  //                       </span>
  //                     </p>

  //                     <p className="text-muted mb-1 font-13">
  //                       <strong>Location :</strong>
  //                       <span className="ms-2 text-capitalize">
  //                         {/* {userDetails?.wm_user_profile?.country} */}
  //                         INDIA
  //                       </span>
  //                     </p>
  //                   </div>
  //                 </Card.Body>
  //               </Card>
  //             </div>
  //           )}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  const NameColumn = ({ row }: any) => {
    const imageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNUKbV4ZCrVOwTMkfnN10Mfhwp7BSiVb64BzDuE_lA9w&s"
    //  "https://i.pravatar.cc/300";
    const list = row.original.customer_list;
    const matchedCustomers =
      customerList &&
      customerList.filter((customer: any) => list.includes(customer.user_id));

    const [popupVisibility, setPopupVisibility] = useState<{
      [key: string]: boolean;
    }>({});
    const [popupModalVisibility, setPopupModalVisibility] = useState<{
      [key: string]: boolean;
    }>({});
    const [modalVisible, setModalVisible] = useState(false);

    const handleMouseEnter = (customerId: string) => {
      setPopupVisibility((prevState) => ({
        ...prevState,
        [customerId]: true,
      }));
    };

    const handleMouseLeave = (customerId: string) => {
      setPopupVisibility((prevState) => ({
        ...prevState,
        [customerId]: false,
      }));
    };


    //Modal in popup
    const handleModalMouseEnter = (customerId: string) => {
      setPopupModalVisibility((prevState) => ({
        ...prevState,
        [customerId]: true,
      }));
    };

    const handleModalMouseLeave = (customerId: string) => {
      setPopupModalVisibility((prevState) => ({
        ...prevState,
        [customerId]: false,
      }));
    };

    const handleMoreClick = () => {
      setModalVisible(true);
    };

    const handleCloseModal = () => {
      setModalVisible(false);
    };

    // Number of customers to show by default
    const defaultVisibleCount = 3;
    const visibleCustomers = matchedCustomers.slice(0, defaultVisibleCount);

    return (
      <div className="d-flex justify-content-left align-items-center">
        <div className="d-flex align-items-center table-user text-dark">
          {visibleCustomers.map((customer: any) => (
            <div
              key={customer.user_id}
              className="rounded-pill bg-light d-inline-flex align-items-center px-2 py-1 me-2 mb-2"
              onMouseEnter={() => handleMouseEnter(customer.user_id)}
              onMouseLeave={() => handleMouseLeave(customer.user_id)}
            >
              <img
                src={
                  customer?.profile_image ? customer?.profile_image : imageUrl
                }
                alt={customer.name}
                className="me-1 rounded-circle"
                style={{ width: 25, height: 25 }}
              />
              <span>{customer.name}</span>
              {popupVisibility[customer.user_id] && (
                <div
                  className="popup"
                  style={{
                    position: "absolute",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    padding: "0px",
                    borderRadius: "5px",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    zIndex: "999",
                    width: "350px",
                  }}
                >
                  <Card className="text-center">
                    <Card.Body>
                      <img
                        src={
                          customer?.profile_image
                            ? customer?.profile_image
                            : imageUrl
                        }
                        className="rounded-circle avatar-xxl img-thumbnail"
                        alt=""
                        style={{ width: 100, height: 100 }}
                      />
                      <h4 className="mb-0 text-capitalize">{customer?.name}</h4>
                      <p className="text-muted">{customer?.name}@mailsac.com</p>
                      <hr />
                      <div className="text-start mt-3">
                        <p className="text-muted mb-2 font-13">
                          <strong>Full Name :</strong>
                          <span className="ms-2 text-capitalize">
                            {customer?.name}
                          </span>
                        </p>

                        <p className="text-muted mb-2 font-13">
                          <strong>Mobile :</strong>
                          <span className="ms-2">
                            {/* {userDetails?.wm_user_profile?.phone_number} */}
                            1234567890
                          </span>
                        </p>

                        <p className="text-muted mb-1 font-13">
                          <strong>Location :</strong>
                          <span className="ms-2 text-capitalize">
                            {/* {userDetails?.wm_user_profile?.country} */}
                            INDIA
                          </span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
          ))}
          {matchedCustomers.length > defaultVisibleCount && (
            <Card
              className="rounded-pill bg-light d-inline-flex align-items-center px-2 py-1 me-2 mb-2 text-center"
              onClick={handleMoreClick}
              style={{ cursor: "pointer" }}
            >
              <Card.Body className="p-0">
                <div>
                  {`+ ${matchedCustomers.length - defaultVisibleCount}`}
                </div>

              </Card.Body>
            </Card>
          )}
        </div>

        {/* Modal for displaying all customers */}
        <Modal show={modalVisible} onHide={handleCloseModal} size="lg" centered>
          <Modal.Header closeButton className="px-2 py-1">
            <Modal.Title>All Customers</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-user text-dark">
              {matchedCustomers.map((customer: any) => (
                <div
                  key={customer.user_id}
                  className="rounded-pill bg-light d-inline-flex align-items-center px-2 py-1 me-2 mb-2"
                  onMouseEnter={() => handleModalMouseEnter(customer.user_id)}
                  onMouseLeave={() => handleModalMouseLeave(customer.user_id)}
                >
                  <img
                    src={
                      customer?.profile_image
                        ? customer?.profile_image
                        : imageUrl
                    }
                    alt={customer.name}
                    className="me-1 rounded-circle"
                    style={{ width: 25, height: 25 }}
                  />
                  <span>{customer.name}</span>
                  {popupModalVisibility[customer.user_id] && (
                    <div
                      className="popup"
                      style={{
                        position: "absolute",
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        padding: "0px",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        zIndex: "999",
                        width: "350px",
                      }}
                    >
                      <Card className="text-center">
                        <Card.Body>
                          <img
                            src={
                              customer?.profile_image
                                ? customer?.profile_image
                                : imageUrl
                            }
                            className="rounded-circle avatar-xxl img-thumbnail"
                            alt=""
                            style={{ width: 100, height: 100 }}
                          />
                          <h4 className="mb-0 text-capitalize">
                            {customer?.name}
                          </h4>
                          <p className="text-muted">
                            {customer?.name}@mailsac.com
                          </p>
                          <hr />
                          <div className="text-start mt-3">
                            <p className="text-muted mb-2 font-13">
                              <strong>Full Name :</strong>
                              <span className="ms-2 text-capitalize">
                                {customer?.name}
                              </span>
                            </p>

                            <p className="text-muted mb-2 font-13">
                              <strong>Mobile :</strong>
                              <span className="ms-2">
                                {/* {userDetails?.wm_user_profile?.phone_number} */}
                                1234567890
                              </span>
                            </p>

                            <p className="text-muted mb-1 font-13">
                              <strong>Location :</strong>
                              <span className="ms-2 text-capitalize">
                                {/* {userDetails?.wm_user_profile?.country} */}
                                INDIA
                              </span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div >
    );
  };

  const columns = [
    // {
    //   Header: "ID",
    //   accessor: "task_id",
    //   classes: "table-user",
    //   sort: false
    // },

    {
      Header: "Title",
      accessor: "title",
      sort: false,
      classes: "table-user",
    },
    {
      Header: "Task Description",
      accessor: "task_description",
      sort: false,
    },
    {
      Header: "Customer",
      accessor: "customer_list",
      Cell: NameColumn,
      sort: false,
    },
    {
      Header: "To Assign",
      accessor: "name",
      Cell: ({ row }: any) => (
        <>
          {row.original.wm_user?.name ? (
            <>{row?.original?.wm_user?.name}</>
          ) : (
            <>Me</>
          )}
        </>
      ),
      sort: false,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: StatusColumn,
      style: { minWidth: "150px" },
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      style: { minWidth: "150px" },
      sort: false,
      Cell: ({ row }: any) => (
        <>
          {!isSubAdmin ? (
            <>
              <Link
                to=""
                className="action-icon text-dark"
                onClick={() => onOpenShowModal(row.original)}
              >
                <i className="mdi mdi-eye"></i>
              </Link>
              <Link
                to=""
                className="action-icon text-dark"
                onClick={() => onOpenEditModal(row.original)}
              >
                <i className="mdi mdi-square-edit-outline"></i>
              </Link>
              <Link
                to=""
                className="action-icon text-dark"
                onClick={() => handleDelete(row.original)}
              >
                <i className="mdi mdi-delete"></i>
              </Link>
            </>
          ) : (
            <Link
              to=""
              className="action-icon text-dark"
              onClick={() => onOpenShowModal(row.original)}
            >
              <i className="mdi mdi-eye"></i>
            </Link>
          )}
        </>
      ),
    },
  ];


  // Function to handle search using API call
  const searchTasks = async (value: string) => {
    const updatedReqBody = {
      ...reqBody,
      search: value,
    };
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const sortTasks = async (value: any) => {
    let updatedReqBody = {
      ...reqBody,
      sort_field: value.column ?? "created_datetime",
      sort_order: value.direction ?? "desc",
    };
    if (!value.direction) {
      updatedReqBody = {
        ...reqBody,
        sort_field: "created_datetime",
        sort_order: "desc",
      };
    }
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  const InvokeAPI = async (reqBody: any) => {
    try {
      dispatch(fetchClientTaskAction(reqBody));
      const totalRecords = clientTaskDetails?.data?.count || 0; // Total number of records
      const rows = clientTaskDetails?.data?.rows || []; // Current page records
      setTaskClientList(rows);
      setTotalRecords(totalRecords); // Store total record count
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const fetchData = async ({ pageIndex, pageSize, search }: { pageIndex: number; pageSize: number; search?: any }) => {
    const updatedReqBody = {
      ...reqBody,
      page_no: pageIndex + 1,
      page_record: pageSize,
      search: search,
    };
    setReqBody(updatedReqBody);
    setPageSize(pageSize)
    await InvokeAPI(updatedReqBody);
  };

  const AddClientTaskButton = () => (
    <div className="text-lg-end mt-xl-0 mt-2">
      {!isSubAdmin ? (
        <Button
          className="btn btn-primary mb-2"
          onClick={onOpenModal}
          style={{ backgroundColor: "#13219D" }}
        >
          <i className="mdi mdi-plus-circle me-1"></i> Add Client
          Task
        </Button>
      ) : (
        ""
      )}
    </div>
  )

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tasks", path: "" },
          { label: "Client Tasks", path: "/apps/clientTask", active: true },
        ]}
        title={"Client Tasks"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body style={{ backgroundColor: "lightblue" }}>
              <Row className="justify-content-between">
                {/* <Col className="col-auto">
                  <form className="d-flex flex-wrap align-items-center">
                    <label htmlFor="inputPassword2" className="visually-hidden">
                      Search
                    </label>
                    <div className="me-3 position-relative">
                      <input
                        type="search"
                        className="form-control my-1 my-lg-0 rounded ps-4 search-input" // Adding padding to accommodate icon
                        id="inputPassword2"
                        placeholder="Search..."
                        onChange={(e) => searchTasks(e.target.value)}
                        style={{ backgroundColor: "#3e436c", color: "white" }}
                      />
                      <div className="position-absolute top-50 start-0 translate-middle-y ms-2">
                        <i className="bi bi-search text-white"></i>
                      </div>
                    </div>
                  </form>
                </Col>
                <Col className="col-auto">
                  <div className="text-lg-end mt-xl-0 mt-2">
                    {!isSubAdmin ? (
                      <Button
                        className="btn btn-primary mb-2 me-2"
                        onClick={onOpenModal}
                        style={{ backgroundColor: "#13219D" }}
                      >
                        <i className="mdi mdi-plus-circle me-1"></i> Add Client
                        Task
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col> */}

                <div className="">

                  {/* {
                    taskClientList &&
                    <CustomTable
                      columns={columns}
                      data={taskClientList}
                      pageSize={pageSize}
                      pagination={true}
                      theadClass="table-light"
                      searchBoxClass="mb-2"
                      isSearchable={false}
                      isSortable={true}
                      searchFunction={searchTasks}
                      sortFunction={sortTasks}
                      fetchData={fetchData}
                      sizePerPageList={sizePerPageList}
                      totalRecords={totalRecords}
                    />
                  } */}
                  {
                    <CustomTable
                      loading={loading}
                      columns={columns}
                      data={taskClientList}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      pagination={true}
                      theadClass="table-light"
                      searchBoxClass="mb-2"
                      isSearchable={true}
                      isSortable={false}
                      sortFunction={sortTasks}
                      fetchData={fetchData}
                      sizePerPageList={sizePerPageList}
                      totalRecords={totalRecords}
                      isCustomActionButton={true}
                      addButton={<AddClientTaskButton />}
                    />
                  }

                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={8}></Col>

                <Col lg={4}>
                  <div className="text-lg-end mt-xl-0 mt-2">
                    {!isSubAdmin ? (
                    <Button
                      className="btn btn-primary mb-2 me-2"
                      onClick={onOpenModal}
                    >
                      <i className="mdi mdi-basket me-1"></i> Add Client Task
                    </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>

              <CustomTable
                columns={columns}
                data={taskClientList}
                pageSize={10}
                pagination={true}
                theadClass="table-light"
                searchBoxClass="mb-2"
                isSearchable={true}
                isSortable={true}
                searchFunction={searchTasks}
                sortFunction={sortTasks}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
      {show && (
        <AddClientTask
          show={show}
          task={task}
          onHide={onCloseModal}
          onSubmit={onHandleSubmit}
          customerList={customerList}
        />
      )}

      {showDetails && (
        <ShowClientTask
          show={showDetails}
          task={task}
          onHide={onCloseModal}
          onSubmit={onHandleSubmit}
          customerList={customerList}
        />
      )}
    </>
  );
};

export default ClientTask;
