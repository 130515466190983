import { OrdersActionTypes } from "./constants";

export interface OrdersActionType {
  type:
  | OrdersActionTypes.API_RESPONSE_SUCCESS
  | OrdersActionTypes.API_RESPONSE_ERROR
  | OrdersActionTypes.FETCH_ORDER_REQUEST
  | OrdersActionTypes.FETCH_NEW_ORDER_REQUEST
  | OrdersActionTypes.FETCH_ORDER_DETAILS
  | OrdersActionTypes.UPDATE_ORDER_STATUS
  | OrdersActionTypes.ADD_ORDER
  | OrdersActionTypes.ORDER_PRODUCT_LIST
  | OrdersActionTypes.LIST_CUSTOMERS_ORDER;
  payload: {} | string;
}

interface SubAdminData {
  id: number;
  email: string;
}

// common success
export const ordersApiResponseSuccess = (
  actionType: string,
  data: SubAdminData | {}
): OrdersActionType => ({
  type: OrdersActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const ordersApiResponseError = (
  actionType: string,
  error: string
): OrdersActionType => ({
  type: OrdersActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Fetch Orders List
export const fetchOrdersAction = (reqBody: any) => ({
  type: OrdersActionTypes.FETCH_ORDER_REQUEST,
  payload: reqBody,
});

//Fetch New Order Request List
export const fetchNewOrdersRequestAction = (reqBody: any) => ({
  type: OrdersActionTypes.FETCH_NEW_ORDER_REQUEST,
  payload: reqBody,
});

//Fetch Action Order Request List
export const orderAction = (actionOrderData: any) => ({
  type: OrdersActionTypes.ACTIONS_ORDER_REQUEST,
  payload: actionOrderData,
});

//fetch Order details
export const fetchOrderDetailsAction = (order_id: any) => ({
  type: OrdersActionTypes.FETCH_ORDER_DETAILS,
  payload: { order_id },
});

//Update Order status
export const UpdateOrderStatus = (order_status: any) => ({
  type: OrdersActionTypes.UPDATE_ORDER_STATUS,
  payload: order_status,
});


//Add Order qty
export const AddOrderQty = (orderQty: any) => ({
  type: OrdersActionTypes.ADD_ORDER,
  payload: orderQty,
});

//Delete Order 
export const DeleteOrder = (orderQty: any) => ({
  type: OrdersActionTypes.DELETE_ORDER,
  payload: orderQty,
});

//Fetch Orders Product List
export const fetchOrderProductListAction = (reqBody: any) => ({
  type: OrdersActionTypes.ORDER_PRODUCT_LIST,
  payload: reqBody,
});

//Fetch Orders Customer List
export const listCustomersOrderAction = (reqBody: any) => ({
  type: OrdersActionTypes.LIST_CUSTOMERS_ORDER,
  payload: reqBody,
});

//Add Customer Order 
export const AddCustomerOrderAction = (reqBody: any) => ({
  type: OrdersActionTypes.ADD_CUSTOMER_ORDER,
  payload: reqBody,
})