import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Permission
function fetchCustomer(params: any) {
  const baseUrl = config.API_URL + "/admin/listAllCustomers";
  return api.create(baseUrl, { ...params?.data });
}

// Listing Permission
function fetchClientCustomer(params: any) {
  const baseUrl = config.API_URL + "/admin/listAllClientCustomers";
  return api.create(baseUrl, { ...params?.data });
}

// Listing Permission
function fetchPending(params: any) {
  const baseUrl = config.API_URL + "/admin/listAllPendingRequests";
  return api.create(baseUrl, { ...params?.data });
}

// Action Pending Request
function actionPendingRequest(params: any) {
  const baseUrl = config.API_URL + "/admin/requestAction";
  return api.update(baseUrl, { ...params?.data });
}

// change status for customer api
function changeCustomerStatus(params: any) {
  const dataRes = {
    status: params?.data?.status,
  };
  const baseUrl =
    config.API_URL + `/admin/changeUserStatus/${params?.data?.user_id}`;
  return api.updateTask(baseUrl, { dataRes });
}

// Update Order status
function UpdateUserStatusApi(params: any) {
  const baseUrl = config.API_URL + `/admin/changeUserStatus`;
  return api.create(baseUrl, { ...params?.data });
}

// Update Order status
function addNewCustomerAPI(params: any) {
  const baseUrl = config.API_URL + `/admin/addNewCustomer`;
  return api.create(baseUrl, { ...params?.data });
}

// Update Order status
function updateCustomerAPI(params: any) {
  const baseUrl = config.API_URL + `/admin/updateCustomer`;
  return api.create(baseUrl, { ...params?.data });
}

// ? fetch customer
function deleteCustomer(user_id: any) {
  const baseUrl = config.API_URL + `/admin/deleteCustomer/${user_id}`;
  return api.delete(baseUrl);
}
export {
  fetchCustomer,
  fetchPending,
  actionPendingRequest,
  changeCustomerStatus,
  UpdateUserStatusApi,
  addNewCustomerAPI,
  fetchClientCustomer,
  updateCustomerAPI,
  deleteCustomer,
};
