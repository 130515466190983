import { PermissionActionTypes } from "./constants";

export interface PermissionActionType {
    type:
    | PermissionActionTypes.API_RESPONSE_SUCCESS
    | PermissionActionTypes.API_RESPONSE_ERROR
    | PermissionActionTypes.FETCH_PERMISSION_REQUEST
    | PermissionActionTypes.ADD_PERMISSION_REQUEST
    | PermissionActionTypes.DELETE_PERMISSION_REQUEST;
  payload: {} | string; 
    
}

interface SubAdminData {
    id: number;
    email: string; 
}

// common success
export const permissionsApiResponseSuccess = (
    actionType: string,
    data: SubAdminData | {}
): PermissionActionType => ({
    type: PermissionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const permissionsApiResponseError = (
    actionType: string,
    error: string
): PermissionActionType => ({
    type: PermissionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});


//Fetch request SubAdmin List
export const fetchPermissionsAction = () => ({
    type: PermissionActionTypes.FETCH_PERMISSION_REQUEST
});

//Add request SubAdmin List
export const AddPermissionsAction = (addSubAdminDetails: any) => ({
    type: PermissionActionTypes.ADD_PERMISSION_REQUEST,
    payload : addSubAdminDetails
});

// Delete Request SubAdmin 
export const DeletePermissionsAction = (user_id: any) => ({
    type: PermissionActionTypes.DELETE_PERMISSION_REQUEST,
    payload: user_id
})