import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Admin Profile
function fetchAdminProfile() {
  const baseUrl = config.API_URL + "/admin/fetchAdminProfile";
  return api.create(baseUrl, {});
}

//Editing Admin PRofile
function editAdminProfile(params: any) {
  const baseUrl = config.API_URL + `/admin/updateAdminProfile`;
  // const baseUrl = config.API_URL + `/admin/deleteAdmin/${params?.data}`;
  return api.update(baseUrl, { ...params?.data });
}

// Listing Admin Profile
function AdminProfile() {
  const baseUrl = config.API_URL + "/admin/fetchAdminProfile";
  return api.create(baseUrl, {});
}

// Listing Admin Profile
function updateAvatarAPI(data: any) {
  const baseUrl = config.API_URL + "/updateAvatar";
  return api.createWithFile(baseUrl, data);
}

export {
  fetchAdminProfile,
  editAdminProfile,
  AdminProfile,
  updateAvatarAPI
};
