import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// components
import PageTitle from "../../../components/PageTitle";
import { FormInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import notify from "../../../components/Notification/notify";
import NotificationContainer from "../../../components/Notification/NotificationContainer";
import {
  UserUpdateAvatar,
  editUserProfileAction,
  fetchUserProfileAction,
} from "../../../redux/actions";
import UserBox from "../Contacts/Profile/UserBox";
import FileUploader from "../../../components/FileUploader";
import AvatarURL from '../../../assets/images/users/user-1.jpg'

const UpdateUserProfile = () => {
  const [editorState, setEditorState] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const [file, setFile] = useState<any>();
  const [adminFile, setAdminFile] = useState<any>();

  const { userProfileDetails, editUserProfileDetails, loading, error, avatarData } =
    useSelector((state: RootState) => ({
      userProfileDetails: state.UserProfileReducer.userProfileDetails,
      editUserProfileDetails: state.UserProfileReducer.editUserProfileDetails,
      loading: state.UserProfileReducer.loading,
      error: state.UserProfileReducer.error,
      avatarData: state.UserProfileReducer.avatarData,
    }));

  // const data: any = sessionStorage.getItem("session_user_details");
  // useEffect(() => {
  //   const user_data = JSON.parse(data);
  //   setUserDetails(user_data);
  // }, [data]);

  /*
   * form validation schema
   */
  const schemaResolver = yupResolver(
    yup.object().shape({
      address_main_line: yup.string().required("Please enter Address"),
      city: yup.string().required("Please enter City"),
      state: yup.string().required("Please enter State"),
      postcode: yup.string().required("Please enter Zip"),
      country: yup.string().required("Please enter Country"),
      company_name: yup.string().required("Please enter Company Name"),
      phone_number: yup.string().required("Please enter Phone"),
      landline_number: yup.string(), // This makes landline_number optional
      gst_number: yup.string().required("Please enter GST Number"),
    })
  );

  /*
   * form methods
   */
  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    dispatch(fetchUserProfileAction());
  }, [dispatch]);

  useEffect(() => {
    if (avatarData) {
      dispatch(fetchUserProfileAction());
    }
  }, [avatarData]);

  // Function to handle file conversion and display
  function handleDisplayImage(data: any) {
    // Convert the data array into Uint8Array
    const uint8Array = new Uint8Array(data);

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: "image/jpeg" });

    // Generate a Blob URL from the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Set the Blob URL as the source of the image
    setAdminFile(blobUrl);
  }
  useEffect(() => {
    if (
      userProfileDetails?.status_code === 200 &&
      userProfileDetails?.data !== undefined &&
      userProfileDetails?.data !== null
    ) {
      setUserDetails(userProfileDetails?.data);
      if (userProfileDetails?.data?.profile_image?.data !== null) {
        handleDisplayImage(userProfileDetails?.data?.profile_image?.data);
      }
    }
  }, [userProfileDetails]);

  useEffect(() => {
    setValue("name", userDetails?.name);
    setValue("email", userDetails?.email);
    setValue(
      "address_main_line",
      userDetails?.wm_user_profile?.address_main_line
    );
    setValue("city", userDetails?.wm_user_profile?.city);
    setValue("state", userDetails?.wm_user_profile?.state);
    setValue("postcode", userDetails?.wm_user_profile?.postcode);
    setValue("country", userDetails?.wm_user_profile?.country);
    setValue("company_name", userDetails?.wm_user_profile?.company_name);
    setValue("phone_number", userDetails?.wm_user_profile?.phone_number);
    setValue("landline_number", userDetails?.wm_user_profile?.landline_number);
    setValue("gst_number", userDetails?.wm_user_profile?.gst_number);
    // setValue('profile_image', userDetails?.profile_image);
  }, [userDetails, setValue]);

  function handleChange(e: any) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  /**
   * On editor body change
   */
  const onEditorStateChange = (editorStates: any) => {
    setEditorState(editorStates);
  };

  const onSubmit = (data: any) => {
    const formData = {
      name: data?.name ? data?.name : userDetails?.name,
      email: data?.email ? data?.email : userDetails?.email,
      address_main_line: data?.address_main_line
        ? data?.address_main_line
        : userDetails?.wm_user_profile?.address_main_line,
      city: data?.city ? data?.city : userDetails?.wm_user_profile?.city,
      state: data?.state ? data?.state : userDetails?.wm_user_profile?.state,
      postcode: data?.postcode
        ? data?.postcode
        : userDetails?.wm_user_profile?.postcode,
      country: data?.country
        ? data?.country
        : userDetails?.wm_user_profile?.country,
      company_name: data?.company_name
        ? data?.company_name
        : userDetails?.wm_user_profile?.company_name,
      phone_number: data?.phone_number
        ? data?.phone_number
        : userDetails?.wm_user_profile?.phone_number,
      landline_number: data?.landline_number
        ? data?.landline_number
        : userDetails?.wm_user_profile?.landline_number,
      gst_number: data?.gst_number
        ? data?.gst_number
        : userDetails?.wm_user_profile?.gst_number,
    };
    dispatch(editUserProfileAction(formData));
  };

  useEffect(() => {
    if (error !== undefined && error !== null) {
      notify(`${error?.message}`, "error");
    }
  }, [error]);

  useEffect(() => {
    if (
      editUserProfileDetails?.statusCode === 200 &&
      editUserProfileDetails !== undefined &&
      editUserProfileDetails !== null
    ) {
      dispatch(fetchUserProfileAction());
    }
  }, [editUserProfileDetails, dispatch]);

  const [userProfileImage, setUserProfileImage] = useState<any>();

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      let userData: any = sessionStorage.getItem("session_user_details");
      userData = JSON.parse(userData)
      const formData = new FormData();
      formData.append('user_id', userData.user_id);
      formData.append('profile_image', selectedFile);
      dispatch(UserUpdateAvatar(formData))
    }
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onload = () => {
      setUserProfileImage(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          // { label: "setting", path: "/admin/profile" },
          {
            label: "My Profile",
            path: "/admin/profile",
            active: true,
          },
        ]}
        title={"My Profile"}  // for user
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg={4}>
            {/* <UserBox userDetails={userDetails} userProfileImage={userProfileImage} handleChange={handleChange}/> */}

            <Card className="text-center">
              <Card.Body>
                <Row>

                  <div className="">
                    <div className="container">
                      <div className="d-flex justify-content-center">
                        <div className="position-relative">
                          <input
                            type="file"
                            id="profileImageInput"
                            style={{ display: 'none' }}
                            accept="image/*"
                            {...register('profile_image')}
                            onChange={handleFileChange}
                          />
                          <label
                            htmlFor="profileImageInput"
                            className="position-absolute"
                            style={{
                              top: '10px', // Adjust to position inside the top border
                              right: '10px', // Adjust to position inside the right border
                              cursor: 'pointer',
                              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light background for visibility
                              borderRadius: '50%', // Circular background
                              width: '25px', // Fixed size for the label
                              height: '25px', // Fixed size for the label
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: '1px solid #ddd',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                              zIndex: 1
                            }}
                          >
                            <i className="mdi mdi-pencil" style={{ fontSize: '15px', color: '#757575' }}></i>
                          </label>
                          <div
                            className="rounded-circle"
                            style={{
                              width: '150px',
                              height: '150px',
                              borderRadius: '50%',
                              border: '6px solid #F8F8F8',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              backgroundImage: `url(${userDetails?.profile_image || AvatarURL})`
                            }}
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* <label htmlFor="profileImageInput">
                    <img
                      src={
                        userDetails?.profile_image
                          ? userDetails?.profile_image
                          : "https://i.pravatar.cc/300"
                      }
                      className="rounded-circle avatar-xxl img-thumbnail"
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                  <input
                    type="file"
                    id="profileImageInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    {...register("profile_image")}
                    onChange={handleFileChange}
                  /> */}
                </Row>
                <h4 className="mb-0 text-capitalize">{userDetails?.name}</h4>
                <p className="text-muted">{userDetails?.email}</p>
                <hr />
                <div className="text-start mt-3">
                  <p className="text-muted mb-2 font-13">
                    <strong>Full Name :</strong>
                    <span className="ms-2 text-capitalize">
                      {userDetails?.name}
                    </span>
                  </p>

                  <p className="text-muted mb-2 font-13">
                    <strong>Mobile :</strong>
                    <span className="ms-2">
                      {userDetails?.wm_user_profile?.phone_number}
                    </span>
                  </p>

                  <p className="text-muted mb-2 font-13">
                    <strong>Email :</strong>
                    <span className="ms-2 ">{userDetails?.email}</span>
                  </p>

                  <p className="text-muted mb-1 font-13">
                    <strong>Location :</strong>
                    <span className="ms-2 text-capitalize">
                      {userDetails?.wm_user_profile?.country}
                    </span>
                  </p>
                </div>

                <div className="text-center mt-3 text-muted fst-italic">
                  <span>Wholesale Mart Vendor</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8}>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  General
                </h5>
                <Row className="">
                  <FormInput
                    name="name"
                    label="Name"
                    placeholder="Enter Name"
                    containerClass={"mb-3 col-md-6"}
                    register={register}
                    key="name"
                    errors={errors}
                    control={control}
                  />
                  <FormInput
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                    containerClass={"mb-3 col-md-6"}
                    register={register}
                    key="email"
                    errors={errors}
                    control={control}
                    disabled={true}
                  />
                </Row>

                <Row className="">
                  <FormInput
                    name="address_main_line"
                    label="Address"
                    placeholder="Enter Address"
                    containerClass={"mb-3 col-md-12"}
                    register={register}
                    key="address_main_line"
                    errors={errors}
                    control={control}
                  />
                </Row>

                <Row className="">
                  <FormInput
                    name="city"
                    label="City"
                    placeholder="Enter city"
                    containerClass={"mb-3 col-md-6"}
                    register={register}
                    key="city"
                    errors={errors}
                    control={control}
                  />

                  <FormInput
                    name="state"
                    label="State"
                    placeholder="Enter state"
                    containerClass={"mb-3 col-md-3"}
                    register={register}
                    key="state"
                    errors={errors}
                    control={control}
                  />

                  <FormInput
                    name="postcode"
                    label="Zip"
                    type="number"
                    placeholder="Enter zip"
                    containerClass={"mb-3 col-md-3"}
                    register={register}
                    key="postcode"
                    errors={errors}
                    control={control}
                  />
                </Row>

                <Row className="">
                  <FormInput
                    name="country"
                    label="Country"
                    placeholder="Enter Country"
                    containerClass={"mb-3 col-md-3"}
                    register={register}
                    key="country"
                    errors={errors}
                    control={control}
                  />

                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    Comapany info
                  </h5>
                  <FormInput
                    name="company_name"
                    label="Company Name"
                    placeholder="Enter Company Name"
                    containerClass={"mb-3 col-md-12"}
                    register={register}
                    key="company_name"
                    errors={errors}
                    control={control}
                  />
                </Row>

                <Row className="">
                  <FormInput
                    name="phone_number"
                    label="Phone"
                    type="number"
                    placeholder="Enter phone"
                    containerClass={"mb-3 col-md-6"}
                    register={register}
                    key="phone_number"
                    errors={errors}
                    control={control}
                  />

                  <FormInput
                    name="landline_number"
                    label="Land-line No."
                    type="number"
                    placeholder="Enter Land-line No."
                    containerClass={"mb-3 col-md-6"}
                    register={register}
                    key="landline_number"
                    errors={errors}
                    control={control}
                  />
                </Row>

                <Row className="">
                  <FormInput
                    name="gst_number"
                    type="number"
                    label="GST No."
                    placeholder="Enter GST No."
                    containerClass={"mb-3 col-md-6"}
                    register={register}
                    key="gst_number"
                    errors={errors}
                    control={control}
                  />
                </Row>

                {/* <Row>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    User Images
                  </h5>
                  <label htmlFor="profileImageInput">
                    <img
                      src={
                        userProfileImage
                          ? userProfileImage
                          : "https://i.pravatar.cc/300"
                      }
                      className="rounded-circle avatar-xxl img-thumbnail"
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                  <input
                    type="file"
                    id="profileImageInput"
                    style={{ display: "none" }}
                    accept="image/*"
                    {...register("profile_image")}
                    onChange={handleFileChange}
                  />
                </Row> */}

                <Row>
                  <Col>
                    <div className="text-center mb-3">
                      <div className="text-end">
                        <button type="submit" className="btn btn-success mt-2">
                          <i className="mdi mdi-content-save"></i> Save
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* <Card>
              <Card.Body>
                <UserProfileUploader
                  profileImg={profileImg}
                  changeUserProfile={handleChangeUserProfile}
                  deleteProfileImage={handleDeleteProfileImage}
                />
                setProductImages={setProductImages}
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                  User Images
                </h5>
                <FormInput
                  register={register}
                  type="file"
                  onChange={handleChange}
                  name="profile_image"
                  className="rounded-circle"
                />
                {adminFile && (
                  <img
                    src={adminFile}
                    className="rounded-circle"
                    width={150}
                    height={150}
                  />
                )}
                {file && (
                  <img
                    src={file}
                    className="rounded-circle"
                    width={150}
                    height={150}
                  />
                )}
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      </form>
    </>
  );
};

export default UpdateUserProfile;
