import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  subadminApiResponseError,
  subadminApiResponseSuccess,
} from "./actions";
import { SubAdminActionTypes } from "./constant";
import {
  addSubAdmin,
  deleteSubAdmin,
  editSubAdmin,
  fetchPermission,
  fetchSubAdmin,
  updatePermission,
  updateSubAdminStatus,
} from "../../helpers/api/subAdminApi";
import Swal from "sweetalert2";
import notify from "../../components/Notification/notify";

interface subAdminData {
  payload: {
    email: string;
    password: string;
  };
  type: string;
}

// Fetch SubAdmin User.....

function* fetchSubAdminSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(fetchSubAdmin, { data });
    const productData: any = response.data;
    yield put(
      subadminApiResponseSuccess(
        SubAdminActionTypes.FETCH_SUBADMIN_REQUEST,
        productData
      )
    );
  } catch (error: any) {
    yield put(
      subadminApiResponseError(
        SubAdminActionTypes.FETCH_SUBADMIN_REQUEST,
        error
      )
    );
  }
}

export function* watchFetchSubAdmin() {
  yield takeEvery(
    SubAdminActionTypes.FETCH_SUBADMIN_REQUEST,
    fetchSubAdminSaga
  );
}

// Fetch Permission ......

function* fetchPermissionSaga(): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: "",
      sort_field: "created_datetime",
      sort_order: "desc",
    };
    const response = yield call(fetchPermission, { data });
    const productData: any = response.data.data;
    yield put(
      subadminApiResponseSuccess(
        SubAdminActionTypes.FETCH_PERMISSION_REQUEST,
        productData
      )
    );
  } catch (error: any) {
    yield put(
      subadminApiResponseError(
        SubAdminActionTypes.FETCH_PERMISSION_REQUEST,
        error
      )
    );
  }
}

export function* watchFetchPermission() {
  yield takeEvery(
    SubAdminActionTypes.FETCH_PERMISSION_REQUEST,
    fetchPermissionSaga
  );
}

//Add SubAdmin User .....

function* AddSubAdminSaga({ payload: data }: subAdminData): SagaIterator {
  try {
    const response = yield call(addSubAdmin, { data });
    const payload: any = response.data;
    yield put(
      subadminApiResponseSuccess(
        SubAdminActionTypes.ADD_SUBADMIN_REQUEST,
        payload
      )
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      subadminApiResponseError(SubAdminActionTypes.ADD_SUBADMIN_REQUEST, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddSubAdmin() {
  yield takeEvery(SubAdminActionTypes.ADD_SUBADMIN_REQUEST, AddSubAdminSaga);
}

//Edit SubAdmin User

function* EditSubAdminSaga({ payload: data }: subAdminData): SagaIterator {
  try {
    const response = yield call(editSubAdmin, { data });
    const payload: any = response.data;
    yield put(
      subadminApiResponseSuccess(
        SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST,
        payload
      )
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      subadminApiResponseError(
        SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST,
        error
      )
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditSubAdmin() {
  yield takeEvery(
    SubAdminActionTypes.UPDATE_SUBADMIN_REQUEST,
    EditSubAdminSaga
  );
}

//Delete subAdmin User...

function* DeleteSubAdminSaga({ payload: data }: subAdminData): SagaIterator {
  try {
    const response = yield call(deleteSubAdmin, { data });
    const payload: any = response.data.data;
    yield put(
      subadminApiResponseSuccess(
        SubAdminActionTypes.DELETE_SUBADMIN_REQUEST,
        payload
      )
    );
    Swal.fire("Success!", "subAdmin has been deleted.", "success");
  } catch (error: any) {
    yield put(
      subadminApiResponseError(
        SubAdminActionTypes.DELETE_SUBADMIN_REQUEST,
        error
      )
    );
    Swal.fire("Error!", `${error.message}`, "error");
  }
}

export function* watchDeleteSubAdmin() {
  yield takeEvery(
    SubAdminActionTypes.DELETE_SUBADMIN_REQUEST,
    DeleteSubAdminSaga
  );
}

//Edit Status Subadmin details
function* EditsubAdminStatusSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(updateSubAdminStatus, { data });
    const payload: any = response.data;
    yield put(
      subadminApiResponseSuccess(
        SubAdminActionTypes.UPDATE_SUBADMIN_STATUS,
        payload
      )
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      subadminApiResponseError(
        SubAdminActionTypes.UPDATE_SUBADMIN_STATUS,
        error
      )
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditSubAdminStatus() {
  yield takeEvery(
    SubAdminActionTypes.UPDATE_SUBADMIN_STATUS,
    EditsubAdminStatusSaga
  );
}

function* UpdatePermissionSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(updatePermission, { data });
    const payload: any = response.data;
    yield put(
      subadminApiResponseSuccess(SubAdminActionTypes.UPDATE_PERMISSION, payload)
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      subadminApiResponseError(SubAdminActionTypes.UPDATE_PERMISSION, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchUpdatePermission() {
  yield takeEvery(SubAdminActionTypes.UPDATE_PERMISSION, UpdatePermissionSaga);
}

function* subAdminSaga() {
  yield all([
    fork(watchFetchPermission),
    fork(watchFetchSubAdmin),
    fork(watchAddSubAdmin),
    fork(watchEditSubAdmin),
    fork(watchDeleteSubAdmin),
    fork(watchEditSubAdminStatus),
    fork(watchUpdatePermission),
  ]);
}

export default subAdminSaga;
