import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Permission
function fetchPermission(params: any) {
  const baseUrl = config.API_URL + "/admin/listPermission";
  return api.get(baseUrl, { ...params?.data });
}


//Adding SubAdmin
function addPermission(params: any) {
  const baseUrl = config.API_URL + "/admin/addPemission";
  const response = api.create(baseUrl, { ...params?.data });
  return response;
}


//Deleting SubAdmin
function deletePermission(params: any) {
  let temp = {
    permission_id: params.data
  }
  const baseUrl = config.API_URL + `/admin/deletePermission/${params.data}`;
  return api.deleteResponse(baseUrl, temp);
}

export {
  fetchPermission,
  addPermission,
  deletePermission,
};
