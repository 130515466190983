export enum TaskActionTypes {
    API_RESPONSE_SUCCESS = "@@tasks/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@tasks/API_RESPONSE_ERROR",
  
    FETCH_TASKS = "@@tasks/FETCH_TASKS",
    FETCH_SUBADMIN_LIST = "@@tasks/FETCH_SUBADMIN_LIST",
    ADD_TASK = "@@tasks/ADD_TASK",
    FETCH_TASKS_ID = "@@tasks/FETCH_TASKS_ID",
    EDIT_TASK = "@@tasks/EDIT_TASK",
    DELETE_TASK = "@@tasks/DELETE_TASK",
    EDIT_STATUS_TASK = "@@tasks/EDIT_STATUS_TASK",
    CUSTOMER_LIST = "@@tasks/CUSTOMER_LIST",
    FETCH_CLIENT_TASK = "@@tasks/FETCH_CLIENT_TASK",
  }
  
