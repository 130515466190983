import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { ordersApiResponseError, ordersApiResponseSuccess } from "./actions";
import { OrdersActionTypes } from "./constants";
import {
  AddOrderQtyApi,
  DeleteOrderApi,
  UpdateOrderStatusApi,
  addCustomerOrder,
  fetchActionOrder,
  fetchNewOrderRequest,
  fetchOrderDetails,
  fetchOrderProduct,
  fetchOrders,
  listOrderCustomers,
} from "../../helpers/api/ordersApi";
import notify from "../../components/Notification/notify";

// Fetch Orders ......
function* fetchOrdersSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(fetchOrders, { data });
    const _response: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.FETCH_ORDER_REQUEST, _response)
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.FETCH_ORDER_REQUEST, error)
    );
  }
}

export function* watchFetchOrders() {
  yield takeEvery(OrdersActionTypes.FETCH_ORDER_REQUEST, fetchOrdersSaga);
}

// Fetch New Orders ......
function* fetchNewOrderRequestSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: payload.page_record ?? "10",
      page_no: payload.page_no ?? "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(fetchNewOrderRequest, { data });
    const _response: any = response.data;
    yield put(
      ordersApiResponseSuccess(
        OrdersActionTypes.FETCH_NEW_ORDER_REQUEST,
        _response
      )
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.FETCH_NEW_ORDER_REQUEST, error)
    );
  }
}

export function* watchFetchNewOrderRequestSaga() {
  yield takeEvery(
    OrdersActionTypes.FETCH_NEW_ORDER_REQUEST,
    fetchNewOrderRequestSaga
  );
}

// Actions Orders ......

function* fetchActionsOrderSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(fetchActionOrder, { data });
    const payload: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.ACTIONS_ORDER_REQUEST, payload)
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.ACTIONS_ORDER_REQUEST, error)
    );
  }
}

export function* watchActionOrderSaga() {
  yield takeEvery(
    OrdersActionTypes.ACTIONS_ORDER_REQUEST,
    fetchActionsOrderSaga
  );
}

// ? fetch product details saga
function* fetchOrderDetailsSaga(action: any): SagaIterator {
  try {
    let { order_id } = action.payload;
    const response = yield call(fetchOrderDetails, order_id);
    const payload: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.FETCH_ORDER_DETAILS, payload)
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.FETCH_ORDER_DETAILS, error)
    );
  }
}

export function* watchFetchOrderDetailsSaga() {
  yield takeEvery(OrdersActionTypes.FETCH_ORDER_DETAILS, fetchOrderDetailsSaga);
}

//Update Order status Saga
function* updateOrderStatus({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(UpdateOrderStatusApi, { data });
    const payload: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.UPDATE_ORDER_STATUS, payload)
    );
    yield call(notify, `${response.data.message}`, "success");
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.UPDATE_ORDER_STATUS, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchUpdateOrderStatus() {
  yield takeEvery(
    OrdersActionTypes.UPDATE_ORDER_STATUS,
    updateOrderStatus
  );
}


//Add Order qty Saga
function* addOrderQtySaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(AddOrderQtyApi, { data });
    const payload: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.ADD_ORDER, payload)
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.ADD_ORDER, error)
    );
  }
}

export function* watchAddOrderQty() {
  yield takeEvery(
    OrdersActionTypes.ADD_ORDER,
    addOrderQtySaga
  );
}


//Add Order qty Saga
function* deleteOrderSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(DeleteOrderApi, { data });
    const payload: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.DELETE_ORDER, payload)
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.DELETE_ORDER, error)
    );
  }
}

export function* watchDeleteOrder() {
  yield takeEvery(
    OrdersActionTypes.DELETE_ORDER,
    deleteOrderSaga
  );
}

// Fetch Orders Product Listing ......
function* fetchOrderProductListSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(fetchOrderProduct, { data });
    const _response: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.ORDER_PRODUCT_LIST, _response)
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.ORDER_PRODUCT_LIST, error)
    );
  }
}

export function* watchFetchOrderProductList() {
  yield takeEvery(OrdersActionTypes.ORDER_PRODUCT_LIST, fetchOrderProductListSaga);
}

// Fetch Orders Customer Listing ......
function* listCustomersOrderActionSaga({ payload }: any): SagaIterator {
  try {
    const data = {
      page_record: "10",
      page_no: "1",
      search: payload.search ?? "",
      sort_field: payload.sort_field ?? "created_datetime",
      sort_order: payload.sort_order ?? "desc",
    };
    const response = yield call(listOrderCustomers, { data });
    const _response: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.LIST_CUSTOMERS_ORDER, _response)
    );
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.LIST_CUSTOMERS_ORDER, error)
    );
  }
}

export function* watchListCustomersOrderActionSaga() {
  yield takeEvery(OrdersActionTypes.LIST_CUSTOMERS_ORDER, listCustomersOrderActionSaga);
}

// Add order by admin ......
function* addCustomerOrderSaga({ payload }: any): SagaIterator {
  try {
    let data = payload
    const response = yield call(addCustomerOrder, { data });
    const _response: any = response.data;
    yield put(
      ordersApiResponseSuccess(OrdersActionTypes.ADD_CUSTOMER_ORDER, _response)
    );
    yield call(notify, `${_response.message}`, "success");
  } catch (error: any) {
    yield put(
      ordersApiResponseError(OrdersActionTypes.ADD_CUSTOMER_ORDER, error)
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchAddCustomerOrderSaga() {
  yield takeEvery(OrdersActionTypes.ADD_CUSTOMER_ORDER, addCustomerOrderSaga);
}

function* orderSaga() {
  yield all([
    fork(watchFetchOrders),
    fork(watchFetchNewOrderRequestSaga),
    fork(watchActionOrderSaga),
    fork(watchFetchOrderDetailsSaga),
    fork(watchUpdateOrderStatus),
    fork(watchAddOrderQty),
    fork(watchDeleteOrder),
    fork(watchFetchOrderProductList),
    fork(watchListCustomersOrderActionSaga),
    fork(watchAddCustomerOrderSaga),
  ]);
}

export default orderSaga;
