import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// account
function login(params: { data: any }) {
  const baseUrl = config.API_URL + "/signin";
  return api.create(baseUrl, { ...params?.data });
}

function logout(params: any) {
  const baseUrl = "/logoutUser";
  return api.create(`${baseUrl}`, { ...params?.dataRes });
}

function signup(params: { name: string; email: string; password: string }) {
  const baseUrl = "/signUp";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forgotPassword";
  return api.create(`${baseUrl}`, params);
}

function verifyOTP_API(params: any) {
  const baseUrl = "/verifyOTP";
  return api.create(`${baseUrl}`, params);
}

function resetPassword_API(params: any) {
  const baseUrl = "/resetPassword";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, verifyOTP_API, resetPassword_API };
