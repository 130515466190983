import { UserOrderActionTypes } from "./constants";

export interface UserOrderActionType {
  type:
  | UserOrderActionTypes.API_RESPONSE_SUCCESS
  | UserOrderActionTypes.API_RESPONSE_ERROR
  | UserOrderActionTypes.GET_USER_ORDER_LIST;
  payload: {} | string;
}

// common success
export const userOrderApiResponseSuccess = (
  actionType: string,
  data: any | {}
): UserOrderActionType => ({
  type: UserOrderActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const userOrderApiResponseError = (
  actionType: string,
  error: string
): UserOrderActionType => ({
  type: UserOrderActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Get User Order List action
export const GetUserOrderAction = (reqBody: any) => ({
  type: UserOrderActionTypes.GET_USER_ORDER_LIST,
  payload: reqBody,
});

