import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  ProgressBar,
  Tab,
  Nav,
  Button,
  Spinner,
} from "react-bootstrap";

// components
import PageTitle from "../../components/PageTitle";
import Rating from "../../components/Rating";

import productImg1 from "../../assets/images/products/product-9.jpg";
import productImg2 from "../../assets/images/products/product-10.jpg";
import productImg3 from "../../assets/images/products/product-11.jpg";
import productImg4 from "../../assets/images/products/product-12.jpg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import classNames from "classnames";
import Swal from "sweetalert2";
import {
  AddToCartAction,
  GetUserProductDetailsAction,
} from "../../redux/actions";
import { Field, Form, Formik, FormikProps } from "formik";
import notify from "../../components/Notification/notify";

interface Product {
  brand: string;
  name?: string;
  reviews: string;
  status: string;
  discount: number;
  price: number;
  description: string;
  rating: number;
  features: string[];
}

// Qty interface
interface FormValues {
  qty: number;
}

// Stock Table
const Stocks = () => {
  return (
    <>
      <div className="table-responsive mt-4">
        <table className="table table-bordered table-centered mb-0">
          <thead className="table-light">
            <tr>
              <th>Outlets</th>
              <th>Price</th>
              <th>Stock</th>
              <th>Revenue</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ASOS Ridley Outlet - NYC</td>
              <td>$139.58</td>
              <td>
                <div className="row align-items-center g-0">
                  <div className="col-auto">
                    <span className="me-2">27%</span>
                  </div>
                  <div className="col">
                    <ProgressBar
                      now={27}
                      className="progress-sm"
                      variant="danger"
                    />
                  </div>
                </div>
              </td>
              <td>$1,89,547</td>
            </tr>
            <tr>
              <td>Marco Outlet - SRT</td>
              <td>$149.99</td>
              <td>
                <div className="row align-items-center g-0">
                  <div className="col-auto">
                    <span className="me-2">71%</span>
                  </div>
                  <div className="col">
                    <ProgressBar
                      now={71}
                      className="progress-sm"
                      variant="success"
                    />
                  </div>
                </div>
              </td>
              <td>$87,245</td>
            </tr>
            <tr>
              <td>Chairtest Outlet - HY</td>
              <td>$135.87</td>
              <td>
                <div className="row align-items-center g-0">
                  <div className="col-auto">
                    <span className="me-2">82%</span>
                  </div>
                  <div className="col">
                    <ProgressBar
                      now={82}
                      className="progress-sm"
                      variant="success"
                    />
                  </div>
                </div>
              </td>
              <td>$5,87,478</td>
            </tr>
            <tr>
              <td>Nworld Group - India</td>
              <td>$159.89</td>
              <td>
                <div className="row align-items-center g-0">
                  <div className="col-auto">
                    <span className="me-2">42%</span>
                  </div>
                  <div className="col">
                    <ProgressBar
                      now={42}
                      className="progress-sm"
                      variant="warning"
                    />
                  </div>
                </div>
              </td>
              <td>$55,781</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const ProductDetails = ({ productId }: any) => {
  let { product_id } = useParams();

  const [product] = useState<Product>({
    brand: "Jack & Jones",
    name: "Jack & Jones Men's T-shirt (Blue)",
    reviews: "36",
    status: "Instock",
    discount: 20,
    price: 80,
    description:
      "The languages only differ in their grammar, their pronunciation and their most common words. Everyone realizes why a new common language would be desirable: one could refuse to pay expensive translators.",
    rating: 4.5,
    features: [
      "Sed ut perspiciatis unde",
      "Itaque earum rerum hic",
      "Nemo enim ipsam voluptatem",
      "Donec quam felis ultricies nec",
      "Temporibus autem quibusdam et",
    ],
  });

  const [discountPrice] = useState<number>(
    Math.round(product.price - (product.price * product.discount) / 100)
  );

  const [productData, setProductData] = useState<any>({});
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    let userData: any = sessionStorage.getItem("session_user_details");
    userData = JSON.parse(userData);
    if ([1, 2].includes(userData?.user_role)) {
      setIsAdmin(true);
    }
  }, [isAdmin]);

  // * product data
  const { productDetails, loading, error } = useSelector(
    (state: RootState) => ({
      productDetails: state.UserProductReducer.productDetails,
      loading: state.UserProductReducer.loading,
      error: state.UserProductReducer.error,
    })
  );

  useEffect(() => {
    dispatch(GetUserProductDetailsAction(product_id));
  }, [dispatch, product_id]);

  useEffect(() => {
    if (
      productDetails?.statusCode === 200 &&
      productDetails?.data !== undefined
    ) {
      setProductData(productDetails.data);
    }
  }, [productDetails, loading]);
  // Stock Table
  const ProductAttributes = () => {
    return (
      <>
        <div className="table-responsive mt-4">
          <table className="table table-bordered table-centered mb-0">
            <thead className="table-light">
              <tr>
                <th colSpan={2}>Attributes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Color</td>
                <td>{productData?.attribute_color || ""}</td>
              </tr>
              <tr>
                <td>Material</td>
                <td>{productData?.attribute_material || ""}</td>
              </tr>
              <tr>
                <td>Shape</td>
                <td>{productData?.attribute_shape || ""}</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>{productData?.attribute_size || ""}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const ProductStatusTag = ({ productData }: { productData: any }) => {
    return (
      <>
        <span
          className={classNames("badge", {
            "bg-success text-white": productData?.status === 1,
            "bg-danger text-white": productData?.status === 2,
            "bg-info text-white": productData?.status === 4,
            "bg-warning text-white": productData?.status === 0,
            "bg-secondary text-white": productData?.status === 3,
          })}
        >
          {/* {productData?.product_description} */}
          {productData?.status === 0
            ? "Not Active"
            : productData?.status === 1
            ? "Active"
            : productData?.status === 2
            ? "Blocked"
            : productData?.status === 3
            ? "not set"
            : productData?.status === 4
            ? "not set"
            : "not set"}
        </span>
      </>
    );
  };

  //Function to increment quantity
  const handleIncrement = (
    formik: FormikProps<FormValues>,
    fieldName: keyof FormValues
  ) => {
    const currentValue = formik.values[fieldName];
    formik.setFieldValue(fieldName, currentValue + 1);
  };

  //Function to decrement quantity
  const handleDecrement = (
    formik: FormikProps<FormValues>,
    fieldName: keyof FormValues
  ) => {
    const currentValue = formik.values[fieldName];
    formik.setFieldValue(fieldName, currentValue > 0 ? currentValue - 1 : 0);
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Product Detail",
            path: "/admin/details",
            active: true,
          },
        ]}
        title={"Product Detail"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={5}>
                  {productData &&
                  productData?.wm_product_photos &&
                  productData?.wm_product_photos.length > 0 ? (
                    <>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="product-1-item"
                      >
                        <Tab.Content className="p-0">
                          {productData.wm_product_photos.map(
                            (photo: any, index: any) => (
                              <Tab.Pane
                                key={`product-${index + 1}-item`}
                                eventKey={`product-${index + 1}-item`}
                              >
                                <img
                                  src={photo.photo_url}
                                  alt={`Product ${index + 1}`}
                                  className="img-fluid mx-auto d-block rounded"
                                  style={{
                                    objectFit: "cover",
                                    height: "450px",
                                    width: "auto",
                                  }}
                                />
                              </Tab.Pane>
                            )
                          )}
                        </Tab.Content>

                        <Nav
                          variant="pills"
                          as="ul"
                          className="nav nav-justified"
                        >
                          {productData.wm_product_photos.map(
                            (photo: any, index: any) => (
                              <Nav.Item key={`nav-item-${index + 1}`} as="li">
                                <Nav.Link
                                  eventKey={`product-${index + 1}-item`}
                                  className="product-thumb cursor-pointer"
                                >
                                  <img
                                    src={photo.photo_url}
                                    alt={`Product ${index + 1}`}
                                    className="img-fluid mx-auto d-block rounded"
                                    style={{
                                      objectFit: "cover",
                                      height: "100px",
                                      width: "auto",
                                    }}
                                  />
                                </Nav.Link>
                              </Nav.Item>
                            )
                          )}
                        </Nav>
                      </Tab.Container>
                    </>
                  ) : null}

                  {/* <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="product-1-item"
                  >
                    <Tab.Content className="p-0">
                      <Tab.Pane eventKey="product-1-item">
                        <img
                          src={productImg1}
                          alt=""
                          className="img-fluid mx-auto d-block rounded"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="product-2-item">
                        <img
                          src={productImg2}
                          alt=""
                          className="img-fluid mx-auto d-block rounded"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="product-3-item">
                        <img
                          src={productImg3}
                          alt=""
                          className="img-fluid mx-auto d-block rounded"
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="product-4-item">
                        <img
                          src={productImg4}
                          alt=""
                          className="img-fluid mx-auto d-block rounded"
                        />
                      </Tab.Pane>
                    </Tab.Content>

                    <Nav variant="pills" as="ul" className="nav nav-justified">
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey="product-1-item"
                          className="product-thumb cursor-pointer"
                        >
                          <img
                            src={productImg1}
                            alt=""
                            className="img-fluid mx-auto d-block rounded"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey="product-2-item"
                          className="product-thumb cursor-pointer"
                        >
                          <img
                            src={productImg2}
                            alt=""
                            className="img-fluid mx-auto d-block rounded"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey="product-3-item"
                          className="product-thumb cursor-pointer"
                        >
                          <img
                            src={productImg3}
                            alt=""
                            className="img-fluid mx-auto d-block rounded"
                          />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey="product-4-item"
                          className="product-thumb cursor-pointer"
                        >
                          <img
                            src={productImg4}
                            alt=""
                            className="img-fluid mx-auto d-block rounded"
                          />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Tab.Container> */}
                </Col>
                <Col lg={7}>
                  {Object.keys(productData).length > 0 ? (
                    <div className="ps-xl-3 mt-3 mt-xl-0">
                      <Link to="#" className="text-primary">
                        {productData?.wm_product_category?.category_name}{" "}
                        {productData?.wm_product_subcategory
                          ?.subcategory_name &&
                          ", " +
                            productData?.wm_product_subcategory
                              ?.subcategory_name}
                      </Link>
                      <h4 className="mb-3">{productData?.product_name}</h4>
                      <h4 className="mb-4">
                        <span className="mr-1">Price : </span>
                        <b>
                          ₹{" "}
                          {parseFloat(productData?.rrr_price)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          INR
                        </b>
                      </h4>
                      <h4>
                        {" "}
                        <ProductStatusTag productData={productData} />{" "}
                      </h4>
                      <p className="text-muted mb-4">
                        {productData?.product_description}
                      </p>
                      <div className="">
                        {" "}
                        <ProductAttributes />{" "}
                      </div>
                      <div className="mt-3">
                        {productData ? (
                          <Formik
                            enableReinitialize={true}
                            initialValues={
                              productData && productData?.qty > 0
                                ? {
                                    qty: productData?.qty,
                                  }
                                : {
                                    qty: 1,
                                  }
                            }
                            onSubmit={(values) => {
                              const data = {
                                product_id: productData?.product_id,
                                qty: values.qty,
                              };
                              dispatch(AddToCartAction(data));
                            }}
                          >
                            {({
                              touched,
                              formik,
                              values,
                              setFieldValue,
                              setFieldError,
                            }: any) => (
                              <Form>
                                <div className="row">
                                  <div className="col-md-12 d-flex align-items-center">
                                    <Link
                                      to=""
                                      className="me-2 action-icon"
                                      onClick={(e: any) => {
                                        let x: any = values.qty - 1;
                                        let value = parseInt(x, 10);
                                        if (isNaN(value) || value < 1) {
                                          setFieldError(
                                            "quantity",
                                            "Quantity must be at least 1 and a number"
                                          );
                                          notify(
                                            "There should be at least one quantity.",
                                            "error"
                                          );
                                        } else if (value > productData?.stock) {
                                          setFieldError(
                                            "quantity",
                                            `Cannot add more than ${productData?.stock} items.`
                                          );
                                          notify(
                                            `Cannot add more than ${productData?.stock} items to the cart.`,
                                            "error"
                                          );
                                        } else {
                                          setFieldValue("qty", value);
                                        }
                                      }}
                                      // onClick={() =>
                                      //   handleDecrement(touched, "qty")
                                      // }
                                    >
                                      <i className="mdi mdi-minus fs-4"></i>
                                    </Link>

                                    <Field
                                      type="number"
                                      id="qty"
                                      name="qty"
                                      className="form-control text-center"
                                      style={{ width: "90px" }}
                                      min={1}
                                      placeholder="Qty"
                                      value={values.qty}
                                      onBlur={(e: any) => {
                                        const value = parseInt(
                                          e.target.value,
                                          10
                                        );
                                        if (isNaN(value) || value < 1) {
                                          setFieldError(
                                            "quantity",
                                            "Quantity must be at least 1 and a number"
                                          );
                                          notify(
                                            "There should be at least one quantity.",
                                            "error"
                                          );
                                        } else if (
                                          value > productData?.stock
                                        ) {
                                          setFieldError(
                                            "quantity",
                                            `Cannot add more than ${productData?.stock} items.`
                                          );
                                          notify(
                                            `Cannot add more than ${productData?.stock} items to the cart.`,
                                            "error"
                                          );
                                        } else {
                                          setFieldValue("qty", value);
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        const value = parseInt(
                                          e.target.value,
                                          10
                                        );
                                        if (isNaN(value) || value < 1) {
                                          setFieldError(
                                            "quantity",
                                            "Quantity must be at least 1 and a number"
                                          );
                                          notify(
                                            "There should be at least one quantity.",
                                            "error"
                                          );
                                        } else if (
                                          value > productData?.stock
                                        ) {
                                          setFieldError(
                                            "quantity",
                                            `Cannot add more than ${productData?.stock} items.`
                                          );
                                          notify(
                                            `Cannot add more +than ${productData?.stock} items to the cart.`,
                                            "error"
                                          );
                                        } else {
                                          setFieldValue("qty", value);
                                        }
                                      }}
                                    />

                                    <Link
                                      to=""
                                      className="ms-2 action-icon"
                                      onClick={(e: any) => {
                                        let value = parseInt(
                                          values.qty + 1,
                                          10
                                        );
                                        if (isNaN(value) || value < 1) {
                                          setFieldError(
                                            "quantity",
                                            "Quantity must be at least 1 and a number"
                                          );
                                          notify(
                                            "There should be at least one quantity.",
                                            "error"
                                          );
                                        } else if (value > productData?.stock) {
                                          setFieldError(
                                            "quantity",
                                            `Cannot add more than ${productData?.stock} items.`
                                          );
                                          notify(
                                            `Cannot add more than ${productData?.stock} items to the cart.`,
                                            "error"
                                          );
                                        } else {
                                          setFieldValue("qty", value);
                                        }
                                      }}
                                    >
                                      <i className="mdi mdi-plus fs-4"></i>
                                    </Link>

                                    {productData?.qty > 0 ? (
                                      <div className="ms-3">
                                        <Button variant="warning" type="submit">
                                          Add more
                                        </Button>
                                      </div>
                                    ) : (
                                      <div className="ms-3">
                                        <Button variant="primary" type="submit">
                                          Add to Cart
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        ) : null}
                      </div>
                      {/* )} */}
                    </div>
                  ) : (
                    // <Spinner/>
                    <span>Loading......</span>
                  )}
                </Col>
              </Row>

              {/* <Stocks /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProductDetails;
