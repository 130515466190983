import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  userProfileApiResponseSuccess,
  userProfileApiResponseError,
} from "./actions";
import { UserProfileActionTypes } from "./constants";
import {
  editUserProfile,
  fetchUserProfile,
  updateUserAvatarAPI,
} from "../../helpers/api/userProfile";
import { APICore } from "../../helpers/api/apiCore";
import notify from "../../components/Notification/notify";
let api = new APICore();

// Fetch User Profile ......

function* fetchUserProfileSaga(): SagaIterator {
  try {
    const response = yield call(fetchUserProfile);
    const payload: any = response.data;
    api.setLoggedInUser({
      ...payload?.data,
      token: payload?.data?.access_token,
    });

    yield put(
      userProfileApiResponseSuccess(
        UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      userProfileApiResponseError(
        UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST,
        error
      )
    );
  }
}

export function* watchFetchUserProfile() {
  yield takeEvery(
    UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST,
    fetchUserProfileSaga
  );
}

// Edit User Profile Request ......
function* editUserProfileSaga({ payload: data }: any): SagaIterator {
  try {
    const response = yield call(editUserProfile, { data });
    const payload: any = response.data;
    yield put(
      userProfileApiResponseSuccess(
        UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST,
        payload
      )
    );
    yield call(notify, `${payload.message}`, "success");
  } catch (error: any) {
    yield put(
      userProfileApiResponseError(
        UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST,
        error
      )
    );
    yield call(notify, `${error.message}`, "error");
  }
}

export function* watchEditUserProfileSaga() {
  yield takeEvery(
    UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST,
    editUserProfileSaga
  );
}

function* UpdateUserAvatarSaga(action: any): SagaIterator {
  try {
    const response = yield call(updateUserAvatarAPI, action.payload);
    const payload: any = response.data;
    yield put(
      userProfileApiResponseSuccess(
        UserProfileActionTypes.UPDATE_USER_AVATAR,
        payload
      )
    );
  } catch (error: any) {
    yield put(
      userProfileApiResponseError(
        UserProfileActionTypes.UPDATE_USER_AVATAR,
        error
      )
    );
  }
}

export function* watchUpdateUserAvatarSaga() {
  yield takeEvery(
    UserProfileActionTypes.UPDATE_USER_AVATAR,
    UpdateUserAvatarSaga
  );
}

function* userProfileSaga() {
  yield all([fork(watchFetchUserProfile), fork(watchEditUserProfileSaga), fork(watchUpdateUserAvatarSaga)]);
}

export default userProfileSaga;
