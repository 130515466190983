import { CategoryActionTypes } from "./constants";

const INIT_STATE = {
  categoryDetails: [],
  loading: false,
  error: null,
  is_add: false,
  successMessage: null,
  editSuccessMessage: null,
  is_edit: false,
  is_deleted: false,
};

interface categoryData {
  categoryDetails: any;
  loading: boolean;
  error: string | null;
  is_add: boolean;
  successMessage: any;
  editSuccessMessage: any;
  is_edit: boolean;
  is_deleted?: boolean;
}

export interface ProductActionType {
  type:
    | CategoryActionTypes.API_RESPONSE_SUCCESS
    | CategoryActionTypes.API_RESPONSE_ERROR
    | CategoryActionTypes.FETCH_CATEGORY
    | CategoryActionTypes.ADD_CATEGORY
    | CategoryActionTypes.EDIT_CATEGORY
    | CategoryActionTypes.DELETE_CATEGORY;
  payload: {
    actionType?: string;
    data?: categoryData | {};
    error?: string;
  };
}

interface State {
  categoryDetails?: categoryData | {};
  loading?: boolean;
  value?: boolean;
  is_add?: boolean;
  successMessage?: any;
  editSuccessMessage: any;
  is_edit: boolean;
  is_deleted?: boolean;
}

const CategoryReducer = (
  state: State = INIT_STATE,
  action: ProductActionType
): any => {
  switch (action.type) {
    case CategoryActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case CategoryActionTypes.FETCH_CATEGORY: {
          return {
            ...state,
            categoryDetails: action.payload.data,
            loading: false,
          };
        }

        case CategoryActionTypes.ADD_CATEGORY: {
          return {
            ...state,
            loading: false,
            successMessage: action.payload.data,
            is_add: false,
          };
        }

        case CategoryActionTypes.EDIT_CATEGORY: {
          return {
            ...state,
            loading: false,
            editSuccessMessage: action.payload.data,
            is_edit: false,
          };
        }

        case CategoryActionTypes.DELETE_CATEGORY: {
          return {
            ...state,
            loading: false,
            is_delete: false,
          };
        }

        default:
          return { ...state };
      }

    case CategoryActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case CategoryActionTypes.FETCH_CATEGORY: {
          return {
            ...state,
            loading: false,
          };
        }

        case CategoryActionTypes.ADD_CATEGORY: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            is_add: false,
          };
        }

        case CategoryActionTypes.EDIT_CATEGORY: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            is_edit: false,
          };
        }

        case CategoryActionTypes.DELETE_CATEGORY: {
          return {
            ...state,
            loading: false,
            is_delete: false,
          };
        }

        default:
          return { ...state };
      }

    case CategoryActionTypes.FETCH_CATEGORY:
      return { ...state, loading: true };

    case CategoryActionTypes.ADD_CATEGORY:
      return { ...state, loading: true, is_add: true };

    case CategoryActionTypes.ADD_CATEGORY:
      return { ...state, loading: true, is_edit: true };

    case CategoryActionTypes.DELETE_CATEGORY:
      return { ...state, loading: true, is_delete: true };
    default:
      return { ...state };
  }
};
export default CategoryReducer;
