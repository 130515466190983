import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { Link } from "react-router-dom";

interface CartSummaryTypes {
  gross_total?: number;
  discount?: number;
  shipping_charge?: number;
  tax?: number;
  net_total?: number;
}

// summary
const CartSummary = (props: { summary: CartSummaryTypes }) => {
  const summary: any = props.summary || {};

  return (
    <>
      <div className="border p-3 mt-4 mt-lg-0 rounded">
        <h4 className="header-title mb-3">Order Summary</h4>

        <div className="table-responsive">
          <table className="table mb-0">
            <tbody>
              <tr>
                <td>Grand Total :</td>
                <td>{summary.gross_total.toFixed(2)}</td>
              </tr>
              {/* <tr>
                <td>Discount : </td>
                <td>{summary.discount!.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Shipping Charge :</td>
                <td>{summary.shipping_charge!.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Estimated Tax : </td>
                <td>{summary.tax!.toFixed(2)}</td>
              </tr> */}
              <tr>
                <th>Total :</th>
                <th>
                  ₹ {parseFloat(summary.net_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

interface ShowOrderDetailsProps {
  show: boolean;
  orderDetails: any;
  onHide: () => void;
}

const MyOrderDetails = ({
  show,
  orderDetails,
  onHide,
}: ShowOrderDetailsProps) => {
  // const [orderDetailList, setOrderDetailList] = useState<any>(orderDetails);
  const [summary, setSummary] = useState<CartSummaryTypes>({
    gross_total: 0.0,
    discount: 0.0,
    shipping_charge: 0,
    tax: 0,
    net_total: 0,
  });

  useEffect(() => {
    // const totalBill = orderDetails?.wm_shopping_list_items.reduce(
    //   (total: any, item: any) => {
    //     const { wm_product, qty } = item;
    //     return total + parseInt(wm_product?.price) * parseInt(qty);
    //   },
    //   0
    // );

    setSummary({
      ...summary,
      gross_total: parseInt(orderDetails?.total_amount),
      net_total: parseInt(orderDetails?.total_amount),
    });
  }, [])

  return (
    <Modal
      show={show}
      size="xl"
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-dialog-centered modal-dialog-scrollable"
      centered
    >
      <Modal.Header className="bg-light" onHide={onHide} closeButton>
        <Modal.Title className="m-0">
          Show Order Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={8}>
                    <div className="table-responsive">
                      <table className="table table-borderless table-centered mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                            <th style={{ width: "50px" }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(orderDetails?.wm_user_order_items || []).map((item: any, idx: any) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <img
                                    src={
                                      item?.wm_product?.wm_product_photos && item?.wm_product?.wm_product_photos.length > 0 ? item?.wm_product?.wm_product_photos[0]?.photo_url :
                                        "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-5.png"
                                    }
                                    alt={item.name}
                                    title="contact-img"
                                    className="rounded me-3"
                                    height="48"
                                  />

                                  <p className="m-0 d-inline-block align-middle font-16">
                                    <Link
                                      to=""
                                      // to="/apps/ecommerce/product-details"
                                      className="text-body"
                                    >
                                      {item?.wm_product?.product_name}
                                    </Link>
                                    <br />
                                    <small className="me-2">
                                      <b>Size:</b> {item?.wm_product?.attribute_size}{" "}
                                    </small>
                                    <small>
                                      <b>Color:</b> <span className="text-capitalize">{item?.wm_product?.attribute_color}{" "}</span>
                                    </small>
                                  </p>
                                </td>
                                <td>{parseInt(item?.wm_product?.rrr_price).toFixed(2)}</td>
                                <td>
                                  {item.qty}
                                </td>
                                <td>{parseInt(item?.wm_product?.rrr_price) * (item?.qty).toFixed(2)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <CartSummary summary={summary} />
                  </Col>
                </Row>

                <div className="text-end mt-3">
                  <button className="btn btn-success" onClick={() => { onHide() }}>
                    Close
                  </button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
export default MyOrderDetails;
