import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

interface CustomPaginationProps {
  tableProps: any;
  pageSize: any;
  sizePerPageList?: { text: string; value: number }[];
  totalPages: number; // Total pages calculated from API
  currentPage: number; // Current page index (0-based)
  onPageChange: (pageIndex: number) => void; // 0-based index
  onPageSizeChange: (size: number) => void;
}

const CustomPagination = ({ tableProps, pageSize, sizePerPageList, totalPages, currentPage, onPageChange, onPageSizeChange }: CustomPaginationProps) => {
  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const handlePageSizeChange = (size: number) => {
    onPageSizeChange(size);
    onPageChange(0); // Reset to first page
  };

  // Function to calculate visible pages 
  const getVisiblePages = useCallback((page: number, total: number) => {
    if (total < 7) {
      return Array.from({ length: total }, (_, i) => i + 1);
    } else {
      if (page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  }, []);

  // Handle page changes
  // const changePage = (page: number) => {
  //   if (page < 0 || page >= totalPages) return; // Prevent invalid page index
  //   if (page === currentPage) return;
  //   onPageChange(page);
  //   tableProps.gotoPage(page);
  // };

  const changePage = (page: number) => {
    if (page < 0 || page >= totalPages) return; // Prevent invalid page index
    if (page === currentPage) return;
    onPageChange(page);
    tableProps.gotoPage(page);
  };

  // Update visible pages when currentPage or totalPages changes
  useEffect(() => {
    setVisiblePages(getVisiblePages(currentPage + 1, totalPages)); // Convert to 1-based for calculation
  }, [currentPage, totalPages, getVisiblePages]);

  return (
    <div className="d-lg-flex align-items-center text-center pb-1">
      {sizePerPageList && sizePerPageList.length > 0 && (
        <div className="d-inline-block me-3">
          <label className="me-1">Display :</label>
          <select
            value={pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            className="form-select d-inline-block w-auto"
          >
            {sizePerPageList.map((pageSize, index) => (
              <option key={index} value={pageSize.value}>
                {pageSize.text}
              </option>
            ))}
          </select>

        </div>
      )}

      <span className="me-3">
        Page
        <strong>
          {currentPage + 1} of {totalPages}
        </strong>
      </span>

      <span className="d-inline-block align-items-center text-sm-start text-center my-sm-0 my-2">
        <div className="d-flex justify-content-center align-items-center">
          <label className="form-label mb-0">Go to page : </label>
          <input
            type="number"
            value={currentPage + 1} // Display 1-based index
            min="1"
            max={totalPages}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0; // Convert to 0-based index
              if (page >= 0 && page < totalPages) {
                onPageChange(page);
                tableProps.gotoPage(page);
              }
            }}
            className="form-control w-auto ms-1 d-inline-block"
          />
        </div>
      </span>

      <ul className="pagination pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
        <li
          className={classNames("page-item", "paginate_button", "previous", {
            disabled: currentPage === 0,
          })}
          onClick={() => {
            if (currentPage > 0) changePage(currentPage - 1);
          }}
        >
          <Link to="#" className="page-link">
            <i className="mdi mdi-chevron-left"></i>
          </Link>
        </li>
        {visiblePages.map((page, index, array) => (
          <React.Fragment key={page}>
            {array[index - 1] + 1 < page && (
              <li className="page-item disabled d-none d-xl-inline-block">
                <Link to="#" className="page-link">...</Link>
              </li>
            )}
            <li
              className={classNames("page-item", { active: currentPage === page - 1 })}
              onClick={() => changePage(page - 1)} // Convert to 0-based index
            >
              <Link to="#" className="page-link"
                // style={{ backgroundColor: "#050A30" }}
                style={{
                  backgroundColor: "#050A30",
                  color: currentPage === page - 1 ? "#ffffff" : "#acacac",
                }}>
                {page}
              </Link>
            </li>
          </React.Fragment>
        ))}
        <li
          className={classNames("page-item", "paginate_button", "next", {
            disabled: currentPage === totalPages - 1,
          })}
          onClick={() => {
            if (currentPage < totalPages - 1) changePage(currentPage + 1);
          }}
        >
          <Link to="#" className="page-link">
            <i className="mdi mdi-chevron-right"></i>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default CustomPagination;
