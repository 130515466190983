import { error } from "console";
import { UserProductActionTypes } from "./constants";

const INIT_STATE = {
  message: null,
  loading: false,
  error: null,
  isAddToCart: false,
  AddToCartMessage: null,
  productDetails: null,
};

export interface UserProductActionType {
  type:
  | UserProductActionTypes.API_RESPONSE_SUCCESS
  | UserProductActionTypes.API_RESPONSE_ERROR
  | UserProductActionTypes.GET_USER_PRODUCT_LIST
  | UserProductActionTypes.GET_USER_PRODUCT_DETAILS
  | UserProductActionTypes.ADD_TO_CART;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
  };
}

interface State {
  message?: any;
  loading?: boolean;
  value?: boolean;
  isAddToCart?: boolean;
  AddToCartMessage?: any;
  productDetails?: any;
}

const UserProductReducer = (
  state: State = INIT_STATE,
  action: UserProductActionType
): any => {
  switch (action.type) {
    case UserProductActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UserProductActionTypes.GET_USER_PRODUCT_LIST: {
          return {
            ...state,
            message: action.payload.data,
            loading: false,
          };
        }

        case UserProductActionTypes.ADD_TO_CART: {
          return {
            ...state,
            AddToCartMessage: action.payload.data,
            loading: false,
            isAddToCart: true
          };
        }

        case UserProductActionTypes.GET_USER_PRODUCT_DETAILS: {
          return {
            ...state,
            productDetails: action.payload.data,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case UserProductActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UserProductActionTypes.GET_USER_PRODUCT_LIST: {
          return {
            ...state,
            error: action.payload.data,
            loading: false,
          };
        }

        case UserProductActionTypes.ADD_TO_CART: {
          return {
            ...state,
            error: action.payload.data,
            isAddToCart: false,
            loading: false,
          };
        }

        case UserProductActionTypes.GET_USER_PRODUCT_DETAILS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case UserProductActionTypes.GET_USER_PRODUCT_LIST:
      return { ...state, loading: true };

    case UserProductActionTypes.ADD_TO_CART:
      return { ...state, loading: true, isAddToCart: false };

    case UserProductActionTypes.GET_USER_PRODUCT_DETAILS:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};
export default UserProductReducer;
