import { UserProfileActionTypes } from "./constants";

const INIT_STATE = {
  userProfileDetails: null,
  editUserProfileDetails: null,
  loading: false,
  error: null,
  // avatarData: null,
};

export interface UserProfileActionType {
  type:
  | UserProfileActionTypes.API_RESPONSE_SUCCESS
  | UserProfileActionTypes.API_RESPONSE_ERROR
  | UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST
  | UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST
  | UserProfileActionTypes.UPDATE_USER_AVATAR;
  payload: {
    actionType?: string;
    data?: any;
    error?: string;
    // avatarData?: any;
  };
}

interface State {
  userProfileDetails?: any;
  editUserProfileDetails?: any;
  loading?: boolean;
  value?: boolean;
  // avatarData?: any;
}

const UserProfileReducer = (
  state: State = INIT_STATE,
  action: UserProfileActionType
): any => {
  switch (action.type) {
    case UserProfileActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST: {
          return {
            ...state,
            userProfileDetails: action.payload.data,
            loading: false,
          };
        }

        case UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST: {
          return {
            ...state,
            editUserProfileDetails: action.payload.data,
            loading: false,
          };
        }
        case UserProfileActionTypes.UPDATE_USER_AVATAR: {
          return {
            ...state,
            avatarData: action.payload.data,
            loading: false,
          };
        }


        default:
          return { ...state };
      }

    case UserProfileActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST: {
          return {
            ...state,
            loading: false,
          };
        }

        case UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case UserProfileActionTypes.UPDATE_USER_AVATAR: {
          return {
            ...state,
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST:
      return { ...state, loading: true };

    case UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST:
      return { ...state, loading: true };

    case UserProfileActionTypes.UPDATE_USER_AVATAR:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};
export default UserProfileReducer;
