import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
// components

import { cartItems, CartItemTypes } from "../apps/Ecommerce/data";
import PageTitle from "../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  AddToCartAction,
  GetRemoveProductFromCartAction,
  GetShoppingListAction,
  OrderAddToCartAction,
  PlaceOrderAction,
} from "../../redux/actions";
import Swal from "sweetalert2";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import notify from "../../components/Notification/notify";
import Spinner from "../../components/Spinner";
import { useDebounce } from "../../hooks/useDebounce";

interface CartSummaryTypes {
  gross_total?: number;
  discount?: number;
  shipping_charge?: number;
  tax?: number;
  net_total?: number;
}

// summary
const CartSummary = (props: { summary: CartSummaryTypes }) => {
  const summary: any = props.summary || {};

  return (
    <>
      <div className="border p-3 mt-4 mt-lg-0 rounded">
        <h4 className="header-title mb-3">Order Summary</h4>

        <div className="table-responsive">
          <table className="table mb-0">
            <tbody>
              <tr>
                <td>Grand Total :</td>
                <td>{summary.gross_total > 0 ? summary.gross_total.toFixed(2) : 0}</td>
              </tr>
              <tr>
                <td>Discount : </td>
                <td>{summary.discount > 0 ? summary.discount.toFixed(2) : 0}</td>
              </tr>
              <tr>
                <td>Shipping Charge :</td>
                <td>{summary.shipping_charge > 0 ? summary.shipping_charge.toFixed(2) : 0}</td>
              </tr>
              <tr>
                <td>Estimated Tax : </td>
                <td>{summary.tax > 0 ? summary.tax.toFixed(2) : 0}</td>
              </tr>
              <tr>
                <th>Total :</th>
                <th>
                  {/* ₹ {summary.gross_total.toFixed(2)} */}
                  ₹ {parseFloat(summary?.gross_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </th>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

// Cart
const MyCart = () => {
  const [items, setItems] = useState<CartItemTypes[]>(cartItems);
  const [shoppingList, setShoppingList] = useState<any>([]);

  const [summary, setSummary] = useState<CartSummaryTypes>({
    gross_total: 0.0,
    discount: 0.0,
    shipping_charge: 0,
    tax: 0,
    net_total: 0,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const {
    message,
    loading,
    error,
    deleteMessage,
    isDeleted,
    isOrderCart,
    orderCartMsg,
    isPlaced,
    placeOrderDetails,
  } = useSelector((state: RootState) => ({
    message: state.UserCartReducer.message,
    loading: state.UserCartReducer.loading,
    error: state.UserCartReducer.error,
    isDeleted: state.UserCartReducer.isDeleted,
    deleteMessage: state.UserCartReducer.deleteMessage,
    isOrderCart: state.UserCartReducer.isOrderCart,
    orderCartMsg: state.UserCartReducer.orderCartMsg,
    isPlaced: state.UserCartReducer.isPlaced,
    placeOrderDetails: state.UserCartReducer.placeOrderDetails,
  }));

  const [debouncedFetch, setDebouncedFetch] = useState(true);
  const debouncedFetchValue = useDebounce(debouncedFetch, 500); // 500ms debounce

  useEffect(() => {
    setDebouncedFetch(true); // Trigger fetching data
  }, []);


  useEffect(() => {
    if (debouncedFetchValue) {
      dispatch(GetShoppingListAction());
      setDebouncedFetch(false); // Reset to avoid repeated fetches
    }
  }, [debouncedFetchValue]);

  useEffect(() => {
    if (
      message?.statusCode === 200 &&
      message?.data !== undefined &&
      message?.data !== null
    ) {
      setShoppingList(message?.data);
      const totalBill = message?.data?.wm_shopping_list_items.reduce(
        (total: any, item: any) => {
          const { wm_product, qty } = item;
          return total + parseInt(wm_product?.rrr_price) * parseInt(qty);
        },
        0
      );
      setSummary({
        ...summary,
        gross_total: totalBill,
      });
    }

    if (
      message?.statusCode === 200 &&
      message?.data !== undefined &&
      message?.data === null
    ) {
      setShoppingList([]);
    }
  }, [message]);


  useEffect(() => {
    if (
      orderCartMsg !== null &&
      orderCartMsg !== undefined &&
      orderCartMsg.statusCode === 200
    ) {
      dispatch(GetShoppingListAction());
    }

    if (
      deleteMessage !== null &&
      deleteMessage !== undefined &&
      deleteMessage.statusCode === 200
    ) {
      dispatch(GetShoppingListAction());
    }
  }, [orderCartMsg, dispatch, deleteMessage]);

  useEffect(() => {
    if (shoppingList) {
      const totalBill = Object.keys(shoppingList).length && shoppingList?.wm_shopping_list_items.reduce(
        (total: any, item: any) => {
          const { wm_product, qty } = item;
          return total + parseInt(wm_product?.rrr_price) * parseInt(qty);
        },
        0
      );
      setSummary({
        ...summary,
        gross_total: totalBill,
      });
    } else {
      setSummary({
        gross_total: 0.0,
        discount: 0.0,
        shipping_charge: 0,
        tax: 0,
        net_total: 0,
      });
    }
  }, [shoppingList]);

  /**
   * On quantity changed
   */
  const onQtyChange = (e: any, item: any) => {
    const productRes = {
      product_id: item.product_id,
      qty: e.target.value,
    };
    dispatch(OrderAddToCartAction(productRes));
  };

  /**
   * Removes item from cart
   */
  const removeItem = (e: any, id: any) => {
    const dataRes = {
      product_id: id,
    };
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Dispatch the delete action
          await dispatch(GetRemoveProductFromCartAction(dataRes));
          // Show success message after dispatching action

          // if(deleteMessage.statusCode === 200 && deleteMessage !== null && deleteMessage !== undefined){
          // Swal.fire(
          //   "Deleted!",
          //   "Your Product has been removed SuccessFully.",
          //   "success"
          // ).then(async () => {
          //   // Dispatch fetch_data action after success message is shown
          //   // await dispatch(GetShoppingListAction());
          // });
          // }else{
          //   Swal.fire("Error", "Something went wrong!", "error");
          // }
        } else {
          Swal.fire("Cancelled", "Your Product has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  /**
   * Adjust the cart
   */
  const _adjustCart = (localItems: any) => {
    // calculate gross and other total
    var newGrossTotal = 0;
    for (const item of localItems) {
      newGrossTotal += item.total;
    }

    var newNetTotel =
      newGrossTotal -
      summary.discount! +
      summary.shipping_charge! +
      summary.tax!;
    setItems(localItems);
    setSummary({
      ...summary,
      gross_total: newGrossTotal,
      net_total: newNetTotel,
    });
  };

  //Get Image
  const getImage = (data: any) => {
    const arrayBufferToBase64 = (buffer: any) => {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    };

    const base64String = arrayBufferToBase64(data);
    const imageUrl = `data:image/png;base64,${base64String}`;
    return imageUrl;
  };

  //Place Order Function
  const handlePlaceOrder = (shoppingListId: any) => {
    const shoppingId = {
      shopping_list_id: shoppingListId,
    };
    dispatch(PlaceOrderAction(shoppingId));
    setShoppingList([])
    // navigate("/user/myOrders");
  };

  const totalAmount = (price: any, qty: any) => {
    const total: any = price * qty
    return parseFloat(total).toFixed(2)
  }
  useEffect(() => {
    if (
      placeOrderDetails?.statusCode === 200 &&
      placeOrderDetails?.data !== undefined &&
      placeOrderDetails?.data !== null
    ) {
      // navigate("/user/myOrders");
    }
  }, [isPlaced]);

  // Define validation schema
  const validationSchema = Yup.object({
    qty: Yup.number()
      .min(1, 'Quantity must be at least 1')
      .required('Quantity is required')
  });

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Ecommerce", path: "/user/myCart" },
          {
            label: "Shopping Cart",
            path: "/user/myCart",
            active: true,
          },
        ]}
        title={"Shopping Cart"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body >
              <Row>
                <Col lg={8}>
                  <div className="table-responsive">
                    <table className="table table-borderless table-centered mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>Product</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                          <th style={{ width: "50px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          loading ? (
                            <tr>
                              <td colSpan={5} className="text-center p-5">
                                <Row>
                                  <Col>
                                    <Card>
                                      <Card.Body>
                                        <div className="text-center">
                                          <Spinner className="m-2" color="primary" />
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ) :
                            (shoppingList?.wm_shopping_list_items || []).length > 0 ? (
                              (shoppingList?.wm_shopping_list_items || []).map((item: any, idx: any) => (
                                <tr key={idx}>
                                  <td>
                                    <img
                                      src={
                                        item?.wm_product?.wm_product_photos && item?.wm_product?.wm_product_photos.length > 0
                                          ? item?.wm_product?.wm_product_photos[0]?.photo_url
                                          : "https://coderthemes.com/ubold/layouts/default/assets/images/products/product-5.png"
                                      }
                                      alt={item?.wm_product?.product_name}
                                      title="contact-img"
                                      className="rounded me-3"
                                      height="48"
                                    />
                                    <p className="m-0 d-inline-block align-middle font-16">
                                      <Link to="" className="text-body">
                                        {item?.wm_product?.product_name}
                                      </Link>
                                      <br />
                                      <small className="me-2">
                                        <b>Size:</b> {item?.wm_product?.attribute_size}{" "}
                                      </small>
                                      <small>
                                        <b>Color:</b> <span className="text-capitalize">
                                          {item?.wm_product?.attribute_color}{" "}
                                        </span>
                                      </small>
                                    </p>
                                  </td>
                                  <td>
                                    {parseFloat(item?.wm_product?.rrr_price).toFixed(2)}
                                  </td>
                                  <td>
                                    <Formik
                                      enableReinitialize
                                      initialValues={{ qty: item.qty }}
                                      validationSchema={validationSchema}
                                      onSubmit={(values, { setSubmitting }) => {
                                        // Handle form submission here
                                        setSubmitting(false);
                                      }}
                                    >
                                      {({ values, setFieldValue, setFieldError }) => (
                                        <Form>
                                          <Field name="qty">
                                            {({ field, form }: any) => (
                                              <div>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  {...field}
                                                  value={field.value || item.qty}
                                                  min={1}
                                                  placeholder="Qty"
                                                  style={{ width: '90px' }}
                                                  onBlur={(e) => {
                                                    const value = parseInt(e.target.value, 10);
                                                    if (isNaN(value) || value < 1) {
                                                      setFieldError('quantity', 'Quantity must be at least 1 and a number');
                                                      notify('There should be at least one quantity.', 'error');
                                                    } else if (value > item?.wm_product?.stock) {
                                                      setFieldError('quantity', `Cannot add more than ${item?.wm_product?.stock} items.`);
                                                      notify(`Cannot add more than ${item?.wm_product?.stock} items to the cart.`, 'error');
                                                    } else {
                                                      setFieldValue('qty', value);
                                                      const productRes = {
                                                        product_id: item.product_id,
                                                        qty: e.target.value,
                                                      };
                                                      dispatch(OrderAddToCartAction(productRes));
                                                    }
                                                  }}
                                                  onChange={(e) => {
                                                    const value = parseInt(e.target.value, 10);
                                                    if (isNaN(value) || value < 1) {
                                                      setFieldError('quantity', 'Quantity must be at least 1 and a number');
                                                      notify('There should be at least one quantity.', 'error');
                                                    } else if (value > item?.wm_product?.stock) {
                                                      setFieldError('quantity', `Cannot add more than ${item?.wm_product?.stock} items.`);
                                                      notify(`Cannot add more than ${item?.wm_product?.stock} items to the cart.`, 'error');
                                                    } else {
                                                      setFieldValue('qty', value);
                                                      // const productRes = {
                                                      //   product_id: item.product_id,
                                                      //   qty: e.target.value,
                                                      // };
                                                      // dispatch(OrderAddToCartAction(productRes));
                                                    }
                                                  }}
                                                />
                                                <ErrorMessage name="qty" component="div" className="error-message" />
                                              </div>
                                            )}
                                          </Field>
                                        </Form>
                                      )}
                                    </Formik>
                                  </td>
                                  <td>
                                    {totalAmount(parseFloat(item?.wm_product?.rrr_price), item.qty)}
                                  </td>
                                  <td>
                                    <Button
                                      onClick={(e) => {
                                        removeItem(e, item?.wm_product?.product_id);
                                      }}
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center p-5">
                                  <h2> No items </h2>
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </table>
                  </div>

                  <Row className="mt-4">
                    <Col sm={6}>
                      <Link
                        to="/user/explore"
                        className="btn text-muted d-none d-sm-inline-block btn-link fw-semibold"
                      >
                        <i className="mdi mdi-arrow-left"></i> Continue Shopping{" "}
                      </Link>
                    </Col>
                    <Col sm={6}>
                      <div className="text-sm-end">
                        <Button
                          className="btn btn-danger"
                          onClick={() => {
                            handlePlaceOrder(shoppingList?.shopping_list_id);
                          }}
                          disabled={(shoppingList?.wm_shopping_list_items || []).length === 0}
                        >
                          <i className="mdi mdi-cart-plus me-1"></i> Place Order{" "}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <CartSummary summary={summary} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MyCart;
