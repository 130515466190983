import { UserProfileActionTypes } from "./constants";

export interface UserProfileActionType {
  type:
  | UserProfileActionTypes.API_RESPONSE_SUCCESS
  | UserProfileActionTypes.API_RESPONSE_ERROR
  | UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST
  | UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST
  | UserProfileActionTypes.UPDATE_USER_AVATAR;
  payload: {} | string;
}

// common success
export const userProfileApiResponseSuccess = (
  actionType: string,
  data: any | {}
): UserProfileActionType => ({
  type: UserProfileActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const userProfileApiResponseError = (
  actionType: string,
  error: string
): UserProfileActionType => ({
  type: UserProfileActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Fetch request Admin Profile List
export const fetchUserProfileAction = () => ({
  type: UserProfileActionTypes.FETCH_USER_PROFILE_REQUEST,
});

//Action request Customers List
export const editUserProfileAction = (editProfileData: any) => ({
  type: UserProfileActionTypes.EDIT_USER_PROFILE_REQUEST,
  payload: editProfileData,
});

//Fetch request Admin Profile image
export const UserUpdateAvatar = (formData: any) => ({
  type: UserProfileActionTypes.UPDATE_USER_AVATAR,
  payload: formData
});
