import React, { useEffect, useState } from "react";
import Nestable from "react-nestable";
import { Row, Col, Card, Button } from "react-bootstrap";
import AddPermission from "./AddPermission";
// styles
import "react-nestable/dist/styles/index.css";

// components
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";

import { customers } from "../../apps/Ecommerce/data";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  AddPermissionsAction,
  DeletePermissionsAction,
  fetchPermissionsAction,
} from "../../../redux/permissions/actions";
import Swal from "sweetalert2";
import notify from "../../../components/Notification/notify";
import NotificationContainer from "../../../components/Notification/NotificationContainer";
import AddPermissionForm from "./AddPermissionForm";
import CustomTable from "../../../components/CustomTable";

/* name column render */
const NameColumn = ({ row }: { row: any }) => {
  return (
    <div className="table-user">
      <img src={row.original.avatar} alt="" className="me-2 rounded-circle" />
      <Link to="#" className="text-body fw-semibold">
        {row.original.name}
      </Link>
    </div>
  );
};

/* status column render */
const StatusColumn = ({ row }: { row: any }) => {
  return (
    <React.Fragment>
      <span
        className={classNames("badge", {
          "bg-soft-success text-success": row.original.status === "Active",
          "bg-soft-danger text-danger": row.original.status === "Blocked",
        })}
      >
        {row.original.status}
      </span>
    </React.Fragment>
  );
};

/* action column render */
const ActionColumn = () => {
  return (
    <React.Fragment>
      {/* <Link to="#" className="action-icon">
          {" "}
          <i className="mdi mdi-eye"></i>
        </Link>
        <Link to="#" className="action-icon">
          {" "}
          <i className="mdi mdi-square-edit-outline"></i>
        </Link> */}
      <Link to="#" className="action-icon">
        <Button className="btn btn-danger mb-2 me-1">
          <i className="mdi mdi-delete"></i>
        </Button>
      </Link>
    </React.Fragment>
  );
};

interface RenderItemProps {
  item: Record<string, any>;
  collapseIcon: React.ReactNode;
  handler: React.ReactNode;
}

const PermissionSettings = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [permissionList, setPermissionList] = useState<any>([]);
  const [isSubAdmin, setIsSubAdmin] = useState<boolean>(false);


  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // give page size
  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "50",
      value: 50,
    }
    // {
    //   text: "All",
    //   value: list.length,
    // },
  ];

  useEffect(() => {
    let userData: any = sessionStorage.getItem("session_user_details");
    userData = JSON.parse(userData);
    if (userData?.user_role === 2) {
      setIsSubAdmin(true);
    }
  }, [isSubAdmin]);
  const {
    permissionDetails,
    loading,
    error,
    is_add,
    successMessage,
    is_deleted,
  } = useSelector((state: RootState) => ({
    permissionDetails: state.PermissionReducer.permissionDetails,
    loading: state.PermissionReducer.loading,
    error: state.PermissionReducer.error,
    successMessage: state.PermissionReducer.successMessage,
    is_deleted: state.PermissionReducer.is_deleted,
    is_add: state.PermissionReducer.is_add,
  }));
  //Fetch subAdmin List useEfffect
  useEffect(() => {
    dispatch(fetchPermissionsAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      permissionDetails?.statusCode === 200 &&
      permissionDetails?.data.length > 0 &&
      permissionDetails?.data !== undefined &&
      permissionDetails?.data !== null
    ) {
      setPermissionList(permissionDetails?.data);
      setTotalRecords(permissionDetails?.data?.length);
    }
  }, [permissionDetails]);

  //Delete Permission Dispatch Call
  const handleDelete = (data: any) => {
    const id: any = data?.permission_id;
    try {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Dispatch the delete action
          dispatch(DeletePermissionsAction(id));
          // Show success message after dispatching action
          Swal.fire("Deleted!", "Your item has been deleted.", "success").then(
            () => {
              // Dispatch fetch_data action after success message is shown
              dispatch(fetchPermissionsAction());
            }
          );
        } else {
          Swal.fire("Cancelled", "Your action has been cancelled :)", "info");
        }
      });
    } catch (error: any) {
      Swal.fire("Error", "Something went wrong!", "error");
    }
  };

  const columns = [
    {
      Header: "Permission Name",
      accessor: "name",
      sort: false,
      classes: "table-user text-dark",
      Cell: ({ value }: any) => (
        <div>{value.charAt(0).toUpperCase() + value.slice(1)}</div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      // classes: "table-action",
      Cell: ({ row }: any) => (
        <>
          {!isSubAdmin && (
            <Link
              to="#"
              className="action-icon mb-2 text-dark"
              onClick={() => handleDelete(row.original)}
            >
              <i className="mdi mdi-delete"></i>
            </Link>
          )}
        </>
      ),
    },
  ];

  const onCloseModal = () => setShow(false);
  const onOpenModal = () => setShow(true);

  useEffect(() => {
    if (
      is_add === false &&
      successMessage !== undefined &&
      successMessage !== null
    ) {
      onCloseModal();
      dispatch(fetchPermissionsAction());
    }
  }, [is_add, successMessage, dispatch]);


  const [reqBody, setReqBody] = useState<any>({
    page_record: "10",
    page_no: "1",
    search: "",
    filter_by: "",
    sort_field: "created_datetime",
    sort_order: "desc",
  });

  // Function to handle search using API call
  const searchOrderRequest = async (value: string) => {
    // const updatedReqBody = {
    //   ...reqBody,
    //   search: value,
    // };
    // setReqBody(updatedReqBody);
    // await InvokeAPI(updatedReqBody);
  };

  const sortOrderRequest = async (value: any) => {
    // let updatedReqBody = {
    //   ...reqBody,
    //   sort_field: value.column ?? "created_datetime",
    //   sort_order: value.direction ?? "desc",
    // };
    // if (!value.direction) {
    //   updatedReqBody = {
    //     ...reqBody,
    //     sort_field: "created_datetime",
    //     sort_order: "desc",
    //   };
    // }
    // setReqBody(updatedReqBody);
    // await InvokeAPI(updatedReqBody);
  };

  // ? API Call
  const InvokeAPI = async (reqBody: any) => {
    try {
      dispatch(fetchPermissionsAction());
      setPermissionList(permissionDetails?.data);
      setTotalRecords(permissionDetails?.data.length); // Store total record count
    } catch (error) {
      console.log("error :>> ", error);
    }
  };


  const fetchData = async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    const updatedReqBody = {
      ...reqBody,
      page_no: pageIndex + 1,
      page_record: pageSize,
    };
    setReqBody(updatedReqBody);
    await InvokeAPI(updatedReqBody);
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Permission Settings",
            path: "/apps/ProductSettings/PermissionSettings",
            active: true,
          },
        ]}
        title={"Permission Settings"}
      />

      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body style={{ backgroundColor: "lightblue" }}>
              <Row className="align-items-center">
                <Col lg={8}></Col>

                <Col lg={4}>
                  <div className="text-lg-end mt-xl-0 mt-2">
                    <Button
                      className="btn btn-primary"
                      onClick={onOpenModal}
                      style={{ backgroundColor: "#13219D" }}
                    >
                      <i className="mdi mdi-plus-circle me-1"></i> Add Permission
                    </Button>
                  </div>
                </Col>
              </Row>

              <Card.Body className="px-0">

                {
                  permissionList &&
                  <CustomTable
                    columns={columns}
                    data={permissionList}
                    pageSize={pageSize}
                    pagination={false}
                    theadClass="table-light"
                    searchBoxClass="mb-2"
                    isSearchable={false}
                    isSortable={false}
                    searchFunction={searchOrderRequest}
                    sortFunction={sortOrderRequest}
                    fetchData={fetchData}
                    sizePerPageList={sizePerPageList}
                    totalRecords={totalRecords}
                  />
                }

                {/* <CustomTable
                  columns={columns}
                  data={permissionList}
                  pageSize={10}
                  pagination={true}
                  theadClass="table-light"
                  searchBoxClass="mb-2"
                  // isSearchable={false}
                  isSortable={false}
                  searchFunction={searchOrderRequest}
                  sortFunction={sortOrderRequest}
                /> */}
                {/* <Table
                  columns={columns}
                  data={permissionList}
                  pageSize={12}
                  isSortable={true}
                  pagination={true}
                  // isSelectable={true}
                  tableClass="table-nowrap table-striped"
                /> */}
              </Card.Body>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <AddPermission
        show={show}
        onHide={onCloseModal}
        onSubmit={onHandleSubmit}
      /> */}

      <AddPermissionForm show={show} onHide={onCloseModal} />
    </>
  );
};

export default PermissionSettings;
