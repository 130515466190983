import React, { useEffect } from "react";
import { Button, Alert, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

//actions
import { verifyOTP } from "../../redux/actions";
import { RootState, AppDispatch } from "../../redux/store";

// components
import { VerticalForm, FormInput } from "../../components";

import AuthLayout from "./AuthLayout";

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-dark-50">
          {t("Back to")}{" "}
          <Link to={"/auth/login"} className="text-dark ms-1">
            <b>{t("Log in")}</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const OTPVerification = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const email = localStorage.getItem("forgot-email");

  const { loading, is_otp_verified, error, otpData } = useSelector(
    (state: RootState) => ({
      loading: state.Auth.loading,
      error: state.Auth.error,
      is_otp_verified: state.Auth.is_otp_verified,
      otpData: state.Auth.otpData,
    })
  );
  useEffect(() => {
    if (is_otp_verified && otpData) {
      navigate("/auth/set-NewPassword");
    } else {
      console.log("in else");
    }
  }, [is_otp_verified])


  const onSubmit = (formData: any) => {
    let reqBody = {
      email: email,
      client_otp: formData["otp"],
    }
    dispatch(verifyOTP(reqBody));
  };

  const schemaResolver = yupResolver(
    yup.object().shape({
      otp: yup.string().required(t("Please enter otp")),
    })
  );

  return (
    <>
      <AuthLayout
      // helpText={t(
      //   `OTP ensures secure access by verifying one-time codes via email ${email}.`
      //   `OTP ensures secure access by verifying one-time codes via email ${email}.`
      // )}
      // bottomLinks={<BottomLink />}
      >
        <div className="text-center w-75 m-auto">
          <h4 className="text-dark-50 text-center mt-3 mb-1">{t("Verification")} </h4>
          <p className="text-muted mb-4">
            {t(`You will get a OTP via email ${email}`)}
          </p>
          {/* <p className="text-muted mb-4">
            {t("Enter your password to access the admin.")} 
          </p> */}
        </div>

        <VerticalForm resolver={schemaResolver} onSubmit={onSubmit}>
          <FormInput
            label={t("OTP")}
            type="text"
            name="otp"
            placeholder={t("Enter your otp")}
            containerClass={"mb-3"}
          />
          {/* {error && (
            <Alert variant="danger" className="my-2">
              {error}
            </Alert>
          )} */}

          <div className="d-grid text-center">
            <Button variant="primary" type="submit">
              {t("Verify")}
            </Button>
          </div>
        </VerticalForm>

        <BottomLink />
      </AuthLayout>
    </>
  );
};

export default OTPVerification;
