const PRODUCT_STATUS = [
  {
    status: 1,
    name: "Active",
    class: "success",
  },
  {
    status: 2,
    name: "De-Active",
    class: "Danger",
  },
];

const ORDER_STATUS = [
 {
    status: 3,
    name: "Dispatch",
    class: "secondary",
    type: "order_status",
  },
  // {
  //   status: 2,
  //   name: "Rejected",
  //   class: "secondary",
  //   type: "order_status",
  // },
  {
    status: 4,
    name: "Delivered",
    class: "success",
    type: "order_status",
  },
];

const PAYMENT_STATUS = [
  {
    status: 0,
    name: "Due",
    class: "info",
  },
  {
    status: 1,
    name: "Paid",
    class: "success",
  },
];

const CUSTOMER_STATUS = [
  {
    status: 1,
    name: "Active",
    class: "success",
  },
  {
    status: 2,
    name: "De-active",
    class: "danger",
  },
];

const TASK_STATUS = [
  {
    status: 1,
    name: "Finished",
    class: "success",
  },
  {
    status: 0,
    name: "Pending",
    class: "danger",
  },
];

const SUB_ADMIN_STATUS = [
  {
    status: 1,
    name: "Active",
    class: "success",
  },
  {
    status: 2,
    name: "De-active",
    class: "danger",
  },
];

export {
  PRODUCT_STATUS,
  ORDER_STATUS,
  PAYMENT_STATUS,
  CUSTOMER_STATUS,
  TASK_STATUS,
  SUB_ADMIN_STATUS
};
