import { PermissionActionTypes } from "./constants";

const INIT_STATE = {
    permissionDetails: [],
    successMessage: null,
    loading: false,
    error: null,
    is_add: false,
    is_deleted: false,
}

interface subAdminData {
    permissionDetails: any,
    successMessage: any,
    loading: boolean,
    error: any,
    is_add: boolean,
    is_deleted: boolean,
}

export interface PermissionActionType {
    type:
    | PermissionActionTypes.API_RESPONSE_SUCCESS
    | PermissionActionTypes.API_RESPONSE_ERROR
    | PermissionActionTypes.FETCH_PERMISSION_REQUEST
    | PermissionActionTypes.ADD_PERMISSION_REQUEST
    | PermissionActionTypes.DELETE_PERMISSION_REQUEST
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    permissionDetails?: any
    successMessage?: any,
    loading?: boolean,
    value?: boolean,
    is_add?: boolean,
    is_deleted?: boolean,
}


const PermissionReducer = (state: State = INIT_STATE, action: PermissionActionType): any => {
    switch (action.type) {
        case PermissionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PermissionActionTypes.FETCH_PERMISSION_REQUEST: {
                    return {
                        ...state,
                        permissionDetails: action.payload.data,
                        loading: false,
                    };
                }

                case PermissionActionTypes.ADD_PERMISSION_REQUEST: {
                    return {
                        ...state,
                        loading: false,
                        successMessage: action.payload.data,
                        is_add: false,
                    };
                }

                case PermissionActionTypes.DELETE_PERMISSION_REQUEST: {
                    return {
                        ...state,
                        loading: false,
                        is_deleted: false,
                    };
                }
                default:
                    return {...state};
            }

        case PermissionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PermissionActionTypes.FETCH_PERMISSION_REQUEST: {
                    return {
                        ...state,
                        loading: false,
                    };
                }

                case PermissionActionTypes.ADD_PERMISSION_REQUEST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        is_add: false,
                    };
                }

                case PermissionActionTypes.DELETE_PERMISSION_REQUEST: {
                    return {
                        ...state,
                        loading: false,
                        is_deleted: false,
                    };
                }

                default:
                return { ...state };
            }
    
        
        case PermissionActionTypes.FETCH_PERMISSION_REQUEST:
            return { ...state, loading: true };

        case PermissionActionTypes.ADD_PERMISSION_REQUEST:
            return { ...state, loading: true, is_add: true };

        case PermissionActionTypes.DELETE_PERMISSION_REQUEST:
            return { ...state, loading: true, is_deleted: true };

        default:
            return { ...state }; 
    }
}
export default PermissionReducer;