import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageTitle from "../../../components/PageTitle";
import FileUploader from "../../../components/FileUploader";
import { FormInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { AdminProfileGet, UpdateAvatar, editProfileAction } from "../../../redux/actions";
import UserBox from "../Contacts/Profile/UserBox";
import Settings from "../Contacts/Profile/Settings";
import NotificationContainer from "../../../components/Notification/NotificationContainer";
import { updateAvatarAPI } from "../../../helpers/api/adminProfile";
import { ErrorMessage, Field, Formik } from "formik";

const NewAdminUpdateProfile = () => {
  const [editorState, setEditorState] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const [file, setFile] = useState<any>();
  const [userProfileImage, setUserProfileImage] = useState<any>();

  const { AdminData, editProfileDetails, avatarData } = useSelector((state: RootState) => ({
    AdminData: state.AdminProfileReducer.AdminData,
    editProfileDetails: state.AdminProfileReducer.editProfileDetails,
    avatarData: state.AdminProfileReducer.avatarData,
  }));

  useEffect(() => {
    if (
      AdminData?.status_code === 200 &&
      AdminData?.data !== undefined &&
      AdminData?.data !== null
    ) {
      setUserDetails(AdminData?.data);
      if (AdminData?.data?.profile_image) {
        const blob = new Blob([AdminData?.data?.profile_image.data], {
          type: "image/jpeg",
        });
        let url = URL.createObjectURL(blob);
        setUserProfileImage(url);
      }
    }
  }, [AdminData]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup.string().required("Please enter Last Name"),
    })
  );

  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    dispatch(AdminProfileGet());
  }, [dispatch]);

  useEffect(() => {
    if (avatarData) {
      dispatch(AdminProfileGet());
    }
  }, [avatarData]);

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      let userData: any = sessionStorage.getItem("session_user_details");
      userData = JSON.parse(userData)
      const formData = new FormData();
      formData.append('user_id', userData.user_id);

      formData.append('profile_image', selectedFile);
      dispatch(UpdateAvatar(formData))
    }

    setFile(selectedFile);
    const reader = new FileReader();

    reader.onload = () => {
      setUserProfileImage(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const onSubmit = (data: any) => {
    const formData = {
      name: data?.name ? data?.name : userDetails?.name,
      email: data?.email ? data?.email : userDetails?.email,
    };
    dispatch(editProfileAction(formData));
  };

  useEffect(() => {
    if (
      editProfileDetails?.statusCode === 200 &&
      editProfileDetails !== undefined &&
      editProfileDetails !== null
    ) {
      dispatch(AdminProfileGet());
    }
  }, [editProfileDetails, dispatch]);



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "My Profile",
            path: "/admin/profile",
            active: true,
          },
        ]}
        title={"My Profile"} // admin
      />
      <Row>
        <Col xl={4} lg={4}>
          <Card className="text-center">
            <Card.Body>

              <div className="">
                <div className="container">
                  <div className="d-flex justify-content-center">
                    <div className="position-relative">
                      <input
                        type="file"
                        id="profileImageInput"
                        style={{ display: 'none' }}
                        accept="image/*"
                        {...register('profile_image')}
                        onChange={handleFileChange}
                      />
                      <label
                        htmlFor="profileImageInput"
                        className="position-absolute"
                        style={{
                          top: '10px', // Adjust to position inside the top border
                          right: '10px', // Adjust to position inside the right border
                          cursor: 'pointer',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Light background for visibility
                          borderRadius: '50%', // Circular background
                          width: '25px', // Fixed size for the label
                          height: '25px', // Fixed size for the label
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: '1px solid #ddd',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                          zIndex: 1
                        }}
                      >
                        <i className="mdi mdi-pencil" style={{ fontSize: '15px', color: '#757575' }}></i>
                      </label>
                      <div
                        className="rounded-circle"
                        style={{
                          width: '150px',
                          height: '150px',
                          borderRadius: '50%',
                          border: '6px solid #F8F8F8',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          backgroundImage: `url(${userDetails?.profile_image || 'https://i.pravatar.cc/500?img=7'})`
                        }}
                      >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Wrap the image in a label to enable clicking */}
              {/* <label htmlFor="profileImageInput">
                <img
                  src={
                    !userDetails?.profile_image
                      ? "https://i.pravatar.cc/300"
                      : userDetails?.profile_image
                  }
                  className="rounded-circle avatar-xxl img-thumbnail"
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              </label>
              <input
                type="file"
                id="profileImageInput"
                style={{ display: "none" }}
                accept="image/*"
                {...register("profile_image")} // Registering the input with React Hook Form
                onChange={handleFileChange} // This handles the file change
              /> */}

              {/* {userDetails?.profile_image && (
                <div>
                  <h4>Image Preview</h4>
                  <img src={userDetails?.profile_image} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
              )} */}
              <h4 className="mb-0 text-capitalize">{userDetails?.name}</h4>
              <p className="text-muted">{userDetails?.email}</p>
              <hr />
              <div className="text-start mt-3">
                <p className="text-muted mb-2 font-13">
                  <strong>Full Name :</strong>
                  <span className="ms-2 text-capitalize">
                    {userDetails?.name}
                  </span>
                </p>
                <p className="text-muted mb-2 font-13">
                  <strong>Email :</strong>
                  <span className="ms-2 ">{userDetails?.email}</span>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={8} lg={8}>
          <Card>
            <Card.Body>
              <h5 className="mb-4 text-uppercase">
                <i className="mdi mdi-account-circle me-1"></i> Personal Info
              </h5>
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <Row>
                  <Col>
                    <label htmlFor="profileImageInput">
                      <img
                        src={userProfileImage ? userProfileImage : "https://i.pravatar.cc/300"}
                        className="rounded-circle avatar-xxl img-thumbnail"
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                    </label>
                    <input
                      type="file"
                      id="profileImageInput"
                      style={{ display: "none" }}
                      accept="image/*"
                      {...register("profile_image")} // Registering the input with React Hook Form
                      onChange={handleFileChange} // This handles the file change
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col md={12}>
                    <FormInput
                      className="w-75"
                      name="name"
                      label="Name"
                      placeholder="Enter Name"
                      containerClass={"mb-3"}
                      register={register}
                      key="name"
                      errors={errors}
                      control={control}
                      defaultValue={userDetails?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormInput
                      className="w-75"
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      containerClass={"mb-3"}
                      register={register}
                      key="email"
                      errors={errors}
                      control={control}
                      defaultValue={userDetails?.email}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center mb-3">
                      <div className="text-start">
                        <button type="submit" className="btn btn-success mt-2">
                          <i className="mdi mdi-content-save"></i> Save
                        </button>
                      </div>
                    </div>
                    {/* <div className="text-start mb-3">
                      <button
                        type="submit"
                        className="btn w-sm btn-success waves-effect waves-light me-1"
                      >
                        Save
                      </button>
                    </div> */}
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NewAdminUpdateProfile;
