import { APICore } from "../apiCore";
import config from "../../../config";
const api = new APICore();

// Listing User Order 
function FetchUserOrderList(params: any) {
  const baseUrl = config.API_URL + "/getMyOrders";
  return api.create(baseUrl, { ...params?.data });
}


export {
  FetchUserOrderList,
};
