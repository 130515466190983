import { CategoryActionTypes } from "./constants";

export interface CategoryAction {
  type:
    | CategoryActionTypes.API_RESPONSE_SUCCESS
    | CategoryActionTypes.API_RESPONSE_ERROR
    | CategoryActionTypes.FETCH_CATEGORY
    | CategoryActionTypes.ADD_CATEGORY
    | CategoryActionTypes.EDIT_CATEGORY
    | CategoryActionTypes.DELETE_CATEGORY;
  payload: {} | any;
}

interface CategoryData {
  categoryDetails: [];
  loading: false;
  error: null;
}

// common success
export const categoryApiResponseSuccess = (
  actionType: string,
  data: CategoryData | {}
): CategoryAction => ({
  type: CategoryActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const categoryApiResponseError = (
  actionType: string,
  error: string
): CategoryAction => ({
  type: CategoryActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//fetch Category Action
export const fetchCategoryAction = () => ({
  type: CategoryActionTypes.FETCH_CATEGORY,
});

//Add Category Action
export const addCategoryAction = (addCategoryDetails: any) => ({
  type: CategoryActionTypes.ADD_CATEGORY,
  payload: addCategoryDetails,
});

//Edit Category Action
export const editCategoryAction = (editCategoryDetails: any) => ({
  type: CategoryActionTypes.EDIT_CATEGORY,
  payload: editCategoryDetails,
});


//Delete Category Action
export const deleteCategoryAction = (deleteCategoryDetails: any) => ({
  type: CategoryActionTypes.DELETE_CATEGORY,
  payload: deleteCategoryDetails,
});