import { APICore } from "./apiCore";
import config from "../../config";
const api = new APICore();

// Listing Task
function fetchTask(params: any) {
  const baseUrl = config.API_URL + "/admin/listAllTask";
  return api.create(baseUrl, { ...params?.data });
}

//Adding Task
function addTask(params: any) {
  const baseUrl = config.API_URL + "/admin/addTask";
  return api.create(baseUrl, { ...params?.data });
}

//Edit Task
function editTask(params: any) {
  const dataRes: any = {
    title: params?.data?.title,
    task_description: params?.data?.task_description,
    user_id: params?.data?.user_id,
    type: params?.data?.type,
  };
  if (params?.data?.type == 1) {
    dataRes.clientArr = params?.data?.clientArr;
  }
  const baseUrl = config.API_URL + `/admin/editTask/${params?.data?.task_id}`;
  return api.updateTask(baseUrl, { dataRes });
}

//Delete Task
function deleteTask(params: any) {
  const baseUrl = config.API_URL + `/admin/deleteTask/${params?.data}`;
  return api.delete(baseUrl);
}

// Listing Task
function fetchSubAdminList(params: any) {
  const baseUrl = config.API_URL + "/admin/getSubAdmins";
  return api.get(baseUrl, { ...params?.data });
}

//Edit Task
function editStatusTask(params: any) {
  const dataRes = {
    task_id: params?.data?.task_id,
    status: params?.data?.status,
  };
  // const baseUrl = config.API_URL + `/admin/updateTaskStatus/${params?.data?.task_id}`;
  const baseUrl = config.API_URL + `/admin/changeTaskStatus`;
  return api.create(baseUrl, dataRes);
}

// Listing Task
function CustomerList(params: any) {
  const baseUrl = config.API_URL + "/admin/getClintLists";
  return api.get(baseUrl, {});
}

// Listing Task
function clientTask(params: any) {
  const baseUrl = config.API_URL + "/admin/listClientTask";
  return api.create(baseUrl, { ...params?.data });
}

export {
  fetchTask,
  addTask,
  editTask,
  deleteTask,
  fetchSubAdminList,
  editStatusTask,
  CustomerList,
  clientTask,
};
