export enum SubAdminActionTypes {
    API_RESPONSE_SUCCESS = "@@subadmin/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@subadmin/API_RESPONSE_ERROR",

    FETCH_SUBADMIN_REQUEST = "@@subadmin/FETCH_SUBADMIN_REQUEST",
    FETCH_PERMISSION_REQUEST = "@@subadmin/FETCH_PERMISSION_REQUEST",
    ADD_SUBADMIN_REQUEST = "@@subadmin/ADD_SUBADMIN_REQUEST",
    UPDATE_SUBADMIN_REQUEST = "@@subadmin/UPDATE_SUBADMIN_REQUEST",
    DELETE_SUBADMIN_REQUEST = "@@subadmin/DELETE_SUBADMIN_REQUEST",
    UPDATE_SUBADMIN_STATUS = "@@subadmin/UPDATE_SUBADMIN_STATUS",
    UPDATE_PERMISSION = "@@subadmin/UPDATE_PERMISSION",
}
